import { inject, Injectable } from '@angular/core';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { AssetTemplateVariable } from '@iot-platform/models/i4b';
import { AbstractFollowedVariableAddPopupService } from '@iot-platform/shared/iot4bos/asset/ui';
import { UnfollowedVariablesGrid } from '../components/configuration-tab/unfollowed-variables-grid-definition';
import { AssetTemplatesFacade } from '../state/facade/asset-templates.facade';

@Injectable({
  providedIn: 'root'
})
export class FollowedVariableAddPopupService extends AbstractFollowedVariableAddPopupService {
  private readonly assetTemplatesFacade: AssetTemplatesFacade = inject(AssetTemplatesFacade);

  initGrid(): I4BGrid<I4BGridOptions, I4BGridData> {
    return {
      ...new UnfollowedVariablesGrid(),
      data: {
        response: {
          data:
            this.assetTemplatesFacade
              .currentEntity()
              ?.template.variables.filter((variable: AssetTemplateVariable) => !variable.followedNumber)
              .map((variable: AssetTemplateVariable, index) => ({ ...variable, id: index.toString() })) ?? [],
          pagination: null
        }
      }
    };
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, signal, WritableSignal } from '@angular/core';
import { AssetTemplate } from '@iot-platform/models/i4b';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssetTemplateCellService {
  cachedTemplates: WritableSignal<AssetTemplate[]> = signal([]);
  templateIds = new Set<string>();

  constructor(
    @Inject('environment') private readonly environment,
    private readonly httpClient: HttpClient
  ) {}

  getAssetTemplateById(templateId: string): Observable<AssetTemplate> {
    if (this.templateIds.has(templateId)) {
      return of(this.cachedTemplates().find((t) => t.id === templateId));
    } else {
      this.templateIds.add(templateId);
      return this.httpClient
        .get<AssetTemplate>(`${this.environment.api.url}${this.environment.api.endpoints.assetTemplates}/${templateId}`)
        .pipe(tap((t) => this.cachedTemplates.set([...this.cachedTemplates(), t])));
    }
  }
}

import { I4BApiError } from './i4b-api-error.model';

export interface I4BBulkOperationApiResponse {
  status: I4BBulkOperationApiResponseStatus;
  variables?: { [key: string]: { success: boolean; error?: I4BApiError } };
  events?: { [key: string]: { success: boolean; error?: I4BApiError } };
  sites?: { [key: string]: { success: boolean; error?: I4BApiError } };
  assets?: { [key: string]: { success: boolean; error?: I4BApiError } };
  devices?: { [key: string]: { success: boolean; error?: I4BApiError } };
  subscribers?: { [key: string]: { success: boolean; error?: I4BApiError } };
}

export enum I4BBulkOperationApiResponseStatuses {
  success = 'Success',
  failed = 'Failed',
  partial_success = 'Partial success'
}

export type I4BBulkOperationApiResponseStatus = 'success' | 'failed' | 'partial_success';

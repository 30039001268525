import { Pagination, PlatformResponse } from '@iot-platform/models/common';
import { PoEventRule } from '@iot-platform/models/i4b';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPoEventRulesDb from './po-events-db.reducer';
import * as fromPoEventRulesUi from './po-events-ui.reducer';

export const poEventRulesFeatureKey = 'poEventRules';

export interface PoEventRulesState {
  [fromPoEventRulesDb.poEventRuleDbFeatureKey]: fromPoEventRulesDb.State;
  [fromPoEventRulesUi.poEventRulesUiFeatureKey]: fromPoEventRulesUi.State;
}

export interface State {
  [poEventRulesFeatureKey]: PoEventRulesState;
}

export function reducers(state: PoEventRulesState | undefined, action: Action) {
  return combineReducers({
    [fromPoEventRulesDb.poEventRuleDbFeatureKey]: fromPoEventRulesDb.reducer,
    [fromPoEventRulesUi.poEventRulesUiFeatureKey]: fromPoEventRulesUi.reducer
  })(state, action);
}

export const selectPoEventRulesState = createFeatureSelector<PoEventRulesState>(poEventRulesFeatureKey);

export const selectPoEventRulesDbState = createSelector(
  selectPoEventRulesState,
  (state: PoEventRulesState) => state[fromPoEventRulesDb.poEventRuleDbFeatureKey]
);

export const selectPoEventRulesUiState = createSelector(
  selectPoEventRulesState,
  (state: PoEventRulesState) => state[fromPoEventRulesUi.poEventRulesUiFeatureKey]
);

export const {
  selectIds: getPoEventRulesIds,
  selectEntities: getPoEventRulesEntities,
  selectAll: getAllPoEventRules,
  selectTotal: getTotalPoEventRules
} = fromPoEventRulesDb.adapter.getSelectors(selectPoEventRulesDbState);

export const getPoEventRulesLoading = createSelector(selectPoEventRulesUiState, fromPoEventRulesUi.getPoEventRulesLoading);
export const getPoEventRulesLoaded = createSelector(selectPoEventRulesUiState, fromPoEventRulesUi.getPoEventRulesLoaded);
export const getPoEventRulesError = createSelector(selectPoEventRulesUiState, fromPoEventRulesUi.getPoEventRulesError);
export const getPagination = createSelector(selectPoEventRulesDbState, fromPoEventRulesDb.getPagination);
export const getAddedRuleForConfig = createSelector(selectPoEventRulesDbState, fromPoEventRulesDb.getAddedRuleForConfig);
export const getConfigurePopupStatus = createSelector(selectPoEventRulesUiState, fromPoEventRulesUi.getOpenConfigurePopup);
export const getCurrentFilters = createSelector(selectPoEventRulesDbState, fromPoEventRulesDb.getCurrentFilters);

export const selectSelectedPoEventRuleId = createSelector(selectPoEventRulesDbState, fromPoEventRulesDb.getSelectedPoEventRuleId);

export const getFormattedData = createSelector(getAllPoEventRules, getPagination, (data: PoEventRule[], pagination: Pagination) => {
  const response: PlatformResponse = {
    data: data.sort((a, b) => (a.entity.level > b.entity.level ? 1 : a.entity.name > b.entity.name ? 1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total
  };
  return response;
});

export const getTotal = createSelector(selectPoEventRulesDbState, getPagination, (state, pagination) => pagination.total);

export const getConfigurePopupData = createSelector(getAddedRuleForConfig, getConfigurePopupStatus, (rule, status) => ({ canOpen: status, rule }));

export const selectSelectedPOEventRule = createSelector(getPoEventRulesEntities, selectSelectedPoEventRuleId, (rules, selectedRuleId) =>
  selectedRuleId ? rules[selectedRuleId] : undefined
);

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'grid-engine-linked-cell',
  templateUrl: './linked-cell.component.html',
  styleUrls: ['./linked-cell.component.scss']
})
export class LinkedCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  tooltip: string;
  value = null;
  isIcon = true;

  constructor(private translateService: TranslateService) {}

  agInit(params: ICellRendererParams & { isFilterRenderer?: boolean }): void {
    this.params = params;
    this.tooltip = this.translateService.instant(`ASSETS.VARIABLE.TOOLTIPS.${this.params?.value ? 'LINKED' : 'NOT_LINKED'}`);

    // Ag grid filter renderer transform boolean values to string
    if (`${params.value}` === 'true' || `${params.value}` === 'false') {
      this.isIcon = true;
      this.value = `${params.value}` === 'true';
    } else if (params.value === this.translateService.instant('AG_GRID.selectAll')) {
      this.isIcon = false;
      this.tooltip = null;
      this.value = params.value;
    } else {
      this.isIcon = false;
      this.tooltip = null;
      this.value = params.isFilterRenderer ? this.translateService.instant('AG_GRID.blanks') : params.value;
    }
  }

  refresh(): boolean {
    return false;
  }
}

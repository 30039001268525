import { Injectable } from '@angular/core';
import { Contact } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { ContactsUiActions } from '../actions';
import * as fromContacts from '../reducers';

@Injectable({
  providedIn: 'root'
})
export class ContactsFacade {
  public allContacts$ = this.store.select(fromContacts.selectAllContacts);
  public contactsLoading$ = this.store.select(fromContacts.selectContactsLoading);
  public totalContacts$ = this.store.select(fromContacts.selectTotalContacts);

  constructor(protected readonly store: Store) {}

  public loadContacts(siteId: string): void {
    this.store.dispatch(ContactsUiActions.loadContacts({ siteId }));
  }

  public addContact(contact: Contact): void {
    this.store.dispatch(ContactsUiActions.addContact({ contact }));
  }

  public updateContact(contact: Contact): void {
    this.store.dispatch(ContactsUiActions.updateContact({ contact }));
  }

  public deleteContact(contact: Contact): void {
    this.store.dispatch(ContactsUiActions.deleteContact({ contact }));
  }
}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'iot4bos-ui-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements OnChanges {
  @Input() data: { placeholder: string; value: number; minimum: number; maximum: number };

  inputForm: UntypedFormGroup = new UntypedFormGroup({ value: new UntypedFormControl('') });
  placeholder$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  minValue: number;
  maxValue: number;

  @Output() valueChange: EventEmitter<{ name: string; value: number; valid: boolean; touched: boolean }> = new EventEmitter<{
    name: string;
    value: number;
    valid: boolean;
    touched: boolean;
  }>();

  get value(): AbstractControl {
    return this.inputForm.get('value');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.value && changes['data'].firstChange) {
      this.placeholder$.next(changes['data'].currentValue.placeholder);
      if (changes['data'].currentValue && changes['data'].currentValue.minimum) {
        this.minValue = changes['data'].currentValue.minimum;
      }
      const minValidator: ValidatorFn = Validators.min(changes['data'].currentValue.minimum);

      if (changes['data'].currentValue && changes['data'].currentValue.maximum) {
        this.maxValue = changes['data'].currentValue.maximum;
      }
      const maxValidator: ValidatorFn = Validators.max(changes['data'].currentValue.maximum);

      this.value.setValue(changes['data'].currentValue.value);
      this.value.setValidators([minValidator, maxValidator, Validators.required]);
      this.value.updateValueAndValidity();

      this.valueChange.emit({ name: changes['data'].currentValue.placeholder, value: this.value.value, valid: this.inputForm.valid, touched: false });
    }
  }

  onValueChange() {
    this.valueChange.emit({
      name: this.placeholder$.getValue(),
      value: this.inputForm.get('value').value,
      valid: this.inputForm.valid,
      touched: true
    });
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { TagCategory, TagLabel } from '@iot-platform/models/common';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BulkManageTagDialogService } from './bulk-manage-tag-dialog.service';

@Component({
  selector: 'iot4bos-ui-bulk-manage-tag-dialog',
  templateUrl: './bulk-manage-tag-dialog.component.html',
  styleUrls: ['./bulk-manage-tag-dialog.component.scss']
})
export class BulkManageTagDialogComponent implements OnInit, OnDestroy {
  manageTagForm!: UntypedFormGroup;

  categories: TagCategory[] = [];
  categoriesLoading$ = new BehaviorSubject<boolean>(true);
  labels: TagLabel[] = [];

  destroy$ = new Subject<void>();

  constructor(
    private readonly addOrRemoveTagsDialogService: BulkManageTagDialogService,
    private readonly fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<BulkManageTagDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isAddition: boolean;
      concept: string;
      entityId: string;
      selectionTotal: number;
    }
  ) {}

  get categoryControl() {
    return this.manageTagForm.get('category') as AbstractControl;
  }

  get labelControl() {
    return this.manageTagForm.get('label') as AbstractControl;
  }

  ngOnInit(): void {
    this.initForm();
    this.addOrRemoveTagsDialogService
      .getTagsByConceptAndEntityId(this.data.concept, this.data.entityId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (categories) => {
          this.categories = categories
            .map((cat) => ({
              ...cat,
              labels: cat.labels?.sort(SortUtil.sortByName)
            }))
            .sort(SortUtil.sortByName);
          this.categoriesLoading$.next(false);
          this.categoryControl.enable();
        },
        error: () => this.categoriesLoading$.next(false)
      });
  }

  initForm(): void {
    this.manageTagForm = this.fb.group({
      category: [{ value: null, disabled: true }],
      label: [{ value: null, disabled: true }, [Validators.required]]
    });
  }

  onCategoryChange(event: MatSelectChange): void {
    this.labelControl.enable();
    this.labelControl.reset();
    this.labels = event.value.labels;
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close(this.labelControl.value.id);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import { IotGeoJsonFeature } from '@iot-platform/iot-platform-maps';

export class ThresholdsHelper {
  public static getEffectiveThreshold(feature: IotGeoJsonFeature, threshold: any) {
    if (!feature || !threshold) {
      return null;
    } else {
      const potentialThresholds = threshold.values.filter((t: any) => {
        if (feature.options && typeof feature.options['value'] === 'number') {
          const operator = threshold.operator === '=' ? '===' : threshold.operator;
          const formula = feature.options['value'] + ' ' + operator + ' ' + t.value;
          // eslint-disable-next-line no-eval
          return eval(formula);
        }
      });

      let effectiveThreshold: any = null;
      if (potentialThresholds.length === 1) {
        effectiveThreshold = potentialThresholds[0];
      } else {
        potentialThresholds.forEach((t: any, index: number) => {
          if (feature.options && typeof feature.options['value'] === 'number') {
            const indexToCompare = threshold.operator === '<' ? index - 1 : threshold.operator === '>' ? index + 1 : index;
            const formula =
              feature.options['value'] +
              ' ' +
              threshold.operator +
              ' ' +
              t.value +
              ' && ' +
              t.value +
              ' ' +
              threshold.operator +
              ' ' +
              threshold.values[indexToCompare]?.value;
            // eslint-disable-next-line no-eval
            if (eval(formula)) {
              effectiveThreshold = t;
            }
          }
        });
      }
      return effectiveThreshold;
    }
  }
}

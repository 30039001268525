<mat-toolbar color="primary" class="breadcrumb-container" fxLayoutAlign="start center">
  <div class="origin" fxFlex fxLayoutAlign="center center" (click)="onReturnToOriginClick()">
    <mat-icon>keyboard_backspace</mat-icon>
  </div>

  @if (site) {
    <iot4bos-ui-site-breadcrumb-item [site]="site" (navigate)="navigate('site', $event)"> </iot4bos-ui-site-breadcrumb-item>
  }
  @if (selectedAsset) {
    <iot4bos-ui-asset-breadcrumb-item [asset]="selectedAsset" (navigate)="close.emit()"> </iot4bos-ui-asset-breadcrumb-item>
  }
  @if (selectedDevice) {
    <iot4bos-ui-device-breadcrumb-item [device]="selectedDevice" (navigate)="close.emit()"> </iot4bos-ui-device-breadcrumb-item>
  }

  <span fxFlex></span>

  @if (!selectedDevice && !selectedAsset && canReadDisplayedConcept) {
    <button
      mat-mini-fab
      class="reverse-accent-button"
      [matTooltip]="'BREADCRUMB.TOOLTIPS.REFRESH_SITE_PAGE' | translate"
      matTooltipClass="regular-tooltip"
      (click)="onRefreshSiteClick()"
    >
      <mat-icon>refresh</mat-icon>
    </button>
  } @else if (selectedDevice && canReadDisplayedConcept) {
    <button
      mat-mini-fab
      class="reverse-accent-button"
      [matTooltip]="'BREADCRUMB.TOOLTIPS.REFRESH_DEVICE_PAGE' | translate"
      matTooltipClass="regular-tooltip"
      (click)="onRefreshDeviceClick()"
    >
      <mat-icon>refresh</mat-icon>
    </button>
  } @else if (selectedAsset && canReadDisplayedConcept) {
    <button
      mat-mini-fab
      class="reverse-accent-button"
      [matTooltip]="'BREADCRUMB.TOOLTIPS.REFRESH_ASSET_PAGE' | translate"
      matTooltipClass="regular-tooltip"
      (click)="onRefreshAssetClick()"
    >
      <mat-icon>refresh</mat-icon>
    </button>
  }

  @if(canReadDisplayedConcept && site?.type !== SiteType.STOCK) {
    <button
      mat-mini-fab
      class="reverse-accent-button"
      [matTooltip]="'BREADCRUMB.TOOLTIPS.DISPLAY_COMMENTS' | translate"
      matTooltipClass="regular-tooltip"
      (click)="onCommentClicked()"
    >
      <mat-icon>comment</mat-icon>
    </button>
  }

  @if (site?.type !== SiteType.STOCK) {}
  <button
    mat-mini-fab
    class="reverse-accent-button"
    [matTooltip]="'SITES.ASSOCIATIONS.BUTTON_TOOLTIP' | translate"
    matTooltipClass="regular-tooltip"
    (click)="onOpenAssociations()"
  >
    <mat-icon>account_tree</mat-icon>
  </button>
</mat-toolbar>

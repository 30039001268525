import { Contact } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ContactsDbActions } from '../actions';

export const contactsDbFeatureKey = 'contactsDb';

export interface State extends EntityState<Contact> {
  selectedContactId: string;
  error: any;
}

export const adapter: EntityAdapter<Contact> = createEntityAdapter<Contact>({
  selectId: (contact: Contact) => contact.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedContactId: null,
  error: null
});

const contactsDbReducer = createReducer(
  initialState,
  on(ContactsDbActions.loadContactsSuccess, (state: State, { contacts }) => adapter.setAll(contacts, state)),
  on(ContactsDbActions.addContactSuccess, (state: State, { addedContact }) =>
    adapter.addOne(addedContact, { ...state, selectedContactId: addedContact.id as string })
  ),
  on(ContactsDbActions.addContactFailure, (state, { error }) => ({ ...state, error })),
  on(ContactsDbActions.updateContactSuccess, (state: State, { updatedContact }) =>
    adapter.updateOne({ id: updatedContact.id as string, changes: updatedContact }, { ...state, selectedContactId: updatedContact.id as string })
  ),
  on(ContactsDbActions.updateContactFailure, (state, { error }) => ({ ...state, error })),
  on(ContactsDbActions.deleteContactSuccess, (state: State, { deletedContact }) => adapter.removeOne(deletedContact.id as string, state)),
  on(ContactsDbActions.deleteContactFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return contactsDbReducer(state, action);
}

export const getSelectedContactId = (state: State) => state.selectedContactId;
export const getError = (state: State) => state.error;

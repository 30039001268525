import { BusinessProfile } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';
import {
  BusinessProfilesApiActions,
  BusinessProfilesFavoriteViewsApiActions,
  BusinessProfilesMembersApiActions,
  BusinessProfilesPageActions,
  BusinessProfilesRolesApiActions,
  BusinessProfilesTagsApiActions
} from '../actions/';

export const adminBusinessProfilesApiFeatureKey = 'adminBusinessProfilesApi';

export interface State extends EntityState<BusinessProfile> {
  selectedBusinessProfileId: string;
}

export const adapter: EntityAdapter<BusinessProfile> = createEntityAdapter<BusinessProfile>({
  selectId: (businessProfile: BusinessProfile) => businessProfile.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedBusinessProfileId: null
});

export const adminBusinessProfilesReducer = createReducer(
  initialState,
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState),
  on(BusinessProfilesApiActions.selectBusinessProfile, (state, { selectedBusinessProfileId }) => ({
    ...state,
    selectedBusinessProfileId
  })),

  on(BusinessProfilesApiActions.listBusinessProfilesSuccess, (state, { businessProfiles }) => adapter.setAll(businessProfiles, state)),
  on(BusinessProfilesApiActions.listBusinessProfilesFailure, (state, { error }) => ({ ...state, error })),

  on(BusinessProfilesApiActions.addBusinessProfileSuccess, (state, { businessProfile }) => adapter.addOne(businessProfile, state)),
  on(BusinessProfilesApiActions.addBusinessProfileFailure, (state, { error }) => ({ ...state, error })),

  on(BusinessProfilesApiActions.deleteBusinessProfileSuccess, (state: State, { deletedBusinessProfile }) =>
    adapter.removeOne(deletedBusinessProfile.id, { ...state, selectedBusinessProfileId: null })
  ),
  on(BusinessProfilesApiActions.deleteBusinessProfileFailure, (state: State, { error }) => ({ ...state, error })),

  on(BusinessProfilesApiActions.updateBusinessProfileSuccess, (state, { businessProfile }) => adapter.updateOne(businessProfile, state)),
  on(BusinessProfilesApiActions.updateBusinessProfileFailure, (state, { error }) => ({ ...state, error })),

  on(BusinessProfilesMembersApiActions.listMembersByBusinessProfileSuccess, (state, { members }) => ({ ...state, members })),
  on(BusinessProfilesMembersApiActions.listMembersByBusinessProfileFailure, (state, { error }) => ({ ...state, error })),

  on(BusinessProfilesRolesApiActions.listRolesByBusinessProfileSuccess, (state, { roles }) => ({ ...state, roles })),
  on(BusinessProfilesRolesApiActions.listRolesByBusinessProfileFailure, (state, { error }) => ({ ...state, error })),

  on(BusinessProfilesPageActions.listTagsByBusinessProfile, (state) => ({ ...state, tags: [] })),
  on(BusinessProfilesTagsApiActions.listTagsByBusinessProfileSuccess, (state, { tags }) => ({ ...state, tags })),
  on(BusinessProfilesTagsApiActions.listTagsByBusinessProfileFailure, (state, { error }) => ({ ...state, error })),

  on(BusinessProfilesPageActions.listFavoriteViewsByBusinessProfile, (state) => ({ ...state, favoriteViews: [] })),
  on(BusinessProfilesFavoriteViewsApiActions.listFavoriteViewsByBusinessProfileSuccess, (state, { response }) => ({ ...state, favoriteViews: response.data })),
  on(BusinessProfilesFavoriteViewsApiActions.listFavoriteViewsByBusinessProfileFailure, (state, { error }) => ({ ...state, error })),

  on(BusinessProfilesTagsApiActions.updateTagsForBusinessProfileSuccess, (state, { addedTags }) => ({ ...state, tags: addedTags })),
  on(BusinessProfilesTagsApiActions.updateTagsForBusinessProfileFailure, (state, { error }) => ({ ...state, tags: [], error }))
);

export function reducer(state: State | undefined, action: Action) {
  return adminBusinessProfilesReducer(state, action);
}

export const getSelectedBusinessProfileId = (state: State) => state.selectedBusinessProfileId;
// export const getMembersForProfile = (state: State) => state.members;
// export const getTagsForProfile = (state: State) => state.tags;

import { ValueGetterParams } from '@ag-grid-community/core';
import { get } from 'lodash';
import { DaliaFirmwareVersionColumnConfiguration, I4BCellType } from '../configurations/i4b-column-configuration';
import { DataLibrary } from '../enums/data-type.enum';
import { I4bCellConcept } from '../enums/i4b-cell-concept.enum';
import { I4BBasicColumnHeader } from '../headers/i4b-basic-column-header';
import { HeaderType, I4BColumnHeader } from '../models/i4b-column-header.model';
import { BasicColumnOptions } from '../options/i4b-basic-column-options';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BColumn } from './i4b-column';

export class DaliaFirmwareVersionColumn implements I4BColumn<I4BBasicColumnHeader, DaliaFirmwareVersionColumnConfiguration, I4BColumnOptions> {
  columnId = 'z1b4bd54-9fc3-11ec-9da8-0023a40210d0-firmware-version';
  className = DaliaFirmwareVersionColumn;

  dataLibs: DataLibrary[] = [DataLibrary.DEVICES, DataLibrary.FIRMWARES];

  header: I4BBasicColumnHeader = new I4BBasicColumnHeader({ type: HeaderType.BASIC, displayName: 'VERSION' });
  configuration: DaliaFirmwareVersionColumnConfiguration = {
    isLink: false,
    position: '',
    sortProperty: '',
    id: 'version',
    translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.VERSION',
    concept: I4bCellConcept.DEVICES,
    isDefault: true,
    isConfigurable: true,
    width: 60,
    cell: {
      type: I4BCellType.BASIC,
      options: {
        toFixed: 1
      }
    }
  };
  options: I4BColumnOptions = new BasicColumnOptions({ sortable: true });

  constructor(
    customHeader?: Partial<I4BColumnHeader>,
    customConfig?: Partial<DaliaFirmwareVersionColumnConfiguration>,
    customOptions?: Partial<I4BColumnOptions>
  ) {
    if (customHeader) {
      this.header = new I4BBasicColumnHeader({
        ...this.header,
        ...customHeader
      });
    }
    if (customConfig) {
      this.configuration = { ...this.configuration, ...customConfig };
    }
    if (customOptions) {
      this.options = { ...this.options, ...customOptions };
    }

    this.configuration.cell.valueGetter = (params: ValueGetterParams) => {
      const cellOptions = get(params, 'colDef.cellRendererParams.cellOptions');
      const value = get(params, `data.${params.colDef.field}`);
      if (cellOptions?.toFixed !== undefined && cellOptions?.toFixed !== null) {
        return Number(value)?.toFixed(cellOptions.toFixed);
      }
      return value;
    };
  }
}

<mat-sidenav-container (backdropClick)="displayAssociation.set(false); displayComments.set(false)" fxFlex
                       hasBackdrop="true">
  <mat-sidenav-content>
    <iot4bos-ui-breadcrumb
      (close)="onClose()"
      (refreshAsset)="refreshAsset($event)"
      (refreshDevice)="refreshDevice($event)"
      (refreshSite)="refreshSite($event)"
      (returnToOrigin)="onReturnToOrigin()"
      (toggleAssociationsPanel)="sidePanel.open(); onToggleAssociationPanel()"
      (toggleCommentPanel)="sidePanel.open(); onToggleCommentPanel($event)"
      [activeItemId]="activeItemId"
      [canReadDisplayedConcept]="canReadDisplayedConcept"
      [mvorigin]="mvorigin"
      [selectedAsset]="navigationApi.selectedAsset$ | async"
      [selectedDevice]="navigationApi.selectedDevice$ | async"
      [site]="navigationApi.site$ | async"
    >
    </iot4bos-ui-breadcrumb>

    <router-outlet></router-outlet>
  </mat-sidenav-content>

  <mat-sidenav #sidePanel mode="over" position="end">
    @if (displayAssociation()) {
      <div class="association-panel" fxLayout="column" fxLayoutAlign="start end">
        <button (click)="sidePanel.close(); displayAssociation.set(false)" class="regular-round-button close-button"
                mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>

        <div class="association-panel-component p-20">
          <iot4bos-ui-asset-device-associations
            (dispatchAction)="sidePanel.close(); onAssetDeviceAssociationAction($event)"
            [associationsLoaded]="siteFullAssociationsLoaded$ | async"
            [associations]="siteFullAssociations$ | async"
            [navigationMode]="true"
            [selectedItem]="selectedItem$ | async"
            [user]="currentUser$ | async"
          ></iot4bos-ui-asset-device-associations>
        </div>
      </div>
    }

    @if (displayComments()) {
      <shared-iot4bos-ui-comments-panel
        data-cy="iot4bos-ui-events-comments"
        [comments]="comments()"
        [withFilters]="!!(navigationApi.selectedAsset$ | async) || !!(navigationApi.selectedDevice$ | async)"
        [filters]="commentsContexts"
        [withFooter]="canReadDisplayedConcept"
        [canEditComment]="canReadDisplayedConcept"
        [canDeleteComment]="canReadDisplayedConcept"
        [defaultConcept]="currentConceptAndElement()?.concept"
        [loading]="commentsLoading()"
        (commentsEvent)="onCommentsEvent($event)"
      >
      </shared-iot4bos-ui-comments-panel>
    }
  </mat-sidenav>
</mat-sidenav-container>

import { IotGeoJsonFeature } from '@iot-platform/iot-platform-maps';
import * as Leaflet from 'leaflet';
import { DivIcon, IconOptions } from 'leaflet';
import { MapClustersHelper } from './map-clusters.helper';
import { ThresholdsHelper } from './thresholds.helper';

const ICON_WIDTH = 33;
const ICON_HEIGHT = 45;
const ICON_SIZE = [ICON_WIDTH, ICON_HEIGHT];

const ICON_ANCHOR_X = 15;
const ICON_ANCHOR_Y = 44;
const ICON_ANCHOR = [ICON_ANCHOR_X, ICON_ANCHOR_Y];

export const DEFAULT_ICON_OPTIONS: Partial<IconOptions> = {
  iconSize: ICON_SIZE as [number, number],
  iconAnchor: ICON_ANCHOR as [number, number]
};

enum PinConcept {
  SITE = 'site',
  ASSET = 'asset',
  DEVICE = 'device'
}

enum PinColor {
  BLUE = 'blue',
  GREEN = 'green',
  RED = 'red'
}

enum PinMode {
  DEFAULT = 'default',
  ACTIVE = 'active',
  HOVER = 'hover'
}

export class MapMarkersHelper {
  static DEFAULT_MARKER_ICON: Leaflet.Icon = Leaflet.icon({
    iconSize: [25, 41],
    iconAnchor: [13, 41],
    iconUrl: 'assets/map/marker-icon.png',
    iconRetinaUrl: 'assets/map/marker-icon-2x.png',
    shadowUrl: 'assets/map/marker-shadow.png'
  });

  static getMarkerIcon = (feature: IotGeoJsonFeature, displayMode: string, pinMode: PinMode = PinMode.DEFAULT): DivIcon => {
    let markerColor = '375F9B';
    if (displayMode && displayMode === 'assetVariable' && !!feature) {
      if (feature?.options['thresholds'] && feature?.options['thresholds'].values && feature?.options['thresholds'].values.length > 0) {
        const threshold = feature?.options['thresholds'];
        const effectiveThreshold = ThresholdsHelper.getEffectiveThreshold(feature, threshold);
        if (effectiveThreshold && effectiveThreshold.hasOwnProperty('lineColor')) {
          markerColor = effectiveThreshold.lineColor.replace('#', '');
        }
        return this.getMarkerAssetVariable(displayMode, markerColor, pinMode);
      } else {
        return this.getMarkerAssetVariable(displayMode, markerColor, pinMode);
      }
    } else if (feature.properties?.hasOwnProperty('concept')) {
      switch (feature.properties['concept']) {
        case 'site':
          if (feature.options && (feature.options.hasOwnProperty('assetActiveEvents') || feature.options.hasOwnProperty('deviceActiveEvents'))) {
            const hasEvents: boolean = feature.options['assetActiveEvents'] > 0 || feature.options['deviceActiveEvents'] > 0;
            return hasEvents ? this.getMarkerPin(PinConcept.SITE, PinColor.RED, pinMode) : this.getMarkerPin(PinConcept.SITE, PinColor.GREEN, pinMode);
          } else if (feature.options && feature.options.hasOwnProperty('matchScore')) {
            return this.getMarkerPin(
              PinConcept.SITE,
              MapClustersHelper.getApplicableScore(feature.options['matchScore']).color.replace('#', '') as PinColor,
              pinMode
            );
          } else {
            return this.getMarkerPin(PinConcept.SITE, PinColor.BLUE, pinMode);
          }

        case 'asset':
          if (feature.properties && feature.properties.hasOwnProperty('siteType') && feature.properties['siteType'] === 'mobile_fleet') {
            return Leaflet.icon({
              ...DEFAULT_ICON_OPTIONS,
              iconUrl: `assets/map/map-marker-icon-mobile-fleet-375F9B-${pinMode}.png`
            });
          } else {
            return this.getMarkerPin(PinConcept.ASSET, PinColor.BLUE, pinMode);
          }

        case 'device':
          if (feature.options && feature.options.hasOwnProperty('ccf')) {
            return feature.options['ccf'] === true
              ? this.getMarkerPin(PinConcept.DEVICE, PinColor.RED, pinMode)
              : this.getMarkerPin(PinConcept.DEVICE, PinColor.GREEN, pinMode);
          } else {
            return this.getMarkerPin(PinConcept.DEVICE, PinColor.BLUE, pinMode);
          }
        default:
          return MapMarkersHelper.DEFAULT_MARKER_ICON;
      }
    }
    return MapMarkersHelper.DEFAULT_MARKER_ICON;
  };

  static getMarkerPin(concept: PinConcept, color: PinColor, mode: PinMode): Leaflet.Icon {
    return Leaflet.icon({
      ...DEFAULT_ICON_OPTIONS,
      iconUrl: `assets/map/map-marker-icon-${concept}-${color}-${mode}.png`
    });
  }

  static getMarkerIconHover(feature: IotGeoJsonFeature, displayMode: string): DivIcon {
    return this.getMarkerIcon(feature, displayMode, PinMode.HOVER);
  }

  static getMarkerIconActive(feature: IotGeoJsonFeature, displayMode: string): DivIcon {
    return this.getMarkerIcon(feature, displayMode, PinMode.ACTIVE);
  }

  /* static getRoutePointIcon(feature: IotGeoJsonFeature): DivIcon {
    return Leaflet.icon({
      ...DEFAULT_ICON_OPTIONS
    });
  }*/

  private static getMarkerAssetVariable(displayMode: string, markerColor: string, pinMode: PinMode = PinMode.DEFAULT): DivIcon {
    const path = `assets/map/map-marker-icon-${displayMode}-${markerColor}-${pinMode}.png`;
    return Leaflet.icon({
      ...DEFAULT_ICON_OPTIONS,
      iconUrl: path
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAssetVariableIcon = (feature: IotGeoJsonFeature): DivIcon => {
    const color = '#eeaaff';
    return Leaflet.icon({
      ...DEFAULT_ICON_OPTIONS,
      iconUrl: 'assets/map/map-marker-icon-variable-' + color + '-active.png'
    });
  };
}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { Organization } from '@iot-platform/models/common';

import { SortUtil } from '@iot-platform/iot-platform-utils';

@Component({
  selector: 'iot4bos-backoffice-ui-organization-concepts-selection',
  templateUrl: './organization-concepts-selection.component.html',
  styleUrls: ['./organization-concepts-selection.component.scss']
})
export class OrganizationConceptsSelectionComponent implements OnChanges {
  @Input() organization: Organization;
  @Input() adminConceptsFromEntitySession: [string];
  @Input() readonly = true;

  @Output() updateConcepts: EventEmitter<string[]> = new EventEmitter<[]>();

  allConceptsAvailable: { name: string; isSelected: boolean }[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('organization') && changes.organization.currentValue) {
      this.organization = {
        ...changes.organization.currentValue,
        adminConcepts: changes.organization.currentValue.adminConcepts ? [...changes.organization.currentValue.adminConcepts] : []
      };
      this.initConcepts(this.organization);
    }
  }

  initConcepts(organization: Organization) {
    if (this.adminConceptsFromEntitySession) {
      const availableConcepts = [...this.adminConceptsFromEntitySession];

      this.adminConceptsFromEntitySession.forEach((concept) => {
        if (organization && organization.adminConcepts && organization.adminConcepts.indexOf(concept) > -1) {
          availableConcepts.splice(availableConcepts.indexOf(concept), 1);
        }
      });

      const availableConceptsInBpOrganization = availableConcepts.reduce((acc, currentValue) => acc.concat([{ name: currentValue, isSelected: false }]), []);

      let adminConcepts = [];
      if (this.organization.adminConcepts) {
        adminConcepts = this.organization.adminConcepts.reduce((acc, currentValue) => acc.concat([{ name: currentValue, isSelected: true }]), []);
      }

      this.allConceptsAvailable = availableConceptsInBpOrganization.concat(adminConcepts).sort(SortUtil.sortByName);
    }
  }

  createNewConceptsArray() {
    return this.allConceptsAvailable.reduce((acc, value) => {
      if (value.isSelected) {
        acc.push(value.name);
      }
      return acc;
    }, []);
  }

  onToggleConceptSelection(event: { name: string; isSelected: boolean }) {
    if (event.isSelected) {
      this.addConceptToOrganization(event);
    } else {
      this.removeConceptFromOrganization(event);
    }
  }

  addConceptToOrganization(addedConcept: { name: string; isSelected: boolean }) {
    if (this.allConceptsAvailable.length) {
      this.allConceptsAvailable.splice(
        this.allConceptsAvailable.findIndex((concept) => concept.name === addedConcept.name),
        1,
        addedConcept
      );
    } else {
      this.allConceptsAvailable.push(addedConcept);
    }

    this.updateConceptsInOrganization(this.createNewConceptsArray());
  }

  removeConceptFromOrganization(removedConcept: { name: string; isSelected: boolean }) {
    this.allConceptsAvailable.splice(
      this.allConceptsAvailable.findIndex((concept) => concept.name === removedConcept.name),
      1,
      removedConcept
    );

    this.updateConceptsInOrganization(this.createNewConceptsArray());
  }

  private updateConceptsInOrganization(updatedConcepts: string[]) {
    this.updateConcepts.emit(updatedConcepts);
  }
}

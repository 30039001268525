import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseUser, CommonApiRequest, Organization } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { AdminOrganizationsAdministratorsPageActions } from '../../../features/admin-organizations/state/actions';
import * as fromOrganizations from '../../../features/admin-organizations/state/reducers/';
import { UsersService } from '../../../features/users/services/users.service';

@Component({
  selector: 'iot4bos-backoffice-ui-dialog-administrator-add-form',
  templateUrl: './dialog-administrator-add-form.component.html',
  styleUrls: ['./dialog-administrator-add-form.component.scss', '../../../style/admin.style.scss']
})
export class DialogAdministratorAddFormComponent implements OnInit, OnDestroy {
  allUsers: BaseUser[] = [];

  unselectedUsers: BaseUser[] = [];
  filteredUnselectedUsers: BaseUser[] = [];

  currentAdministrators: BaseUser[] = [];
  filteredCurrentAdministrators: BaseUser[] = [];

  param: { organizationName: string } = { organizationName: '' };
  loadingUsers$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private filterUnselected;
  private filterSelected;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private readonly dialogRef: MatDialogRef<DialogAdministratorAddFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { organization: Organization },
    private readonly store: Store,
    private readonly usersService: UsersService
  ) {
    this.store.select(fromOrganizations.getAllAdministrators).subscribe((admins) => {
      this.currentAdministrators = [...admins];
      this.filteredUnselectedUsers = this.unselectedUsers = this.checkAvailableUsers(this.allUsers).sort(SortUtil.sortByProperty('lastname'));

      if (this.filterSelected) {
        this.filterUsers(this.filterSelected, this.currentAdministrators, true);
      } else {
        this.filteredCurrentAdministrators = [...this.currentAdministrators];
      }

      if (this.filterUnselected) {
        this.filterUsers(this.filterUnselected, this.unselectedUsers, false);
      }
    });
  }

  ngOnInit() {
    if (this.data && this.data.organization) {
      this.param.organizationName = this.data.organization.name;
    }
  }

  private getUsers(request: CommonApiRequest): void {
    this.loadingUsers$.next(true);

    this.usersService
      .getUsers(request)
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.loadingUsers$.next(false))
      )
      .subscribe((response) => {
        this.allUsers = response.data;
        this.filteredUnselectedUsers = this.unselectedUsers = [...this.checkAvailableUsers(response.data).sort(SortUtil.sortByProperty('lastname'))];
      });
  }

  onSearchUsers(term: string): void {
    const request: CommonApiRequest = {
      limit: 1000,
      filters: [
        { criteriaKey: 'userEmail', value: term },
        { criteriaKey: 'isShared', value: false },
        { criteriaKey: 'userStatus', value: 'active' }
      ]
    };
    this.getUsers(request);
  }

  displayUserWrapper(user: BaseUser): string {
    return user ? `${user.firstname} ${user.lastname}` : '';
  }

  private checkAvailableUsers(usersByOrganization: BaseUser[]): BaseUser[] {
    const available: BaseUser[] = [];
    usersByOrganization.forEach((user) => {
      if (this.currentAdministrators.indexOf(this.currentAdministrators.find((u) => user.id === u.id)) === -1) {
        available.push(user);
      }
    });
    return available;
  }

  removeAdministratorFromOrganization(administrator: BaseUser) {
    this.store.dispatch(
      AdminOrganizationsAdministratorsPageActions.removeAdministratorFromOrganization({
        organizationId: this.data.organization.id,
        administratorToRemove: administrator
      })
    );
  }

  addAdministratorToOrganization(administrator: BaseUser) {
    this.store.dispatch(
      AdminOrganizationsAdministratorsPageActions.addAdministratorToOrganization({
        organizationId: this.data.organization.id,
        administratorToAdd: administrator
      })
    );
  }

  filterUsers(event, listToFilter: BaseUser[], areUsersSelected: boolean): void {
    if (areUsersSelected) {
      this.filterSelected = event;
      this.filteredCurrentAdministrators = this.getFilteredUsers(listToFilter, this.filterSelected);
    } else {
      this.filterUnselected = event;
      this.filteredUnselectedUsers = this.getFilteredUsers(listToFilter, this.filterUnselected);
    }
  }

  getFilteredUsers(listToFilter: BaseUser[], filterEvent): BaseUser[] {
    return listToFilter.filter(
      (user) =>
        user.lastname.toLowerCase().includes(filterEvent.target.value.toLowerCase()) ||
        user.firstname.toLowerCase().includes(filterEvent.target.value.toLowerCase()) ||
        user.email.toLowerCase().includes(filterEvent.target.value.toLowerCase())
    );
  }

  exit() {
    this.dialogRef.close(true);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormulaType } from '@iot-platform/models/common';
import { DefaultFormulaSourceVariable, DeviceVariable, Formula, FormulaSourceVariable, SlopeShiftParameters } from '@iot-platform/models/i4b';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule],
  selector: 'shared-iot4bos-asset-ui-slope-shift-parameters-form',
  templateUrl: './slope-shift-parameters-form.component.html'
})
export class SlopeShiftParametersFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() deviceVariable?: DeviceVariable;
  @Input() initialFormula!: Formula;
  @Input() readonly = false;

  @Output() dispatchFormula: EventEmitter<Formula | null> = new EventEmitter();

  parametersForm: UntypedFormGroup;

  assetVariableFormula: Formula = { model: FormulaType.SLOPE_SHIFT, parameters: {}, srcVariables: {} };

  subscriptions: Subscription[] = [];

  get formulaSlope(): AbstractControl {
    return this.parametersForm.get('formulaSlope');
  }

  get formulaShift(): AbstractControl {
    return this.parametersForm.get('formulaShift');
  }

  ngOnInit() {
    const parameters = this.initialFormula?.parameters as SlopeShiftParameters;
    this.parametersForm = new UntypedFormGroup({
      formulaSlope: new UntypedFormControl(this.initialFormula ? parameters.slope : null),
      formulaShift: new UntypedFormControl(this.initialFormula ? parameters.shift : null)
    });

    if (this.readonly) {
      this.parametersForm.disable();
    }

    if (this.deviceVariable) {
      (this.assetVariableFormula.srcVariables as DefaultFormulaSourceVariable)['0'] = {
        name: '0',
        variableId: this.deviceVariable.id,
        originId: this.deviceVariable.device?.id,
        type: 'device-variable'
      } as FormulaSourceVariable;
    }

    this.subscriptions.push(this.parametersForm.valueChanges.subscribe(() => this.sendFormula()));
    this.sendFormula();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.deviceVariable) {
      this.assetVariableFormula.srcVariables = {
        '0': {
          name: '0',
          type: 'device-variable',
          variableId: changes.deviceVariable.currentValue?.id ?? null,
          originId: changes.deviceVariable.currentValue?.device.id ?? null
        }
      };

      if (this.parametersForm) {
        this.sendFormula();
      }
    }
  }

  canCalculateSlopeShiftFormula(): boolean {
    return (
      (this.formulaSlope.value || this.formulaSlope.value === 0) && (this.formulaShift.value || this.formulaShift.value === 0) && this.deviceVariable !== null
    );
  }

  createFormula(): void {
    this.assetVariableFormula.parameters = { slope: this.formulaSlope.value, shift: this.formulaShift.value };
  }

  sendFormula(): void {
    if (this.canCalculateSlopeShiftFormula()) {
      this.createFormula();
      this.dispatchFormula.emit(this.assetVariableFormula);
    } else {
      this.dispatchFormula.emit(null);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

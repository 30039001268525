import { ProcessCellForExportParams } from '@ag-grid-community/core';
import { formatNumber as _formatNumber } from '@angular/common';
import { LocalStorageKeys } from '@iot-platform/core';
import { ProcessMultipleStateVariablesUtil } from '@iot-platform/iot-platform-utils';
import { get } from 'lodash';
import { RichValueCellParams } from '../cells/rich-value-cell.params';
import { AssetVariable, DeviceVariable, FollowedVariable } from '@iot-platform/models/i4b';

export class RichVariableValueCellHelpers {
  static processValue(params: ProcessCellForExportParams): string {
    const { value, column, node } = params;
    const {
      cellOptions: { dataType, displayUnit: dUnit }
    } = column.getColDef().cellRendererParams;
    const displayUnit = dUnit ?? true;
    const data = get(node.data, column.getColDef().field);
    const isLastRecord: boolean = dataType === 'lastRecord';
    const isLastValue: boolean = dataType === 'lastValue';
    const isExpandedVariable: boolean = dataType === 'expandedVariable';
    const unit: string = isLastRecord ? get(node, 'data.unit') : get(data, 'unit');
    const isMultipleStateVariable: boolean = ProcessMultipleStateVariablesUtil.isUnitMultipleState(unit);
    if (isMultipleStateVariable) {
      if (isLastRecord) {
        return ProcessMultipleStateVariablesUtil.getProcessedUnit(node.data);
      }
      const lastRecords = value !== null ? [{ value, datetime: '' }] : [];
      return ProcessMultipleStateVariablesUtil.getProcessedUnit({
        ...data,
        lastRecords
      });
    } else if (isLastRecord) {
      return RichVariableValueCellHelpers.formatNumber(get(params, 'value[0].value'), displayUnit ? get(node, 'data.unit') : '');
    } else if (isLastValue || isExpandedVariable) {
      return RichVariableValueCellHelpers.formatNumber(get(data, 'lastValue.value'), displayUnit ? get(data, 'unit') : '');
    }
    return RichVariableValueCellHelpers.formatNumber(get(data, 'value'), displayUnit ? get(data, 'unit') : '');
  }

  static formatNumber(value: number, unit?: string): string {
    if (value === undefined || value === null) {
      return '';
    } else if (!!unit) {
      return `${_formatNumber(value, RichVariableValueCellHelpers.locale(), RichVariableValueCellHelpers.getNumberOfDecimals())} ${unit}`;
    }
    return `${_formatNumber(value, RichVariableValueCellHelpers.locale(), RichVariableValueCellHelpers.getNumberOfDecimals())}`;
  }

  static locale(): string {
    const userNumberLocale = JSON.parse(localStorage.getItem(`iot4bos.${LocalStorageKeys.STORAGE_USER_PREFERENCES}`))?.appNumberFormat;
    return userNumberLocale ?? 'en';
  }

  static getNumberOfDecimals(): string {
    const userNumberOfDecimals = JSON.parse(localStorage.getItem(`iot4bos.${LocalStorageKeys.STORAGE_USER_PREFERENCES}`))?.appNumberOfDecimals;
    return userNumberOfDecimals ? '1.0-' + userNumberOfDecimals.toString() : '1.0-3';
  }

  static getVariable(params: RichValueCellParams): Partial<AssetVariable> | Partial<DeviceVariable> | Partial<FollowedVariable> {
    if (params.colDef.field?.includes('context')) {
      const contextVariable: { id: string; name: string; value: number; unit: string } = get(params.data, params.colDef.field);
      return { ...contextVariable, lastValue: { value: contextVariable?.value, datetime: '' } };
    } else {
      return !!params.colDef.field ? get(params.data, params.colDef.field) : params.data;
    }
  }
}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'iot4bos-ui-string-input',
  templateUrl: './string-input.component.html',
  styleUrls: ['./string-input.component.scss']
})
export class StringInputComponent implements OnChanges {
  @Input() data: { placeholder: string; value: string; list: [] };
  @Output() valueChange: EventEmitter<{ name: string; value: number; valid: boolean; touched: boolean }> = new EventEmitter<{
    name: string;
    value: number;
    valid: boolean;
    touched: boolean;
  }>();

  inputForm: UntypedFormGroup = new UntypedFormGroup({ value: new UntypedFormControl('') });
  placeholder$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  choices = [];

  constructor() {}

  get value(): AbstractControl {
    return this.inputForm.get('value');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.value && changes['data'].firstChange) {
      this.placeholder$.next(changes['data'].currentValue.placeholder);
      this.choices = changes['data'].currentValue.choices;
      this.value.setValue(changes['data'].currentValue.value);
      this.value.setValidators([Validators.required]);
      this.value.updateValueAndValidity();

      this.valueChange.emit({ name: changes['data'].currentValue.placeholder, value: this.value.value, valid: this.inputForm.valid, touched: false });
    }
  }

  onValueChange() {
    this.valueChange.emit({
      name: this.placeholder$.getValue(),
      value: this.inputForm.get('value').value,
      valid: this.inputForm.valid,
      touched: true
    });
  }
}

import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommentContext } from '@iot-platform/models/i4b';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, MatCheckboxModule, FormsModule, UpperCasePipe],
  selector: 'shared-iot4bos-ui-comments-filters',
  templateUrl: './comments-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentsFiltersComponent {
  @Input() commentsFilters: CommentContext[] = [];
  @Input() onFilterChange!: (filters: CommentContext[]) => void; // THE OUTPUT TO USE FOR DYNAMIC INJECTION

  @Output() filterChange: EventEmitter<CommentContext[]> = new EventEmitter<CommentContext[]>(); // THE OUTPUT TO USE FOR SELECTOR INJECTION
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { FavoriteView, Filter } from '@iot-platform/models/common';
import { PoEventAlgorithm, PoEventRule } from '@iot-platform/models/i4b';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { PoEventsService } from '../../../../../../../shared/src/lib/services/po-events.service';

@Component({
  selector: 'iot4bos-ui-po-event-configure-form',
  templateUrl: './po-event-configure-form.component.html',
  styleUrls: ['./po-event-configure-form.component.scss']
})
export class PoEventConfigureFormComponent implements OnInit, OnDestroy {
  poEventConfigureForm: UntypedFormGroup;
  currentFavoriteView$: Observable<FavoriteView>;
  currentFilters$: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);
  clearAppliedFilters$: Observable<boolean>;

  algos: PoEventAlgorithm[] = [];
  selectedAlgo: PoEventAlgorithm;
  severities$: Observable<string[]>;
  pathToFilters$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  subFormsParams: { name: string; value: string | number; valid: boolean; touched: boolean }[] = [];
  allSubFormsValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  mainFormValid = false;
  currentConcept;
  currentParams;

  subscriptions: Subscription[] = [];

  constructor(
    private readonly dialogRef: MatDialogRef<PoEventConfigureFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { element: PoEventRule },
    private readonly poEventsService: PoEventsService
  ) {
    this.subscriptions.push(
      this.poEventsService.getPoEventAlgorithms().subscribe((algorithms) => {
        this.algos = algorithms;
        this.selectedAlgo = this.algos.find((alg: { name: string }) => alg.name.toLowerCase() === this.data.element?.algo?.toLowerCase());

        if (this.data.element?.concept) {
          this.pathToFilters$.next(`${this.pathToFilters$.value}-${this.data.element.concept}`);
        }
      }),
      this.allSubFormsValid$.subscribe((valid) => (this.mainFormValid = valid))
    );
  }

  get isThresholdCrossedAlgo(): boolean {
    return this.pathToFilters$.getValue().indexOf('threshold-crossed') !== -1;
  }

  get eventLabel(): AbstractControl {
    return this.poEventConfigureForm.get('eventLabel');
  }

  get entity(): AbstractControl {
    return this.poEventConfigureForm.get('entity');
  }

  get eventType(): AbstractControl {
    return this.poEventConfigureForm.get('eventType');
  }

  get class(): AbstractControl {
    return this.poEventConfigureForm.get('class');
  }

  get backToNormal(): AbstractControl {
    return this.poEventConfigureForm.get('backToNormal');
  }

  get active(): AbstractControl {
    return this.poEventConfigureForm.get('active');
  }

  get conceptList(): AbstractControl {
    return this.poEventConfigureForm.get('conceptList');
  }

  get conceptSolo(): AbstractControl {
    return this.poEventConfigureForm.get('conceptSolo');
  }

  get severity(): AbstractControl {
    return this.poEventConfigureForm.get('severity');
  }

  get shouldDisableConfigureButton(): boolean {
    return (
      !this.mainFormValid ||
      !this.poEventConfigureForm.valid ||
      (this.mainFormValid && this.poEventConfigureForm.valid && !this.poEventConfigureForm.touched) ||
      !this.currentFilters$.getValue()?.length
    );
  }

  ngOnInit() {
    this.severities$ = this.poEventsService.getSeverities().pipe(
      map((severities) => {
        severities.splice(
          severities.findIndex((s) => s === 'unknown_severity'),
          1
        );
        return severities;
      })
    );
    this.currentConcept = this.data.element.concept;

    if (this.currentConcept) {
      this.pathToFilters$.next(this.data.element.algo.replace(new RegExp(' ', 'g'), '-').toLowerCase());
    }

    this.currentFilters$.next(this.data.element.filters);
    this.clearAppliedFilters$ = of(false);
    this.currentFavoriteView$ = of(null);
    this.currentParams = [...this.data.element.params];

    this.poEventConfigureForm = new UntypedFormGroup({
      class: new UntypedFormControl({ value: this.data.element.class ? this.data.element.class : this.selectedAlgo.defaultClass, disabled: false }),
      backToNormal: new UntypedFormControl({ value: this.data.element.backToNormal, disabled: false }),
      severity: new UntypedFormControl({ value: this.data.element.severity.toLowerCase(), disabled: false }),
      active: new UntypedFormControl({ value: this.data.element.isActive, disabled: false }),
      conceptSolo: new UntypedFormControl({ value: this.data.element.concept, disabled: true }),
      conceptList: new UntypedFormControl({ value: this.data.element.concept, disabled: false }, [Validators.required])
    });

    if (this.currentFilters$.value.length === 0) {
      this.active.setValue(false);
      this.active.disable();
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  onApplyFilters(filters: Filter[]): void {
    this.currentFilters$.next(filters);
    if (filters.length === 0) {
      this.active.setValue(false);
      this.active.disable();
    } else {
      this.active.enable();
    }
    this.poEventConfigureForm.markAllAsTouched();
  }

  onConceptChange($event: MatSelectChange): void {
    this.pathToFilters$.next(`${this.selectedAlgo.name.replace(new RegExp(' ', 'g'), '-').toLowerCase()}-${$event.value}`);
    this.currentConcept = $event.value;
    if (this.data.element.concept === $event.value) {
      this.currentFilters$.next(this.data.element.filters);
    } else {
      this.currentFilters$.next([]);
    }
  }

  configure(): void {
    const rule = {
      ...this.data.element,
      backToNormal: this.backToNormal.value,
      isActive: this.active.value,
      class: this.class.value,
      severity: this.severity.value.charAt(0).toUpperCase() + this.severity.value.slice(1),
      filters: this.currentFilters$.value,
      concept: this.currentConcept,
      params: this.subFormsParams.map((p) => ({ name: p.name, value: p.value }))
    };

    this.dialogRef.close(rule);
  }

  getData(param): { placeholder: string; value: any; minimum: number; maximum: number; default: any } {
    return {
      placeholder: param.name,
      value: this.getValue(param.name, param.value.default),
      minimum: this.getMinimum(param),
      maximum: param.value.maximum,
      default: param.value.default
    };
  }

  getStringData(param): any {
    return { placeholder: param.name, value: this.getValue(param.name, param.value.default), choices: param.value.enum };
  }

  markAsTouched(): void {
    this.poEventConfigureForm.markAllAsTouched();
  }

  onParamValueChange(param: { name: string; value: string | number; valid: boolean; touched: boolean }): void {
    const existingParamIndex = this.subFormsParams.findIndex((p) => p.name === param['name']);

    if (existingParamIndex === -1) {
      this.subFormsParams.push(param);
    } else {
      this.subFormsParams.splice(existingParamIndex, 1, param);
    }

    const errors = this.subFormsParams.filter((p) => p.valid === false);
    const touched = this.subFormsParams.filter((p) => p.name === param['name'] && param['touched'] === true);

    if (touched.length !== 0) {
      this.poEventConfigureForm.markAllAsTouched();
    }

    this.allSubFormsValid$.next(errors.length === 0);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private getValue(name: string, defaultValue: string): any {
    const param = this.data.element.params.find((p) => p.name === name);
    return param ? param.value : defaultValue;
  }

  private getMinimum(param): number {
    const minimum = param.value['exclusiveMinimum'] + 0.001;
    return minimum ? minimum : param.value.minimum;
  }
}

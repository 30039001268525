import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomOptionsCellParams } from '../custom-options-cell.params';

@Component({
  selector: 'grid-engine-icon-cell',
  templateUrl: './icon-cell.component.html',
  styleUrls: ['./icon-cell.component.scss']
})
export class IconCellComponent implements ICellRendererAngularComp {
  params: CustomOptionsCellParams;
  value = null;
  isIcon = true;
  protected readonly translateService: TranslateService = inject(TranslateService);

  agInit(params: CustomOptionsCellParams & { isFilterRenderer?: boolean }): void {
    this.params = params;
    if (!params.value) {
      this.isIcon = false;
      this.value = params.isFilterRenderer ? this.translateService.instant('AG_GRID.blanks') : params.value;
    } else if (params.value === this.translateService.instant('AG_GRID.selectAll')) {
      this.isIcon = false;
      this.value = params.value;
    } else {
      this.isIcon = true;
      this.value = params.value;
    }
  }

  refresh(): boolean {
    return false;
  }
}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard, LoginCallbackComponent } from '@iot-platform/auth';

import { LoginShellComponent } from '../../../../libs/auth/src/lib/containers/login-shell/login-shell.component';
import { AccountValidationComponent } from '../../../../libs/iot4bos-backoffice-ui/src/lib/features/account-validation/account-validation.component';
import { AcknowledgementsComponent } from '../../../../libs/iot4bos-ui/src/lib/features/acknowledgements/acknowledgements.component';

const routes: Routes = [
  {
    path: 'login',
    children: [
      {
        path: '',
        component: LoginShellComponent
      },
      {
        path: 'callback',
        component: LoginCallbackComponent
      }
    ]
  },
  { path: 'ack/:tokenId', component: AcknowledgementsComponent },
  { path: 'a/u', component: AccountValidationComponent },
  {
    path: '',
    loadChildren: () => import('@iot-platform/iot4bos-ui').then((m) => m.Iot4bosUiModule),
    canActivate: [AuthGuard]
  },
  { path: '', pathMatch: 'full', redirectTo: '/home' },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, enableTracing: false })],
  exports: [RouterModule]
})
export class AppRouterModule {}

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomCellParams } from '../custom-cell.params';

@Component({
  selector: 'grid-engine-basic-cell',
  templateUrl: './basic-cell.component.html',
  styleUrls: ['./basic-cell.component.scss']
})
export class BasicCellComponent implements ICellRendererAngularComp {
  params: CustomCellParams;
  cellOptions: any;

  sanitizer: DomSanitizer = inject(DomSanitizer);

  agInit(params: CustomCellParams): void {
    this.params = params;
    this.cellOptions = this.params.cellOptions;
  }

  refresh(): boolean {
    return false;
  }

  isEmpty(): boolean {
    return !this.params?.value && this.params?.value !== 0 && this.params?.value !== false;
  }
}

import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Product, ProductCatalog } from '@iot-platform/models/common';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';

export function checkProductIdUnicityValidator(allProducts: Product[], initialId: string, selectedCatalog): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let existing = [];
    if (control.value !== initialId) {
      existing = allProducts.filter((product) => product.identifier === control.value && selectedCatalog.id === product.catalog.id);
    }
    return existing.length > 0 ? { productExist: true } : null;
  };
}

@Component({
  selector: 'iot4bos-backoffice-ui-admin-product-creation-form',
  templateUrl: './admin-product-creation-form.component.html',
  styleUrls: ['./admin-product-creation-form.component.scss']
})
export class AdminProductCreationFormComponent implements OnInit, OnDestroy {
  productForm: UntypedFormGroup;
  subscriptions: Subscription[] = [];

  initialId = '';
  isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  displayDuplicateMessage$: Subject<boolean> = new Subject<boolean>();

  NAME_MAX_LENGTH = 50;
  ID_MAX_LENGTH = 20;

  @ViewChild('productIdInput') productIdInput: ElementRef;

  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<AdminProductCreationFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { product: Product; catalogs: ProductCatalog[]; allProducts: Product[] }
  ) {}

  get title$(): Observable<string> {
    return this.data.product
      ? this.translateService.get('ADMIN.PRODUCT_CATALOGS.FORM.TITLE_EDIT', { productName: this.data.product.name })
      : this.translateService.get('ADMIN.PRODUCT_CATALOGS.FORM.TITLE_CREATE');
  }

  get errorMessage$(): Observable<string> {
    return this.translateService.get('ADMIN.PRODUCT_CATALOGS.FORM.REQUIRED');
  }

  get name(): AbstractControl {
    return this.productForm.get('name');
  }

  get catalog(): AbstractControl {
    return this.productForm.get('catalog');
  }

  get productId(): AbstractControl {
    return this.productForm.get('productId');
  }

  get actionButtonLabel$(): Observable<string> {
    return of(this.data.product ? 'IOT_DICTIONARY.UPDATE' : 'IOT_DICTIONARY.CREATE');
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.productForm = new UntypedFormGroup({
      catalog: new UntypedFormControl(this.data.product ? this.data.product.catalog : '', [Validators.required]),
      productId: new UntypedFormControl(this.data.product ? this.data.product.identifier : '', [
        Validators.required,
        Validators.maxLength(this.ID_MAX_LENGTH),
        Validators.pattern('^[a-zA-Z0-9]*$')
      ]),
      name: new UntypedFormControl(this.data.product ? this.data.product.name.trim() : '', [Validators.required, Validators.maxLength(this.NAME_MAX_LENGTH)])
    });

    if (this.data.product) {
      this.initialId = this.data.product.identifier.trim();
      const selectedCatalog = this.data.catalogs.find((c) => c.id === this.data.product.catalog.id);
      this.productId.setValidators(checkProductIdUnicityValidator(this.data.allProducts, this.initialId, selectedCatalog));
      this.catalog.setValue(selectedCatalog);
      this.catalog.disable();
    }
  }

  initCatalogValidation() {
    this.productId.setValidators(
      checkProductIdUnicityValidator(this.data.allProducts, this.initialId !== '' ? this.initialId : this.productId.value, this.catalog.value)
    );
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const newProduct: Product = this.data.product
      ? { ...this.data.product, name: this.name.value, identifier: this.productId.value, catalog: this.catalog.value }
      : {
          name: this.name.value.trim(),
          identifier: this.productId.value.trim(),
          catalog: this.catalog.value
        };

    this.dialogRef.close(newProduct);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

import { HeaderType } from '@iot-platform/models/grid-engine';

export const poEventRulesMetadataSettings = {
  metadata: {
    url: '/po-event/rules',
    params: {
      limit: '100',
      page: '0'
    }
  },
  masterViewToolbar: {},
  masterViewFilterEngine: {
    masterView: 'po-event-rules',
    expanded: true
  },
  masterViewTable: {
    bluePrint: {
      autoRefresh: false,
      multipleSelection: false,
      enableConditions: [],
      visibleConditions: [],
      columns: [],
      expandedPanel: {},
      buttonColumn: {
        id: 'button',
        name: '',
        order: 7,
        width: '',
        headerType: HeaderType.BASIC,
        cellType: 'button',
        type: 'center center',
        enableConditions: [[{ key: 'isEditable', value: true }]],
        visibleConditions: [
          [
            { key: 'canUpdatePoEventRule', value: true },
            { key: 'canDeletePoEventRule', value: true }
          ]
        ],
        singleActions: [
          {
            key: 'edit',
            label: 'EDIT',
            enableConditions: [[{ key: 'isEditable', value: true }]],
            visibleConditions: [[{ key: 'canUpdatePoEventRule', value: true }]]
          },
          {
            key: 'configure',
            label: 'CONFIGURE',
            enableConditions: [[{ key: 'isEditable', value: true }]],
            visibleConditions: [[{ key: 'canUpdatePoEventRule', value: true }]]
          },
          {
            key: 'delete',
            label: 'DELETE',
            enableConditions: [[{ key: 'isActive', value: false }], [{ key: 'isEditable', value: true }]],
            visibleConditions: [[{ key: 'canDeletePoEventRule', value: true }]]
          }
        ]
      }
    }
  },
  masterViewComments: {}
};

/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { ContactNotification } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { NotificationsDbActions, NotificationsUiActions } from '../actions';
import { ContactsDbActions, ContactsService } from '@iot-platform/shared/data-access/contacts';

@Injectable()
export class NotificationsEffects {
  loadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsUiActions.loadNotifications),
      switchMap((action) =>
        this.contactsService.getNotificationsBySite(action.siteId).pipe(
          map((notifications: ContactNotification[]) => NotificationsDbActions.loadNotificationsSuccess({ notifications })),
          catchError((error) => of(NotificationsDbActions.loadNotificationsFailure({ error })))
        )
      )
    )
  );

  addNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsUiActions.addNotification),
      concatMap((action) =>
        this.contactsService.addNotification(action.notificationToAdd, action.contactIdsToSub, action.siteId).pipe(
          map((addedNotification: ContactNotification) => NotificationsDbActions.addNotificationSuccess({ addedNotification })),
          catchError((error) => of(NotificationsDbActions.addNotificationFailure({ error })))
        )
      )
    )
  );

  updateNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsUiActions.updateNotification),
      concatMap((action) =>
        this.contactsService.updateNotification(action.notificationToUpdate, action.siteId, action.contactIdsToSub, action.contactIdsToUnsub).pipe(
          map((updatedNotification: ContactNotification) => NotificationsDbActions.updateNotificationSuccess({ updatedNotification })),
          catchError((error) => of(NotificationsDbActions.updateNotificationFailure({ error })))
        )
      )
    )
  );

  deleteNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsUiActions.deleteNotification),
      concatMap((action) =>
        this.contactsService.deleteNotification(action.notificationToDelete, action.siteId).pipe(
          map((deletedNotification: ContactNotification) => NotificationsDbActions.deleteNotificationSuccess({ deletedNotification })),
          catchError((error) => of(NotificationsDbActions.deleteNotificationFailure({ error })))
        )
      )
    )
  );

  addThenSelectNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsDbActions.addNotificationSuccess),
      map((action) => NotificationsUiActions.selectNotification({ notificationToSelect: action.addedNotification }))
    )
  );

  configureContactNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsUiActions.configureNotifications),
      concatMap((action) =>
        this.contactsService.updateContactNotifications(action.contact, action.notificationIdsToBeSubTo, action.notificationIdsToBeUnsubFrom).pipe(
          map((updatedNotifications: ContactNotification[]) => NotificationsDbActions.configureNotificationsSuccess({ updatedNotifications })),
          catchError((error) => of(NotificationsDbActions.configureNotificationsFailure({ error })))
        )
      )
    )
  );

  deleteContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactsDbActions.deleteContactSuccess),
      map((action) => NotificationsUiActions.loadNotifications({ siteId: action.deletedContact.site?.id as string }))
    )
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          NotificationsUiActions.addNotification,
          NotificationsUiActions.updateNotification,
          NotificationsUiActions.deleteNotification,
          NotificationsUiActions.configureNotifications
        ),
        tap(() => this.notificationService.displayLoader(true))
      ),
    { dispatch: false }
  );

  hideLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          NotificationsDbActions.addNotificationSuccess,
          NotificationsDbActions.addNotificationFailure,
          NotificationsDbActions.updateNotificationSuccess,
          NotificationsDbActions.updateNotificationFailure,
          NotificationsDbActions.deleteNotificationSuccess,
          NotificationsDbActions.deleteNotificationFailure,
          NotificationsDbActions.configureNotificationsSuccess,
          NotificationsDbActions.configureNotificationsFailure
        ),
        tap(() => this.notificationService.displayLoader(false))
      ),
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          NotificationsDbActions.addNotificationSuccess,
          NotificationsDbActions.updateNotificationSuccess,
          NotificationsDbActions.deleteNotificationSuccess,
          NotificationsDbActions.configureNotificationsSuccess
        ),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          NotificationsDbActions.loadNotificationsFailure,
          NotificationsDbActions.addNotificationFailure,
          NotificationsDbActions.updateNotificationFailure,
          NotificationsDbActions.deleteNotificationFailure,
          NotificationsDbActions.configureNotificationsFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private readonly contactsService: ContactsService,
    private readonly notificationService: NotificationService
  ) {}
}

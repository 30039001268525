<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ title$ | async }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content" color="accent">
    <form [formGroup]="form" fxLayout="row">
      <div fxFlex="40" fxLayoutAlign="center center">
        <mat-icon class="asset-variable-icon" svgIcon="asset_variable"></mat-icon>
      </div>

      <div class="p-20" fxFlex="60" fxLayout="column" fxLayoutAlign="space-between">
        <mat-form-field class="width-65" color="accent">
          <mat-label>{{ 'ASSETS.VARIABLE_FORM.NAME' | translate }}</mat-label>
          <input #nameInput type="text" formControlName="name" matInput maxlength="40" pattern="\S.*" required [matAutocomplete]="nameAutocomplete" />
          <mat-autocomplete #nameAutocomplete="matAutocomplete">
            @for (category of filteredNormalizedVariablesByCategory(); track category) {
              <mat-optgroup [label]="category.categoryName">
                @for (variable of category.variables; track variable) {
                  <mat-option [value]="variable.name" (onSelectionChange)="onNormalizedVariableSelected(variable)">{{ variable.name }}</mat-option>
                }
              </mat-optgroup>
            }
          </mat-autocomplete>
          @if (name.invalid && (name.errors?.maxlength || name.errors?.pattern)) {
            <mat-error>{{ 'ASSETS.VARIABLE_FORM.ERROR_MESSAGE.REQUIRED' | translate }}</mat-error>
          }
          @if (name.invalid && name.errors?.duplicate) {
            <mat-error>{{ 'ASSETS.VARIABLE_FORM.ERROR_MESSAGE.DUPLICATE' | translate }}</mat-error>
          }
          <mat-hint align="end">{{ nameInput.value.length || 0 }}/40</mat-hint>
        </mat-form-field>

        @if (selectedNormalizedVariable && selectedNormalizedVariable.description) {
          <div class="fs-10" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="fs-16" fxLayout="row" fxLayoutAlign="start center">info_outlined</mat-icon
            ><span>{{ selectedNormalizedVariable.description | translate }}</span>
          </div>
        }

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
          <shared-iot4bos-asset-ui-variable-units-autocomplete
            [clearUnit]="clearUnit"
            [currentUnit]="unit.value"
            (selectUnit)="unit.setValue($event)"
          ></shared-iot4bos-asset-ui-variable-units-autocomplete>

          <mat-form-field color="accent" fxFlex="40">
            <mat-label>{{ 'ASSETS.VARIABLE_FORM.FOLLOWED_NUMBER' | translate }}</mat-label>
            <mat-select formControlName="followedNumber">
              <mat-option [value]="null">{{ 'ASSETS.VARIABLE_FORM.FOLLOWED_NUMBER_NONE' | translate }}</mat-option>
              @for (followedNumber of followedNumbers; track followedNumber) {
                <mat-option [disabled]="data.followedVariableNumbers.includes(followedNumber)" [value]="followedNumber">
                  {{ followedNumber }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field color="accent">
          <mat-label>{{ 'ASSETS.VARIABLE_FORM.COMMENT' | translate }}</mat-label>
          <textarea #commentInput formControlName="comment" matInput maxlength="60" rows="1"></textarea>
          <mat-hint align="end">{{ commentInput.value.length || 0 }}/60</mat-hint>
        </mat-form-field>
        <p class="dialog-form-tips">{{ 'ASSETS.VARIABLE_FORM.MANDATORY_FIELDS' | translate }}</p>
      </div>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'ASSETS.VARIABLE_FORM.CANCEL' | translate }}</button>
    @if (!data.variable) {
      @if (data.canConfigure) {
        <button (click)="save(true)" [disabled]="form.invalid" class="button-regular" color="accent" mat-raised-button>
          {{ 'ASSETS.VARIABLE_FORM.CREATE_CONFIGURE' | translate }}
        </button>
      }
      <button (click)="save(false)" [disabled]="form.invalid" class="button-regular" color="accent" mat-raised-button>
        {{ 'ASSETS.VARIABLE_FORM.CREATE_CLOSE' | translate }}
      </button>
    } @else {
      <button (click)="save(false)" [disabled]="form.invalid" class="button-regular" color="accent" mat-raised-button>
        {{ 'ASSETS.VARIABLE_FORM.SAVE' | translate }}
      </button>
    }
  </mat-card-actions>
</mat-card>

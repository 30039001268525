import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AssetVariable, DeviceVariable } from '@iot-platform/models/i4b';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VariableChartService {
  constructor(@Inject('environment') private environment, private http: HttpClient) {}

  public loadTimeseriesByVariableId(type: string, id: string, variableId: string, start: string, end: string, limit: number): Observable<any> {
    let params = new HttpParams();
    params = params.set('start', start);
    params = params.set('end', end);
    params = params.set('limit', limit.toString(10));
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers, params };
    return this.http.get(`${this.environment.api.url}/${type}s/${id}/variables/${variableId}/timeseries`, options);
  }

  public loadTimeseriesByVariables(
    variables: {
      id: string;
      name: string;
      unit: string;
      parentId: string;
      parentName: string;
      parentType: string;
    }[],
    start: string,
    end: string,
    limit: number
  ) {
    const variables$ = variables.map((variable) => this.loadTimeseriesByVariableId(variable.parentType, variable.parentId, variable.id, start, end, limit));

    return combineLatest(variables$).pipe(
      map((series) => series.map((serie, idx) => ({ ...variables[idx], series: serie.timeseries })))
    );
  }

  loadVariables(
    variables: {
      id: string;
      name: string;
      unit: string;
      parentId: string;
      parentName: string;
      parentType: string;
    }[]
  ): Observable<DeviceVariable[] | AssetVariable[]> {
    const uniqueVariables = variables.reduce((acc, current) => {
      if (!acc.some((v) => v.parentId === current.parentId)) {
        acc.push(current);
      }
      return acc;
    }, []);
    const variables$ = uniqueVariables.map(({ parentId, parentType }) => {
      const context: string = parentType === 'device' ? this.environment.api.endpoints.devices : this.environment.api.endpoints.assets;
      return this.http
        .get<DeviceVariable[] | AssetVariable[]>(`${this.environment.api.url + context}/${parentId}/variables?limit=1000&page=0`)
        .pipe(map((data: any) => data.content));
    });

    return combineLatest(variables$).pipe(
      map((data) =>
        data.reduce((acc, arr) => acc.concat(arr), [])
      )
    );
  }
}

import { IotGeoJsonFeature, IotMapDisplayMode } from '@iot-platform/iot-platform-maps';
import * as Leaflet from 'leaflet';
import { MapSvgHelper } from './map-svg.helper';
import { ThresholdsHelper } from './thresholds.helper';

/* eslint-disable @typescript-eslint/no-unused-vars */
export class MapClustersHelper {
  static createClusterIcon(cluster: Leaflet.MarkerCluster, concept: string, displayMode: IotMapDisplayMode) {
    const markers = cluster.getAllChildMarkers();
    const childCount = cluster.getChildCount();

    let cptCCF = 0;
    let notCCF = 0;
    const total = 0;

    let assetsClusters: { color: string; strokeDashOffset: string; strokeDashArray: string; count: number }[] = [];
    let sitesClusters: { color: string; strokeDashOffset: string; strokeDashArray: string; count: number }[] = [];

    if (concept === 'assets') {
      assetsClusters = this.getAssetsClusters([...markers]);
    }

    if (concept === 'sites' && displayMode === 'matchScore') {
      sitesClusters = this.getSitesClusters([...markers]);
    }

    markers.forEach((marker) => {
      const feature: IotGeoJsonFeature = marker.feature as IotGeoJsonFeature;

      switch (concept) {
        case 'devices':
          cptCCF += feature?.options?.hasOwnProperty('ccf') && feature?.options['ccf'] === true ? 1 : 0;
          notCCF += feature?.options?.hasOwnProperty('ccf') && feature?.options['ccf'] === false ? 1 : 0;
          break;
        case 'sites':
          switch (displayMode) {
            case 'activeEvents':
              cptCCF +=
                (feature?.options?.hasOwnProperty('assetActiveEvents') && feature?.options['assetActiveEvents'] > 0) ||
                (feature?.options?.hasOwnProperty('deviceActiveEvents') && feature?.options['deviceActiveEvents'] > 0)
                  ? 1
                  : 0;
              notCCF +=
                feature?.options?.hasOwnProperty('assetActiveEvents') &&
                feature?.options['assetActiveEvents'] === 0 &&
                feature?.options?.hasOwnProperty('deviceActiveEvents') &&
                feature?.options['deviceActiveEvents'] === 0
                  ? 1
                  : 0;
              break;
            default:
              break;
          }
          break;
        case 'assets':
          break;
        default:
          break;
      }
    });

    let grandTotal = 0;
    if (total > 0) {
      grandTotal = total;
    } else {
      grandTotal = childCount;
    }
    const size = childCount > 1000 ? '200%' : childCount > 500 ? '160%' : childCount > 100 ? '130%' : '100%';
    const displayCCF = (cptCCF / grandTotal) * 100;
    const html =
      concept !== 'assets'
        ? concept === 'sites' && displayMode === 'matchScore'
          ? this.getAssetHtml(size, childCount, sitesClusters)
          : this.getBasicHtml(concept, displayMode, size, childCount, displayCCF)
        : this.getAssetHtml(size, childCount, assetsClusters);

    return Leaflet.divIcon({ html, className: 'marker-cluster', iconSize: Leaflet.point(40, 40) });
  }

  static getBasicHtml(concept: string, displayMode: IotMapDisplayMode, size: string, childCount: number, displayCCF: number) {
    const htmlRedGreenCluster = MapSvgHelper.getCCFSvgIcon(size, displayCCF, childCount);
    const htmlStandardCluster = MapSvgHelper.getStandardSvgIcon(size, childCount);

    return concept === 'devices'
      ? displayMode === 'ccf'
        ? htmlRedGreenCluster
        : htmlStandardCluster
      : concept === 'sites'
        ? displayMode === 'activeEvents'
          ? htmlRedGreenCluster
          : htmlStandardCluster
        : htmlStandardCluster;
  }

  public static getApplicableScore(matchScore: number): { min: number; max: number; color: string } {
    const mScoreRed: { min: number; max: number; color: string } = { min: 0, max: 0.4, color: '#E75D70' };
    const mScoreOrange: { min: number; max: number; color: string } = { min: 0.4, max: 0.7, color: '#F58C2D' };
    const mScoreGreen: { min: number; max: number; color: string } = { min: 0.7, max: 0.9, color: '#7CCA6F' };
    const mScoreBlue: { min: number; max: number; color: string } = { min: 0.9, max: 1.01, color: '#375F9B' };
    const mScoreGrey: { min: number; max: number; color: string } = { min: 0, max: 0, color: '#96A5AF' };

    const scores: { min: number; max: number; color: string }[] = [mScoreRed, mScoreOrange, mScoreGreen, mScoreBlue];

    let applicableScore: { min: number; max: number; color: string } | undefined = mScoreGrey;

    if (matchScore) {
      applicableScore = scores.find((score: { min: number; max: number; color: string }) => matchScore < score.max && matchScore >= score.min);
    }

    return applicableScore ?? mScoreGrey;
  }

  private static getAssetHtml(
    size: string,
    childCount: number,
    clusters: { color: string; strokeDashOffset: string; strokeDashArray: string; count: number }[]
  ) {
    const html = MapSvgHelper.getVariableSvgIcon(size, childCount, clusters);
    return html;
  }

  private static getAssetsClusters(markers: Leaflet.Marker[]): { color: string; strokeDashOffset: string; strokeDashArray: string; count: number }[] {
    const noValueColor = '#375F9B';
    let total = 1;
    const toCluster: { color: string; strokeDashOffset: string; strokeDashArray: string; count: number }[] = [
      { color: noValueColor, strokeDashOffset: '25', strokeDashArray: '', count: 0 }
    ];

    return markers.reduce((acc: any, marker) => {
      const feature: IotGeoJsonFeature = marker.feature as IotGeoJsonFeature;
      if (feature?.options) {
        total++;
        const offset = '25';
        if (feature?.options['thresholds'] && feature?.options['thresholds'].values && feature?.options['thresholds'].values.length > 0) {
          const threshold = feature?.options['thresholds'];
          const effectiveThreshold = ThresholdsHelper.getEffectiveThreshold(feature, threshold);
          if (effectiveThreshold && effectiveThreshold.hasOwnProperty('lineColor')) {
            const found = acc.find((x: any) => x.color === effectiveThreshold.lineColor);

            if (!found) {
              acc.push({ count: 1, color: effectiveThreshold.lineColor, strokeDashOffset: offset, strokeDashArray: '' });
            } else {
              const index = acc.findIndex((x: any) => x.color === effectiveThreshold.lineColor);
              const toUpdate = { count: found.count++, ...found };
              acc[index] = toUpdate;
            }
          } else {
            const noValueIndex = acc.findIndex((x: any) => x.color === noValueColor);
            const noValue = acc[noValueIndex];
            const toUpdate = { count: noValue.count++, ...noValue };
            acc[noValueIndex] = toUpdate;
          }
        } else {
          const noValueIndex = acc.findIndex((x: any) => x.color === noValueColor);
          const noValue = acc[noValueIndex];
          const toUpdate = { count: noValue.count++, ...noValue };
          acc[noValueIndex] = toUpdate;
        }
      }
      return acc;
    }, toCluster);
  }

  private static getSitesClusters(markers: Leaflet.Marker[]): { color: string; strokeDashOffset: string; strokeDashArray: string; count: number }[] {
    const noValueColor = '#375F9B';
    let toCluster: { color: string; strokeDashOffset: string; strokeDashArray: string; count: number }[] = [
      { color: noValueColor, strokeDashOffset: '25', strokeDashArray: '', count: 0 }
    ];
    toCluster = markers.reduce((acc: any, marker) => {
      const feature: IotGeoJsonFeature = marker.feature as IotGeoJsonFeature;
      const offset = '25';

      const applicableScore = this.getApplicableScore(feature?.options['matchScore']);
      const found = acc.find((x: any) => x.color === applicableScore.color);

      if (!found) {
        acc.push({ count: 1, color: applicableScore.color, strokeDashOffset: offset, strokeDashArray: '' });
      } else {
        const index = acc.findIndex((x: any) => x.color === applicableScore.color);
        const toUpdate = { count: found.count++, ...found };
        acc[index] = toUpdate;
      }
      return acc;
    }, toCluster);

    return toCluster;
  }
}

import { BaseDevice } from '../common';
import { Context } from '../common/context.model';
import { Status } from '../common/status.model';
import { Asset } from './asset.model';
import { DeviceVariable } from './device-variable.model';
import { Site } from './site.model';

export interface Device extends BaseDevice {
  assets?: Asset[]; // à bon ?
  connector: string;
  context?: Context;
  credential?: { login?: string; password?: string; cert?: string; key?: string };
  expandedVariables: { [key: string]: Partial<DeviceVariable> } | NonNullable<unknown>;
  isConfigureCommandEnabled?: boolean;
  isMovable?: boolean;
  isRefreshCommandEnabled?: boolean;
  lastConfStatus?: Status;
  serviceId?: string;
  sharedAuthentication?: boolean;
  site?: Site;
  status?: DeviceStatus;
}

export interface DeviceStatus extends Status {
  name: DeviceStatusName;
}

export enum DeviceStatusName {
  test_mode = 'test_mode',
  standby = 'standby',
  running = 'running',
  maintenance = 'maintenance',
  decommissioned = 'decommissioned'
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromGridEngine from '../reducers';
import { GridsDbActions } from '../actions';
import { Observable } from 'rxjs';
import { I4BGridSort } from '@iot-platform/models/grid-engine';
import { CommonApiRequest, Filter } from '@iot-platform/models/common';

@Injectable({
  providedIn: 'root'
})
export class GridEngineFacade {
  constructor(private readonly store: Store) {}

  allGrids$ = this.store.select(fromGridEngine.getAllGrids);

  getDefaultGrid(masterview: string) {
    return this.store.select(fromGridEngine.selectDefaultGridByMasterview(masterview));
  }

  getTotalDefaultGrid(masterview: string): Observable<number> {
    return this.store.select(fromGridEngine.selectTotalByGrid(masterview));
  }

  getLoadedDefaultGrid(masterview: string): Observable<boolean> {
    return this.store.select(fromGridEngine.selectDataLoadedByGrid(masterview));
  }

  getSortDefaultGrid(masterview: string): Observable<I4BGridSort[]> {
    return this.store.select(fromGridEngine.selectSortByGrid(masterview));
  }

  selectGridAndLoadData(gridId: string, masterview: string, filters: Filter[] = [], endPoint: string = ''): void {
    this.store.dispatch(GridsDbActions.selectGridAndLoadData({ gridId, masterview, filters, endPoint }));
  }

  reloadGridData(request: CommonApiRequest): void {
    this.store.dispatch(GridsDbActions.loadGridData({ request }));
  }
}

import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { Filter, Pagination } from '@iot-platform/models/common';
import { PoEventRule } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { POEventRulesDbActions, POEventRulesUiActions } from '../actions/';

export const poEventRuleDbFeatureKey = 'poEventRuleDb';

export interface State extends EntityState<PoEventRule> {
  selectedPoEventRuleId: string | null;
  pagination: Pagination;
  addedRuleForConfig: PoEventRule | null;
  currentFilters: Filter[];
  error: any;
}

export const adapter: EntityAdapter<PoEventRule> = createEntityAdapter<PoEventRule>({
  selectId: (poEventRule: PoEventRule) => poEventRule.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedPoEventRuleId: null,
  pagination: { currentPage: 0, total: 0, maxPage: 0, hasMore: false, limit: 100 },
  addedRuleForConfig: null,
  error: null,
  currentFilters: []
});

export const poEventRulesDbReducer = createReducer(
  initialState,
  on(POEventRulesUiActions.listPOEventRules, (state) => adapter.removeAll({ ...state, addedRuleForConfig: null })),
  on(POEventRulesDbActions.listPOEventRulesSuccess, (state, { response }) =>
    adapter.setAll(response.data, {
      ...state,
      pagination: { currentPage: response.currentPage, hasMore: response.hasMore, limit: response.limit, maxPage: response.maxPage, total: response.total }
    })
  ),
  on(POEventRulesDbActions.listPOEventRulesFailure, (state, { error }) => ({ ...state, error })),

  on(POEventRulesUiActions.loadPOEventRule, (state) => ({ ...state, selectedPoEventRuleId: null })),
  on(POEventRulesDbActions.loadPOEventRuleSuccess, (state, { ruleLoaded }) =>
    adapter.setOne(ruleLoaded, { ...state, selectedPoEventRuleId: ruleLoaded.id as unknown as string })
  ),
  on(POEventRulesDbActions.loadPOEventRuleFailure, (state, { error }) => ({ ...state, error })),

  on(POEventRulesDbActions.addPOEventRuleSuccess, (state, { addedRule }) =>
    adapter.addOne(addedRule, { ...state, pagination: { ...state.pagination, total: state.pagination.total + 1 } })
  ),
  on(POEventRulesDbActions.addPOEventRuleFailure, (state, { error }) => ({ ...state, error })),

  on(POEventRulesDbActions.addPOEventRuleThenConfigureSuccess, (state, { addedRule }) =>
    adapter.addOne(addedRule, { ...state, addedRuleForConfig: addedRule, pagination: { ...state.pagination, total: state.pagination.total + 1 } })
  ),
  on(POEventRulesUiActions.cancelAddPOEventRuleThenConfigure, (state) => ({ ...state, addedRuleForConfig: null })),
  on(POEventRulesDbActions.addPOEventRuleThenConfigureFailure, (state, { error }) => ({ ...state, error })),
  on(POEventRulesDbActions.updatePOEventRuleSuccess, (state, { updatedRule }) =>
    adapter.updateOne({ id: updatedRule.id, changes: updatedRule }, { ...state, addedRuleForConfig: null })
  ),
  on(POEventRulesDbActions.updatePOEventRuleFailure, (state, { error }) => ({ ...state, error })),

  on(POEventRulesDbActions.deletePOEventRuleSuccess, (state, { deletedRule }) =>
    adapter.removeOne(deletedRule.id, { ...state, pagination: { ...state.pagination, total: state.pagination.total - 1 } })
  ),
  on(POEventRulesDbActions.deletePOEventRuleFailure, (state, { error }) => ({ ...state, error })),
  on(POEventRulesUiActions.savePOEventRuleFilters, (state: State, { filters }) => ({ ...state, currentFilters: filters })),
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return poEventRulesDbReducer(state, action);
}

export const getSelectedPoEventRuleId = (state: State) => state.selectedPoEventRuleId;
export const getPagination = (state: State) => state.pagination;
export const getAddedRuleForConfig = (state: State) => state.addedRuleForConfig;
export const getCurrentFilters = (state: State) => state.currentFilters;

import { Action, createReducer, on } from '@ngrx/store';

import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';

import { DeviceEventsByDeviceDbActions, DeviceEventsByDeviceUiActions } from '../../actions';

export const deviceEventsByDeviceUiFeatureKey = 'deviceEventsByDeviceUi';

export interface State {
  error?: any;
  deviceEventsLoaded: boolean;
  deviceEventsLoading: boolean;
  totalActiveEventsLoaded: boolean;
}

export const initialState: State = {
  error: null,
  deviceEventsLoaded: false,
  deviceEventsLoading: false,
  totalActiveEventsLoaded: false
};

const deviceEventsByDeviceUiReducer = createReducer(
  initialState,
  on(DeviceEventsByDeviceUiActions.loadDeviceEventsByDevice, (state: State) => ({ ...state, deviceEventsLoading: true, deviceEventsLoaded: false })),
  on(DeviceEventsByDeviceDbActions.loadDeviceEventsByDeviceSuccess, (state: State) => ({ ...state, deviceEventsLoading: false, deviceEventsLoaded: true })),
  on(DeviceEventsByDeviceDbActions.loadDeviceEventsByDeviceFailure, (state: State, { error }) => ({
    ...state,
    deviceEventsLoading: false,
    deviceEventsLoaded: false,
    error
  })),
  // ****
  on(DeviceEventsByDeviceUiActions.bulkUpdateStatusByDeviceEventIdByDevice, (state: State) => ({ ...state, deviceEventsLoaded: false })),
  on(DeviceEventsByDeviceDbActions.updateStatusByDeviceEventIdByDeviceSuccess, (state: State) => ({ ...state, deviceEventsLoaded: true })),
  on(DeviceEventsByDeviceDbActions.updateStatusByDeviceEventIdByDeviceFailure, (state: State, { error }) => ({ ...state, deviceEventsLoaded: false, error })),
  // ****
  on(DeviceEventsByDeviceUiActions.loadTotalActiveDeviceEventsByDevice, (state: State) => ({ ...state, totalActiveEventsLoaded: false })),
  on(DeviceEventsByDeviceDbActions.loadTotalActiveDeviceEventsByDeviceSuccess, (state: State) => ({ ...state, totalActiveEventsLoaded: true })),
  on(DeviceEventsByDeviceDbActions.loadTotalActiveDeviceEventsByDeviceFailure, (state: State, { error }) => ({
    ...state,
    totalActiveEventsLoaded: false,
    error
  })),
  // ****
  on(DeviceEventsByDeviceUiActions.loadMvDeviceEventsByDeviceSettings, (state: State) => ({ ...state, deviceEventsLoaded: false })),
  on(DeviceEventsByDeviceDbActions.loadMvDeviceEventsByDeviceSettingsSuccess, (state: State) => ({ ...state, deviceEventsLoaded: true })),
  on(DeviceEventsByDeviceDbActions.loadMvDeviceEventsByDeviceSettingsFailure, (state: State, { error }) => ({ ...state, deviceEventsLoaded: false, error })),
  // ****
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return deviceEventsByDeviceUiReducer(state, action);
}

export const getDeviceEventsLoaded = (state: State) => state.deviceEventsLoaded;
export const getDeviceEventsLoading = (state: State) => state.deviceEventsLoading;
export const getTotalActiveEventsLoaded = (state: State) => state.totalActiveEventsLoaded;

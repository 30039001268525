/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Contact } from '@iot-platform/models/common';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { ContactsDbActions, ContactsUiActions } from '../actions';
import { ContactsService } from '../../services';

@Injectable()
export class ContactsEffects {
  loadContacts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContactsUiActions.loadContacts),
      concatMap((action) =>
        this.contactsService.loadContacts(action.siteId).pipe(
          map((contacts: Contact[]) => ContactsDbActions.loadContactsSuccess({ contacts })),
          catchError((error) => of(ContactsDbActions.loadContactsFailure({ error })))
        )
      )
    );
  });

  addContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContactsUiActions.addContact),
      concatMap((action) =>
        this.contactsService.addContact(action.contact).pipe(
          map((addedContact: Contact) => ContactsDbActions.addContactSuccess({ addedContact })),
          catchError((error) => of(ContactsDbActions.addContactFailure({ error })))
        )
      )
    );
  });

  updateContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContactsUiActions.updateContact),
      concatMap((action) =>
        this.contactsService.updateContact(action.contact).pipe(
          map((updatedContact: Contact) => ContactsDbActions.updateContactSuccess({ updatedContact })),
          catchError((error) => of(ContactsDbActions.updateContactFailure({ error })))
        )
      )
    );
  });

  deleteContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContactsUiActions.deleteContact),
      concatMap((action) =>
        this.contactsService.deleteContact(action.contact).pipe(
          concatMap((deletedContact: Contact) => [ContactsDbActions.deleteContactSuccess({ deletedContact })]),
          catchError((error) => of(ContactsDbActions.deleteContactFailure({ error })))
        )
      )
    );
  });

  displayLoader$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ContactsUiActions.addContact, ContactsUiActions.updateContact, ContactsUiActions.deleteContact),
        tap((_) => {
          this.notificationService.displayLoader(true);
        })
      );
    },
    { dispatch: false }
  );

  hideLoader$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ContactsDbActions.addContactSuccess,
          ContactsDbActions.updateContactSuccess,
          ContactsDbActions.deleteContactSuccess,
          ContactsDbActions.addContactFailure,
          ContactsDbActions.updateContactFailure,
          ContactsDbActions.deleteContactFailure
        ),
        tap((_) => {
          this.notificationService.displayLoader(false);
        })
      );
    },
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ContactsDbActions.addContactSuccess, ContactsDbActions.updateContactSuccess, ContactsDbActions.deleteContactSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      );
    },
    { dispatch: false }
  );

  displayError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ContactsDbActions.loadContactsFailure,
          ContactsDbActions.addContactFailure,
          ContactsDbActions.updateContactFailure,
          ContactsDbActions.deleteContactFailure
        ),
        tap((action) => {
          this.notificationService.displayError(action);
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly contactsService: ContactsService,
    private readonly notificationService: NotificationService
  ) {}
}

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { DateIntervalUtils } from '@iot-platform/iot-platform-utils';
import * as moment from 'moment';
import { Moment } from 'moment-timezone';
import { CustomDateCellParams } from '../custom-date-cell.params';

@Component({
  selector: 'grid-engine-date-cell',
  templateUrl: './date-cell.component.html',
  styleUrls: ['./date-cell.component.scss']
})
export class DateCellComponent implements ICellRendererAngularComp {
  params: CustomDateCellParams;

  dateFormat: string;
  dateTimezone: string;
  isRecentDay: boolean;
  isRecentHour: boolean;
  isFutureDay: boolean;
  isNextDay: boolean;

  removeTimezone = false;

  agInit(params: CustomDateCellParams): void {
    this.params = params;

    this.dateFormat = this.getFormat();
    this.dateTimezone = this.getTimezone();
    this.removeTimezone = this.initTimezoneRemoval();

    this.processDateIntervals(moment(), this.params.value);
  }

  refresh(): boolean {
    return false;
  }

  getFormat(): string {
    return this.params.cellOptions?.columnFormat ?? '';
  }

  getTimezone() {
    if (this.params.cellOptions && this.params.cellOptions.columnTimezone) {
      return this.params.value[this.params.cellOptions.columnTimezone];
    } else {
      return null;
    }
  }

  initTimezoneRemoval() {
    return this.params.cellOptions?.removeTimezone === true;
  }

  processDateIntervals(now: Moment, date: string): void {
    this.isRecentDay = DateIntervalUtils.isDateInThePastDay(now, date);
    this.isRecentHour = DateIntervalUtils.isDateInThePastHour(now, date);
    this.isNextDay = DateIntervalUtils.isDateInTheNextDay(now, date);
    this.isFutureDay = DateIntervalUtils.isDateInTheFutureDays(now, date);
  }
}

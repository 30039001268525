import { AssetStatusName } from '@iot-platform/models/i4b';
import { I4BCellType } from '../configurations';
import { I4bCellConcept } from '../enums';
import { HeaderType } from '../models';
import { I4BButtonColumn } from './i4b-button.column';

export class I4BAssetButtonColumn extends I4BButtonColumn {
  constructor() {
    super(
      {
        type: HeaderType.CALL_TO_ACTION,
        displayName: 'BUTTON',
        icon: ''
      },
      {
        concept: I4bCellConcept.ASSETS,
        isConfigurable: false,
        isDefault: true,
        isLink: false,
        position: '',
        sortProperty: '',
        width: 10,
        id: 'button',
        cell: {
          type: I4BCellType.CALL_TO_ACTION,
          singleActions: [
            {
              order: 20,
              key: 'refresh',
              label: 'ASSETS.CARD.REFRESH',
              enableConditions: [[{ key: 'isRefreshCommandEnabled', value: true }]],
              visibleConditions: [[{ key: 'canReadAsset', value: true }]]
            },
            {
              order: 10,
              key: 'edit',
              label: 'IOT_DICTIONARY.EDIT',
              disableConditions: [[{ key: 'status.name', value: AssetStatusName.decommissioned }]],
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateAsset', value: true }]]
            },
            {
              order: 10,
              key: 'edit',
              label: 'IOT_DICTIONARY.EDIT',
              disableConditions: [[{ key: 'status.name', value: AssetStatusName.decommissioned }]],
              enableConditions: [[{ key: 'optionalProperties.deliveryDate', value: true }]],
              visibleConditions: [[{ key: 'canUpdateAsset', value: false }], [{ key: 'canUpdateAssetContent', value: true }]]
            },
            {
              order: 15,
              key: 'configureProducts',
              label: 'ASSETS.CARD.CONFIGURE_PRODUCTS',
              disableConditions: [[{ key: 'status.name', value: AssetStatusName.decommissioned }]],
              enableConditions: [
                [
                  { key: 'optionalProperties.product1', value: true },
                  { key: 'optionalProperties.product2', value: true }
                ]
              ],
              visibleConditions: [[{ key: 'canUpdateAsset', value: true }]]
            },
            {
              order: 15,
              key: 'configureProducts',
              label: 'ASSETS.CARD.CONFIGURE_PRODUCTS',
              disableConditions: [
                [{ key: 'status.name', value: AssetStatusName.decommissioned }],
                [{ key: 'product1', value: null }],
                [{ key: 'product2', value: null }]
              ],
              enableConditions: [
                [
                  { key: 'optionalProperties.product1', value: true },
                  { key: 'optionalProperties.product2', value: true }
                ]
              ],
              visibleConditions: [[{ key: 'canUpdateAsset', value: false }], [{ key: 'canUpdateAssetContent', value: true }]]
            },
            {
              order: 17,
              key: 'removeProducts',
              label: 'ASSETS.CARD.REMOVE_PRODUCTS',
              disableConditions: [
                [
                  { key: 'product1', value: null },
                  { key: 'status.name', value: AssetStatusName.decommissioned }
                ],
                [
                  { key: 'product2', value: null },
                  { key: 'status.name', value: AssetStatusName.decommissioned }
                ]
              ],
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateAsset', value: true }]]
            }
          ],
          bulkActions: [
            {
              order: 10,
              key: 'bulkAddTag',
              label: 'GRID_ENGINE.COLUMNS.CTA.ADD_TAGS',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateAsset', value: true }]]
            },
            {
              order: 20,
              key: 'bulkRemoveTag',
              label: 'GRID_ENGINE.COLUMNS.CTA.REMOVE_TAGS',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateAsset', value: true }]]
            },
            {
              order: 30,
              key: 'bulkRefresh',
              label: 'ASSETS.CARD.REFRESH',
              enableConditions: [],
              visibleConditions: [[{ key: 'canReadAsset', value: true }]]
            }
          ],
          visibleConditions: [[{ key: 'canReadAsset', value: true }]],
          enableConditions: []
        }
      },
      { order: 1000 }
    );
  }
}

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { DateIntervalUtils } from '@iot-platform/iot-platform-utils';
import { DiagnosticVariableCellOptionsDisplayModeType } from '@iot-platform/models/grid-engine';
import { DeviceVariable } from '@iot-platform/models/i4b';
import { get } from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment-timezone';
import { DiagnosticVariableCellHelper } from '../../helpers/diagnostic-variable-cell.helper';
import { CustomDiagnosticVariableCellParams } from '../custom-diagnostic-variable-cell.params';

@Component({
  selector: 'grid-engine-diagnostic-variable-cell',
  templateUrl: './diagnostic-variable-cell.component.html',
  styleUrls: ['./diagnostic-variable-cell.component.scss']
})
export class DiagnosticVariableCellComponent implements ICellRendererAngularComp {
  params: CustomDiagnosticVariableCellParams;
  displayMode: DiagnosticVariableCellOptionsDisplayModeType;
  variable: DeviceVariable;
  isRecentDay: boolean;
  isRecentHour: boolean;
  isFutureDay: boolean;
  isNextDay: boolean;

  agInit(params: CustomDiagnosticVariableCellParams): void {
    this.params = params;
    this.displayMode = get(params, ['cellOptions', 'displayMode']);
    this.variable = DiagnosticVariableCellHelper.getDiagnosticVariableByCategory(
      get(params, ['cellOptions', 'variableCategory'], ''),
      get(params, ['data', 'expandedVariables'], {})
    ) as DeviceVariable;
    if (this.displayMode === 'datetime') {
      this.processDateIntervals(moment(), this.variable?.lastValue?.datetime);
    }
  }

  refresh(): boolean {
    return false;
  }

  processDateIntervals(now: Moment, date: string): void {
    this.isRecentDay = DateIntervalUtils.isDateInThePastDay(now, date);
    this.isRecentHour = DateIntervalUtils.isDateInThePastHour(now, date);
    this.isNextDay = DateIntervalUtils.isDateInTheNextDay(now, date);
    this.isFutureDay = DateIntervalUtils.isDateInTheFutureDays(now, date);
  }

  onDispatchEvent(event: MouseEvent): void {
    event.stopPropagation();
    this.params.dispatchEvent({
      type: this.params.eventConfiguration.type,
      options: {
        ...this.params.eventConfiguration.options,
        variableName: this.variable.name.toLowerCase()
      },
      rawData: this.params.data
    });
  }
}

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AssetVariable, DeviceVariable, FollowedVariable } from '@iot-platform/models/i4b';
import { get } from 'lodash';
// eslint-disable-next-line max-len
import { AssetVariableConfigureReadPopupComponent } from '../../../grid-manager-popups/asset-variable-configure-read-popup/asset-variable-configure-read-popup.component';
import { RichValueCellParams } from '../rich-value-cell.params';
import { RichVariableValueCellHelpers } from '../../helpers/rich-variable-value-cell.helpers';

@Component({
  selector: 'grid-engine-rich-variable-value-cell',
  templateUrl: './rich-variable-value-cell.component.html',
  styleUrls: ['./rich-variable-value-cell.component.scss']
})
export class RichVariableValueCellComponent implements ICellRendererAngularComp {
  params: RichValueCellParams;
  variable: Partial<AssetVariable> | Partial<DeviceVariable> | Partial<FollowedVariable>;
  displayUnit: boolean;
  canReadGraph = false;
  canReadEvent = false;
  minimalDisplay = false;
  displayInfoIcon = false;
  dialog: MatDialog = inject(MatDialog);
  eventIconProperties: { isDisplayed: boolean; tooltip: string; iconName: string } = { isDisplayed: false, iconName: '', tooltip: '' };
  displayGraphIcon = false;

  agInit(params: RichValueCellParams): void {
    this.params = params;
    this.minimalDisplay = params.cellOptions.minimalDisplay;
    this.displayUnit = params.cellOptions.displayUnit ?? true;
    this.variable = RichVariableValueCellHelpers.getVariable(this.params);
    this.canReadGraph = this.params.userPermissions?.find(({ key }) => key === 'canReadGraph')?.value ?? false;
    this.canReadEvent = this.params.userPermissions?.find(({ key }) => key === 'canReadEvent')?.value ?? false;

    this.setEventIconProperties();
    this.displayGraphIcon = this.canReadGraph && !!(this.variable as Partial<AssetVariable> | Partial<FollowedVariable>)?.totalGraphs;
  }

  refresh(): boolean {
    return false;
  }

  setEventIconProperties(): void {
    if (
      this.canReadEvent &&
      !!(this.variable as Partial<AssetVariable>)?.totalActiveEvents &&
      !(this.variable as Partial<AssetVariable>)?.totalAcknowledgedEvents
    ) {
      this.eventIconProperties = { iconName: 'active', isDisplayed: true, tooltip: 'GRID_ENGINE.CELLS.RICH_VARIABLE_VALUE_CELL.DISPLAY_ACTIVE_EVENTS' };
    } else if (
      this.canReadEvent &&
      !(this.variable as Partial<AssetVariable>)?.totalActiveEvents &&
      !!(this.variable as Partial<AssetVariable>)?.totalAcknowledgedEvents
    ) {
      this.eventIconProperties = {
        iconName: 'acknowledged',
        isDisplayed: true,
        tooltip: 'GRID_ENGINE.CELLS.RICH_VARIABLE_VALUE_CELL.DISPLAY_ACKNOWLEDGED_EVENTS'
      };
    } else if (
      this.canReadEvent &&
      !!(this.variable as Partial<AssetVariable>)?.totalActiveEvents &&
      !!(this.variable as Partial<AssetVariable>)?.totalAcknowledgedEvents
    ) {
      this.eventIconProperties = {
        iconName: 'ongoing',
        isDisplayed: true,
        tooltip: 'GRID_ENGINE.CELLS.RICH_VARIABLE_VALUE_CELL.DISPLAY_ACTIVE_AND_ACKNOWLEDGED_EVENTS'
      };
    } else {
      this.eventIconProperties = { iconName: '', isDisplayed: false, tooltip: '' };
    }
  }

  onDispatchEvent(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.minimalDisplay) {
      this.params.dispatchEvent({ type: this.params.eventConfiguration.type, options: this.params.eventConfiguration.options, rawData: this.params.data });
    }
  }

  openFollowedVariableEvents(event: MouseEvent): void {
    event.stopPropagation();
    this.params.dispatchEvent({
      type: 'openFollowedVariableEvents',
      options: this.params.eventConfiguration.options,
      rawData: { asset: this.params.data, followedVariable: this.variable }
    });
  }

  openGraphsByVariable(event: MouseEvent): void {
    event.stopPropagation();
    let siteId: string = get(this.params, 'data.site.id', null);
    if (this.params.cellOptions.dataType === 'event' || !siteId) {
      siteId = get(this.params, 'data.context.site.id');
    }
    this.params.dispatchEvent({
      type: 'openGraphsByVariable',
      options: this.params.eventConfiguration.options,
      rawData: { asset: this.params.data, followedVariable: this.variable, siteId }
    });
  }

  toggleIconInfo(displayIcon: boolean, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.displayInfoIcon = displayIcon && !!this.variable?.linked && !this.params.data.hasOwnProperty('device');
  }

  openVariableConfiguration(event: MouseEvent) {
    event.stopPropagation();
    this.dialog.open(AssetVariableConfigureReadPopupComponent, {
      data: { assetVariable: this.variable, asset: !!this.params.colDef.field ? this.params.data : get(this.params.data, 'asset') }
    });
  }
}

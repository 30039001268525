import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Organization } from '@iot-platform/models/common';

import { TranslateService } from '@ngx-translate/core';

import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { EntitiesService } from '../../../../../../shared/src/lib/entities.service';

@Component({
  selector: 'iot4bos-backoffice-ui-dialog-organization-edit-form',
  templateUrl: './dialog-organization-edit-form.component.html',
  styleUrls: ['./dialog-organization-edit-form.component.scss']
})
export class DialogOrganizationEditFormComponent implements OnInit {
  organizationForm: UntypedFormGroup;
  initialName = '';

  constructor(
    private dialogRef: MatDialogRef<DialogOrganizationEditFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { organization: Organization; parentOrganizationId: string; parentOrganizationName: string },
    private translateService: TranslateService,
    private entitiesService: EntitiesService
  ) {}

  ngOnInit() {
    this.organizationForm = new UntypedFormGroup({
      name: new UntypedFormControl(
        this.data.organization ? this.data.organization.name : '',
        [Validators.required, Validators.maxLength(50)],
        [this.checkName.bind(this)]
      )
    });

    if (this.data.organization) {
      this.initialName = this.data.organization.name;
    }
  }

  get nameControl(): AbstractControl {
    return this.organizationForm.get('name');
  }

  get title$(): Observable<string> {
    return this.data.organization
      ? this.translateService.get('ADMIN.DIALOG.ADD_ORGANIZATION.TITLE_EDIT')
      : this.data.parentOrganizationName
      ? this.translateService.get('ADMIN.DIALOG.ADD_ORGANIZATION.TITLE_ADD', { parentOrganizationName: this.data.parentOrganizationName })
      : of('');
  }

  checkName(name: UntypedFormControl) {
    if (!this.organizationForm) {
      return of(null);
    }

    if (this.nameControl.value && this.data.parentOrganizationId) {
      return this.entitiesService.getEntityNameDuplicatesTotal(name.value).pipe(
        map((entityWithThisName: number) => this.initialName === name.value ? null : entityWithThisName > 0 ? { duplicate: true } : null),
        take(1)
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    let organization: Organization = {
      ...this.data.organization,
      name: this.nameControl.value.trim()
    };

    if (this.data.parentOrganizationId) {
      organization = { ...organization, parentId: this.data.parentOrganizationId };
    }
    this.dialogRef.close(organization);
  }
}

import { Component, Input, OnChanges, Signal, SimpleChanges } from '@angular/core';
import { fromGrids } from '@iot-platform/grid-engine';
import { Filter } from '@iot-platform/models/common';
import { AssetEventsGridData, DeviceEventsGridData, I4BGrid, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { PoEventRule } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';

@Component({
  selector: 'iot4bos-ui-po-event-generated-events-tab',
  templateUrl: './po-event-generated-events-tab.component.html',
  styleUrls: ['./po-event-generated-events-tab.component.scss']
})
export class PoEventGeneratedEventsTabComponent implements OnChanges {
  @Input() userPermissions: { key: string; value: boolean }[] = [];
  @Input() rule!: PoEventRule;

  grid!: Signal<I4BGrid<I4BGridOptions, AssetEventsGridData> | I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined>;

  eventType!: string;
  masterviewName!: string;
  mandatoryFilters: Filter[] = [];

  origin = `po-event-rules/${this.rule?.id}`;

  constructor(private readonly store: Store) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes?.rule?.currentValue) {
      this.mandatoryFilters = [{ criteriaKey: 'poEventRuleId', value: changes.rule.currentValue.id, isHidden: true }];
      this.selectGridByConcept(changes.rule.currentValue);
    }
  }

  private selectGridByConcept(rule: PoEventRule): void {
    if (rule.concept?.toLowerCase() === 'asset-variable') {
      this.eventType = 'asset-events';
      this.masterviewName = 'asset-events-by-pe-rule';
      this.grid = this.store.selectSignal(fromGrids.getDefaultAssetEventsByPERuleGrid);
    } else {
      this.eventType = 'device-events';
      this.masterviewName = 'device-events-by-pe-rule';
      this.grid = this.store.selectSignal(fromGrids.getDefaultDeviceEventsPERuleGrid);
    }
  }
}

import { CommandType } from '../../../i4b/enums/command-type.enum';
import { I4BCellType } from '../configurations';
import { I4bCellConcept } from '../enums';
import { HeaderType } from '../models';
import { I4BButtonColumn } from './i4b-button.column';

export class KercomDeviceButtonColumn extends I4BButtonColumn {
  constructor() {
    super(
      {
        type: HeaderType.CALL_TO_ACTION,
        displayName: 'BUTTON',
        icon: ''
      },
      {
        concept: I4bCellConcept.DEVICES,
        isConfigurable: false,
        isDefault: true,
        isLink: false,
        position: '',
        sortProperty: '',
        width: 10,
        id: 'button',
        cell: {
          type: I4BCellType.CALL_TO_ACTION,
          bulkActions: [
            {
              order: 1,
              key: 'bulkEditDevices',
              label: 'DEVICES.INFO_FORM_POPUP.EDIT_DEVICES',
              enableConditions: [],
              disableConditions: [],
              visibleConditions: [[{ key: 'canUpdateDevice', value: true }]]
            },
            {
              order: 2,
              key: 'bulkEditDeviceFiles',
              label: 'DEVICES.FILES_POPUP.TITLE',
              enableConditions: [],
              disableConditions: [],
              visibleConditions: [[{ key: 'canUpdateDevice', value: true }]]
            },
            {
              order: 3,
              key: `bulk_${CommandType.REFRESH}_command`,
              label: `DEVICES.DETAIL_DEVICE_CARD.${CommandType.REFRESH.toUpperCase()}`,
              enableConditions: [[{ key: 'isRefreshCommandEnabled', value: true }]],
              disableConditions: [],
              visibleConditions: [[{ key: 'canReadDevice', value: true }]],
              divider: true
            },
            {
              order: 4,
              key: `bulk_${CommandType.SELFCONF}_command`,
              label: `DEVICES.DETAIL_DEVICE_CARD.${CommandType.SELFCONF.toUpperCase()}`,
              enableConditions: [[{ key: 'isGetConfigCommandEnabled', value: true }]],
              disableConditions: [],
              visibleConditions: [[{ key: 'canReadDevice', value: true }]]
            },
            {
              order: 5,
              key: `bulk_${CommandType.UNBLOCK}_command`,
              label: `DEVICES.DETAIL_DEVICE_CARD.${CommandType.UNBLOCK.toUpperCase()}`,
              enableConditions: [[{ key: 'isUnblockCommandEnabled', value: true }]],
              disableConditions: [],
              visibleConditions: [[{ key: 'canReadDevice', value: true }]]
            },
            {
              order: 6,
              key: `bulk_${CommandType.GET_ALARM}_command`,
              label: `DEVICES.DETAIL_DEVICE_CARD.${CommandType.GET_ALARM.toUpperCase()}`,
              enableConditions: [[{ key: 'isGetAlarmCommandEnabled', value: true }]],
              disableConditions: [],
              visibleConditions: [[{ key: 'canReadDevice', value: true }]]
            }
          ],
          singleActions: [
            {
              order: 1,
              key: 'editDevice',
              label: 'DEVICES.TABLE_CONTENT.BUTTONS.EDIT_DEVICE',
              enableConditions: [],
              disableConditions: [],
              visibleConditions: [[{ key: 'canUpdateDevice', value: true }]]
            },
            {
              order: 2,
              key: 'editDeviceFiles',
              label: 'DEVICES.FILES_POPUP.TITLE',
              enableConditions: [],
              disableConditions: [],
              visibleConditions: [[{ key: 'canUpdateDevice', value: true }]]
            },
            {
              order: 3,
              key: `${CommandType.REFRESH}_command`,
              label: `DEVICES.DETAIL_DEVICE_CARD.${CommandType.REFRESH.toUpperCase()}`,
              enableConditions: [[{ key: 'isRefreshCommandEnabled', value: true }]],
              disableConditions: [],
              visibleConditions: [[{ key: 'canReadDevice', value: true }]],
              divider: true
            },
            {
              order: 4,
              key: `${CommandType.SELFCONF}_command`,
              label: `DEVICES.DETAIL_DEVICE_CARD.${CommandType.SELFCONF.toUpperCase()}`,
              enableConditions: [[{ key: 'isGetConfigCommandEnabled', value: true }]],
              disableConditions: [],
              visibleConditions: [[{ key: 'canReadDevice', value: true }]]
            },
            {
              order: 5,
              key: `${CommandType.UNBLOCK}_command`,
              label: `DEVICES.DETAIL_DEVICE_CARD.${CommandType.UNBLOCK.toUpperCase()}`,
              enableConditions: [[{ key: 'isUnblockCommandEnabled', value: true }]],
              disableConditions: [],
              visibleConditions: [[{ key: 'canReadDevice', value: true }]]
            },
            {
              order: 6,
              key: `${CommandType.GET_ALARM}_command`,
              label: `DEVICES.DETAIL_DEVICE_CARD.${CommandType.GET_ALARM.toUpperCase()}`,
              enableConditions: [[{ key: 'isGetAlarmCommandEnabled', value: true }]],
              disableConditions: [],
              visibleConditions: [[{ key: 'canReadDevice', value: true }]]
            }
          ],
          visibleConditions: [],
          enableConditions: []
        }
      },
      { order: 1000 }
    );
  }
}

import { IotMapDisplayMode } from '@iot-platform/iot-platform-maps';
import { Filter } from '@iot-platform/models/common';
import * as Leaflet from 'leaflet';

export interface I4BMapLayerConfig {
  path: string;
  displayMode: IotMapDisplayMode;
  layer: { [name: string]: Leaflet.LayerGroup };
  additionalFilter?: Filter;
}

export interface I4BMapLayersConfig {
  [concept: string]: I4BMapLayerConfig[];
}

export class MapLayersHelper {
  private static LAYERS_CONFIG: I4BMapLayersConfig = {
    sites: [
      { path: 'default', displayMode: 'default', layer: { Basic: Leaflet.layerGroup([]) } },
      { path: 'assetsDevices', displayMode: 'assetsDevices', layer: { 'Assets & Devices': Leaflet.layerGroup([]) } },
      { path: 'activeEvents', displayMode: 'activeEvents', layer: { 'Active Events': Leaflet.layerGroup([]) } },
      { path: 'matchScore', displayMode: 'matchScore', layer: { 'Address Accuracy': Leaflet.layerGroup([]) } }
    ],
    assets: [
      { path: 'default', displayMode: 'default', layer: { Basic: Leaflet.layerGroup([]) } },
      {
        path: 'LEVEL',
        displayMode: 'assetVariable',
        layer: { Level: Leaflet.layerGroup([]) },
        additionalFilter: { criteriaKey: 'variableName', value: 'level' }
      },
      {
        path: 'PRESSURE',
        displayMode: 'assetVariable',
        layer: { Pressure: Leaflet.layerGroup([]) },
        additionalFilter: { criteriaKey: 'variableName', value: 'pressure' }
      },
      {
        path: 'SHUTDOWN_STATUS',
        displayMode: 'assetVariable',
        layer: { 'Shutdown status': Leaflet.layerGroup([]) },
        additionalFilter: { criteriaKey: 'variableName', value: 'shutdown_status' }
      },
      {
        path: 'PRODUCTION_VALVE',
        displayMode: 'assetVariable',
        layer: { 'Production valve': Leaflet.layerGroup([]) },
        additionalFilter: { criteriaKey: 'variableName', value: 'production_valve' }
      },
      {
        path: 'LEFT_PRESSURE',
        displayMode: 'assetVariable',
        layer: { 'Left pressure': Leaflet.layerGroup([]) },
        additionalFilter: { criteriaKey: 'variableName', value: 'left_pressure' }
      },
      {
        path: 'RIGHT_PRESSURE',
        displayMode: 'assetVariable',
        layer: { 'Right pressure': Leaflet.layerGroup([]) },
        additionalFilter: { criteriaKey: 'variableName', value: 'right_pressure' }
      }
    ],
    devices: [
      { path: 'default', displayMode: 'default', layer: { Basic: Leaflet.layerGroup([]) } },
      { path: 'ccf', displayMode: 'ccf', layer: { CCF: Leaflet.layerGroup([]) } }
    ]
  };

  public static getLayersByConcept(concept: string): { [layerName: string]: Leaflet.LayerGroup } {
    return this.LAYERS_CONFIG[concept].reduce((acc: { [layerName: string]: Leaflet.LayerGroup }, config: I4BMapLayerConfig) => {
      Object.entries(config.layer).forEach(([lName, layer]) => {
        acc[lName] = layer;
      });
      return acc;
    }, {});
  }

  public static getSelectedLayerConfig(concept: string, layerName: string): I4BMapLayerConfig | undefined {
    return this.LAYERS_CONFIG[concept].find((config: I4BMapLayerConfig) => Object.keys(config.layer).includes(layerName));
  }

  public static getDisplayModeByLayer(concept: string, layerName: string): IotMapDisplayMode {
    const config = this.getSelectedLayerConfig(concept, layerName);
    return config?.displayMode ? config.displayMode : 'default';
  }
}

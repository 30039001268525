import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'grid-engine-followed-number-cell',
  templateUrl: './followed-number-cell.component.html',
  styleUrls: ['./followed-number-cell.component.scss']
})
export class FollowedNumberCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  tooltip: string;

  constructor(private readonly translateService: TranslateService) {}

  agInit(params: ICellRendererParams) {
    this.params = params;
    if (this.params.value !== null) {
      this.tooltip = this.translateService.instant('ASSETS.VARIABLE.FOLLOWED.CARD_TITLE', { followedRank: this.params.value });
    }
  }

  refresh(): boolean {
    return false;
  }
}

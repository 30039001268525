import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, ReactiveFormsModule, MatIconModule, MatToolbarModule, MatCardModule, MatButtonModule, MatCheckboxModule],
  selector: 'shared-iot4bos-asset-ui-optional-properties-form',
  templateUrl: './optional-properties-form.component.html',
  styleUrls: ['./optional-properties-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionalPropertiesFormComponent implements OnInit {
  form: UntypedFormArray = new UntypedFormArray([]);
  properties: { key: string; value: boolean }[] = [];

  constructor(
    public dialogRef: MatDialogRef<OptionalPropertiesFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { optionalProperties: { [key: string]: boolean }; name: string }
  ) {}

  get isPropertiesEdited(): boolean {
    const props: { [key: string]: boolean } = this.getProperties();
    return Object.keys(props).some((key) => props[key] !== this.data.optionalProperties[key]);
  }

  ngOnInit(): void {
    this.properties = Object.keys(this.data.optionalProperties)
      .sort()
      .map((key: string) => {
        const value: boolean = this.data.optionalProperties[key];
        if (value) {
          this.form.push(new UntypedFormControl(key));
        }
        return { key, value };
      });
  }

  getProperties(): { [key: string]: boolean } {
    return this.properties.reduce(
      (acc: { [key: string]: boolean }, { key }) => ({
        ...acc,
        [key]: this.form.getRawValue().includes(key)
      }),
      {}
    );
  }

  onSelectionChange(event: MatCheckboxChange): void {
    if (event.checked) {
      this.form.push(new UntypedFormControl(event.source.value));
    } else {
      this.form.controls.forEach((control: AbstractControl, index: number) => {
        if (control.value === event.source.value) {
          this.form.removeAt(index);
        }
      });
    }
  }

  save(): void {
    this.dialogRef.close(this.getProperties());
  }

  close() {
    this.dialogRef.close();
  }
}

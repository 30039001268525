import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ContactsService } from './services';
import { ContactsEffects } from './state';
import * as fromContacts from './state/reducers';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature(fromContacts.contactsFeatureKey, fromContacts.reducers), EffectsModule.forFeature([ContactsEffects])],
  providers: [ContactsService]
})
export class ContactsStoreModule {}

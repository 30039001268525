import { Injectable } from '@angular/core';
import { AssetVariable } from '@iot-platform/models/i4b';
import { AbstractAssetVariableConfigureReadPopupService } from '../components/grid-manager-popups/asset-variable-configure-read-popup/abstract-asset-variable-configure-read-popup.service';

@Injectable()
export class GridEngineSettingsService {
  constructor(private readonly assetVariableConfigureReadPopupService: AbstractAssetVariableConfigureReadPopupService) {}

  updateAssetVariableThresholds(assetVariable: AssetVariable): void {
    this.assetVariableConfigureReadPopupService.updateAssetVariableThresholds(assetVariable);
  }
}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseUser } from '@iot-platform/models/common';
import { SortUtil } from '@iot-platform/iot-platform-utils';

@Component({
  selector: 'iot4bos-backoffice-ui-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss', '../../style/admin.style.scss']
})
export class UsersListComponent implements OnChanges {
  @Input() users: BaseUser[] = [];

  @Output() selectUser: EventEmitter<BaseUser> = new EventEmitter<BaseUser>();

  sortedUsers: BaseUser[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('users')) {
      this.sortedUsers = [...changes.users.currentValue];
      this.sortedUsers.sort(SortUtil.sortByProperty('lastname'));
    }
  }

  onSelectUser(selected: BaseUser) {
    this.selectUser.emit(selected);
  }
}

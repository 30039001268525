import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Device } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot4bos-ui-device-breadcrumb-item',
  templateUrl: './device-breadcrumb-item.component.html',
  styleUrls: ['./device-breadcrumb-item.component.scss', '../breadcrumb-item.scss']
})
export class DeviceBreadcrumbItemComponent {
  @Input() device: Device;
  @Input() active: boolean;

  @Output() navigate: EventEmitter<{ activeId: string; requestedSiteId: string }> = new EventEmitter();

  onClick() {
    this.navigate.emit({ activeId: this.device.id, requestedSiteId: this.device.site.id });
  }
}

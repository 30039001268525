import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'iot4bos-backoffice-ui-roles-crud-checkbox',
  templateUrl: './roles-crud-checkbox.component.html',
  styleUrls: ['./roles-crud-checkbox.component.scss']
})
export class RolesCrudCheckboxComponent implements OnChanges {
  @Input() authorizations: string[];
  @Input() disabled: boolean;

  @Output() toggleAuthorizations: EventEmitter<string[]> = new EventEmitter();

  canCreate;
  canRead;
  canUpdate;
  canDelete;

  innerAuthorizations: string[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('authorizations') && changes.authorizations.currentValue) {
      this.innerAuthorizations = Object.assign([], changes.authorizations.currentValue);
      this.canCreate = this.innerAuthorizations.includes('create');
      this.canRead = this.innerAuthorizations.includes('read');
      this.canUpdate = this.innerAuthorizations.includes('update');
      this.canDelete = this.innerAuthorizations.includes('delete');
    }
  }

  updateAuthorizations(key: string, value) {
    if (this.innerAuthorizations.indexOf(key) === -1 && value.checked) {
      this.innerAuthorizations.push(key);
    }

    if (this.innerAuthorizations.indexOf(key) > -1 && !value.checked) {
      this.innerAuthorizations.splice(this.innerAuthorizations.indexOf(key), 1);
    }
  }

  onToggleCreate(value) {
    this.updateAuthorizations('create', value);
    this.toggleAuthorizations.emit(this.innerAuthorizations);
  }

  onToggleRead(value) {
    this.updateAuthorizations('read', value);
    this.toggleAuthorizations.emit(this.innerAuthorizations);
  }

  onToggleUpdate(value) {
    this.updateAuthorizations('update', value);
    this.toggleAuthorizations.emit(this.innerAuthorizations);
  }

  onToggleDelete(value) {
    this.updateAuthorizations('delete', value);
    this.toggleAuthorizations.emit(this.innerAuthorizations);
  }
}

import { noop } from 'rxjs';
import { I4BCellType, I4BColumnConfiguration } from './configurations';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import { DaliaFirmwareVersionColumn, ExpandedVariableColumn, I4BBasicColumn, I4BColumn, I4BSelectionColumn, IdColumn, NameColumn } from './definitions';
import { DaliaDeviceButtonColumn } from './definitions/dalia-device-button.column';
import { DiagnosticVariableGroupColumn } from './definitions/i4b-diagnostic-variable-group.column';
import { I4bCellConcept } from './enums';
import { DevicesGridData, HeaderType, I4BColumnHeader, I4BGrid, I4BGridOptions } from './models';
import { I4BColumnOptions } from './options';

export const DEFAULT_DEVICES_COLUMNS_FOR_DALIA_DEVICES_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new NameColumn(
    { displayName: 'NAME', type: HeaderType.ICON_AND_TEXT, icon: 'device' },
    {
      id: 'name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_NAME',
      concept: I4bCellConcept.DEVICES,
      width: 200,
      isDefault: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToDevice' } }
    },
    { order: 1, pinned: 'left', width: 200 }
  ),
  new IdColumn(
    { displayName: 'IDENTIFIER' },
    {
      id: 'identifier',
      concept: I4bCellConcept.DEVICES,
      width: 170,
      isDefault: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToDevice' } }
    },
    { order: 2 }
  ),
  new I4BBasicColumn(
    { displayName: 'FAMILY' },
    {
      id: 'type.family',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_FAMILY',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      sortProperty: 'type.family',
      concept: I4bCellConcept.DEVICES,
      width: 130
    },
    {
      order: 3
    }
  ),
  new I4BBasicColumn(
    { displayName: 'MODEL' },
    {
      id: 'type.model',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_MODEL',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      sortProperty: 'type.model',
      concept: I4bCellConcept.DEVICES,
      width: 130
    },
    {
      order: 4
    }
  ),
  new DaliaFirmwareVersionColumn(
    { displayName: 'FIRMWARE' },
    {
      id: 'type.firmware',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_FIRMWARE',
      cell: {
        type: I4BCellType.BASIC,
        options: {
          toFixed: 3
        }
      },
      isDefault: true,
      sortProperty: 'type.firmware',
      concept: I4bCellConcept.DEVICES,
      width: 130
    },
    {
      order: 5
    }
  ),
  new I4BBasicColumn(
    { displayName: 'OPERATOR' },
    {
      id: 'communication.operator',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.OPERATOR',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 150
    },
    {
      order: 6
    }
  ),
  new I4BBasicColumn(
    { displayName: 'LAST INCOMING CALL', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_INCOMING_CALL',
      id: 'lastCallStatus.datetime',
      isDefault: true,
      concept: I4bCellConcept.DEVICES,
      cell: {
        type: I4BCellType.DATE
      }
    },
    {
      order: 7
    }
  )
];

export const DEFAULT_DIAGNOSTIC_VARIABLE_COLUMNS_FOR_DALIA_DEVICE_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new DiagnosticVariableGroupColumn(
    { displayName: 'TEMPERATURE' },
    {
      id: 'TEMPERATURE',
      translationKey: 'TEMPERATURE',
      cell: { type: I4BCellType.DIAGNOSTIC_VARIABLE },
      isDefault: false,
      isConfigurable: true,
      concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
      width: 100
    },
    {
      order: 21
    }
  ),
  new ExpandedVariableColumn(
    { displayName: 'BATTERY LEVEL' },
    {
      id: 'expandedVariables.bl.lastValue.value',
      isDefault: true,
      isConfigurable: true,
      sortProperty: 'expandedVariables.bl.lastValue.value',
      concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
      cell: {
        type: I4BCellType.VARIABLE_VALUE_CELL,
        options: {
          combineWithUnit: true
        }
      },
      width: 170
    },
    { order: 22, customId: 'expandedVariables.bl', sortable: true, isDisplayed: true }
  ),
  new DiagnosticVariableGroupColumn(
    { displayName: 'RADIO_LEVEL' },
    {
      id: 'RADIO_LEVEL',
      translationKey: 'RADIO_LEVEL',
      cell: { type: I4BCellType.DIAGNOSTIC_VARIABLE },
      isDefault: false,
      isConfigurable: true,
      concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
      width: 110
    },
    {
      order: 23
    }
  ),
  new DiagnosticVariableGroupColumn(
    { displayName: 'SMS_SENT' },
    {
      id: 'SMS_SENT',
      translationKey: 'SMS_SENT',
      cell: { type: I4BCellType.DIAGNOSTIC_VARIABLE },
      isDefault: false,
      isConfigurable: true,
      concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
      width: 110
    },
    {
      order: 24
    }
  ),
  new ExpandedVariableColumn(
    { displayName: 'ENERGY MODE' },
    {
      id: 'expandedVariables.em.lastValue.value',
      isDefault: true,
      isConfigurable: true,
      sortProperty: 'expandedVariables.em.lastValue.value',
      concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
      cell: {
        type: I4BCellType.VARIABLE_VALUE_CELL,
        cellClassRules: {
          'bg-blue300': 'value === "FPM"',
          'bg-green200': 'value === "ECO1"',
          'bg-orange300': 'value === "ECO2"',
          'bg-red300': 'value === "PSM"'
        },
        options: {
          textAlign: 'center',
          translatedValues: {
            0: {
              value: 'FPM',
              color: '#FFFFFF',
              // backgroundColor: '#66ACEC',
              align: 'center'
            },
            1: {
              value: 'ECO1',
              color: '#333333',
              // backgroundColor: '#B1D7A5',
              align: 'center'
            },
            2: {
              value: 'ECO2',
              color: '#333333',
              // backgroundColor: '#F4BB66',
              align: 'center'
            },
            3: {
              value: 'PSM',
              color: '#FFFFFF',
              // backgroundColor: '#E76678',
              align: 'center'
            }
          }
        }
      },
      width: 175
    },
    { order: 25, customId: 'expandedVariables.em', sortable: true, isDisplayed: true }
  ),
  new ExpandedVariableColumn(
    { displayName: 'POWER SUPPLY', type: HeaderType.ICON_AND_TEXT, showHeaderName: false },
    {
      id: 'expandedVariables.pws.lastValue.value',
      isDefault: true,
      isConfigurable: true,
      sortProperty: 'expandedVariables.pws.lastValue.value',
      concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
      width: 120,
      cell: {
        type: I4BCellType.ICON,
        options: {
          textAlign: 'center',
          translatedValues: {
            0: {
              value: 'power-supply'
            }
          }
        }
      }
    },
    { order: 26, customId: 'expandedVariables.pws' }
  )
];

export class DefaultDaliaDevicesGrid implements I4BGrid<I4BGridOptions, DevicesGridData> {
  masterview = 'dalia-devices';
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name = null;
  userId = null;
  data: DevicesGridData = {
    response: {
      data: [],
      pagination: null
    }
  };
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: false,
    autoRefresh: { enabled: true, delay: 120 },
    gridSort: [{ colId: 'name', sort: 'asc', sortIndex: 2 }],
    buttonColumn: { enabled: true, className: new DaliaDeviceButtonColumn() },
    selectionColumn: { enabled: false, className: new I4BSelectionColumn() }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];

  export = () => noop();
}

import { Injectable } from '@angular/core';
import { ContactsFacade as SharedContactsFacade } from '@iot-platform/shared/data-access/contacts';
import { Store } from '@ngrx/store';
import * as fromContacts from '../reducers';

@Injectable({
  providedIn: 'root'
})
export class ContactsFacade extends SharedContactsFacade {
  constructor(protected readonly store: Store) {
    super(store);
  }

  public contacts$ = this.store.select(fromContacts.selectContactsWithNotifications);
}

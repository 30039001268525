import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { AssetVariable, DeviceVariable } from '@iot-platform/models/i4b';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'iot4bos-ui-variable-item',
  templateUrl: './variable-item.component.html',
  styleUrls: ['./variable-item.component.scss']
})
export class VariableItemComponent {
  @Input() variable: DeviceVariable | AssetVariable;
  @Input() selected = false;
  @Input() locked = false;

  @Output() toggle: EventEmitter<boolean> = new EventEmitter<any>(false);

  onChange(event: MatCheckboxChange) {
    this.toggle.emit(event.checked);
  }
}

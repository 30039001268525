import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
  selector: 'grid-engine-basic-header-cell',
  templateUrl: './basic-header-cell.component.html',
  styleUrls: ['./basic-header-cell.component.scss']
})
export class BasicHeaderCellComponent implements IHeaderAngularComp {
  public params;

  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GridsDbActions } from '@iot-platform/grid-engine';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { PoEventsService } from '../../../../../../../shared/src/lib/services/po-events.service';
import { POEventRulesDbActions, POEventRulesUiActions } from '../actions';

@Injectable()
export class PoEventsEffects {
  listPoEventRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POEventRulesUiActions.listPOEventRules),
      concatMap((action) =>
        this.poEventsService.listPoEventRules(action.request).pipe(
          map((response) => POEventRulesDbActions.listPOEventRulesSuccess({ response })),
          catchError((error) => of(POEventRulesDbActions.listPOEventRulesFailure({ error })))
        )
      )
    )
  );

  addPoEventRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POEventRulesUiActions.addPOEventRule),
      concatMap((action) =>
        this.poEventsService.addRule(action.poEventRuleToAdd).pipe(
          map((addedRule) => POEventRulesDbActions.addPOEventRuleSuccess({ addedRule })),
          catchError((error) => of(POEventRulesDbActions.addPOEventRuleFailure({ error })))
        )
      )
    )
  );

  addPoEventRuleThenConfigure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POEventRulesUiActions.addPOEventRuleThenConfigure),
      concatMap((action) =>
        this.poEventsService.addRule(action.poEventRuleToAdd).pipe(
          map((addedRule) => POEventRulesDbActions.addPOEventRuleThenConfigureSuccess({ addedRule })),
          catchError((error) => of(POEventRulesDbActions.addPOEventRuleThenConfigureFailure({ error })))
        )
      )
    )
  );

  updatePoEventRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POEventRulesUiActions.updatePOEventRule),
      concatMap((action) =>
        this.poEventsService.updateRule(action.poEventRuleToUpdate).pipe(
          map((updatedRule) => POEventRulesDbActions.updatePOEventRuleSuccess({ updatedRule })),
          catchError((error) => of(POEventRulesDbActions.updatePOEventRuleFailure({ error })))
        )
      )
    )
  );

  configurePoEventRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POEventRulesUiActions.configurePOEventRule),
      switchMap((action) =>
        this.poEventsService.fullUpdateRule(action.poEventRuleToConfigure).pipe(
          map((updatedRule) => POEventRulesDbActions.updatePOEventRuleSuccess({ updatedRule })),
          catchError((error) => of(POEventRulesDbActions.updatePOEventRuleFailure({ error })))
        )
      )
    )
  );

  deletePoEventRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POEventRulesUiActions.deletePOEventRule),
      concatMap((action) =>
        this.poEventsService.deletePoEventRule(action.poEventRuleToDelete).pipe(
          map((deletedRule) => POEventRulesDbActions.deletePOEventRuleSuccess({ deletedRule })),
          catchError((error) => of(POEventRulesDbActions.deletePOEventRuleFailure({ error })))
        )
      )
    )
  );

  deletePoEventRuleSuccessThenNavigateToMV$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(POEventRulesDbActions.deletePOEventRuleSuccess),
        tap(() => this.router.navigate(['po-event-rules']))
      ),
    { dispatch: false }
  );

  navigatePoEventRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POEventRulesUiActions.navigateToPOEventRuleDetails),
      tap((action) => this.router.navigate(['po-event-rules', action.rule.id])),
      concatMap((action) => [
        POEventRulesUiActions.loadPOEventRule({ ruleToLoadId: action.rule.id as unknown as string }),
        GridsDbActions.resetDataInDefaultGrid({ gridConcept: 'po-event-generated-asset-events' }),
        GridsDbActions.resetDataInDefaultGrid({ gridConcept: 'po-event-generated-device-events' })
      ])
    )
  );

  loadRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POEventRulesUiActions.loadPOEventRule),
      switchMap((action) =>
        this.poEventsService.getRuleById(action.ruleToLoadId).pipe(
          map((ruleLoaded) => POEventRulesDbActions.loadPOEventRuleSuccess({ ruleLoaded })),
          catchError((error) => of(POEventRulesDbActions.loadPOEventRuleFailure({ error })))
        )
      )
    )
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          POEventRulesDbActions.addPOEventRuleSuccess,
          POEventRulesDbActions.addPOEventRuleThenConfigureSuccess,
          POEventRulesDbActions.updatePOEventRuleSuccess,
          POEventRulesDbActions.deletePOEventRuleSuccess
        ),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          POEventRulesDbActions.addPOEventRuleFailure,
          POEventRulesDbActions.addPOEventRuleThenConfigureFailure,
          POEventRulesDbActions.updatePOEventRuleFailure,
          POEventRulesDbActions.deletePOEventRuleFailure,
          POEventRulesDbActions.listPOEventRulesFailure
        ),
        tap((action) => this.notificationService.displayError(action))
        // tap((action) => this.notificationService.displayError(`${action.type} ${action}`))
      ),
    { dispatch: false }
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          POEventRulesUiActions.listPOEventRules,
          POEventRulesUiActions.loadPOEventRule,
          POEventRulesUiActions.addPOEventRule,
          POEventRulesUiActions.addPOEventRuleThenConfigure,
          POEventRulesUiActions.updatePOEventRule,
          POEventRulesUiActions.deletePOEventRule
        ),
        tap(() => {
          this.notificationService.showLoader();
        })
      ),
    { dispatch: false }
  );

  hideLoaderAfterResponse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          POEventRulesDbActions.listPOEventRulesSuccess,
          POEventRulesDbActions.listPOEventRulesFailure,
          POEventRulesDbActions.loadPOEventRuleSuccess,
          POEventRulesDbActions.loadPOEventRuleFailure,
          POEventRulesDbActions.addPOEventRuleSuccess,
          POEventRulesDbActions.addPOEventRuleFailure,
          POEventRulesDbActions.addPOEventRuleThenConfigureSuccess,
          POEventRulesDbActions.addPOEventRuleThenConfigureFailure,
          POEventRulesDbActions.updatePOEventRuleSuccess,
          POEventRulesDbActions.updatePOEventRuleFailure,
          POEventRulesDbActions.deletePOEventRuleSuccess,
          POEventRulesDbActions.deletePOEventRuleFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly poEventsService: PoEventsService,
    private readonly notificationService: NotificationService,
    private readonly router: Router
  ) {}
}

import { Component, OnDestroy } from '@angular/core';
import { EmailTemplate } from '@iot-platform/models/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomCellParams } from '../custom-cell.params';
import { EmailTemplateCellService } from './email-template-cell.service';

@Component({
  selector: 'grid-engine-email-template-cell',
  templateUrl: './email-template-cell.component.html',
  styleUrls: ['./email-template-cell.component.scss']
})
export class EmailTemplateCellComponent implements OnDestroy {
  params: CustomCellParams;
  templateLoading = true;
  emailTemplate!: EmailTemplate;

  destroyed$ = new Subject();

  constructor(private readonly emailTemplateCellService: EmailTemplateCellService) {}

  agInit(params: CustomCellParams): void {
    this.params = params;

    if (!!this.params.value) {
      this.emailTemplateCellService
        .getEmailTemplateById(this.params.value)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (template: EmailTemplate) => {
            this.emailTemplate = template;
            this.templateLoading = false;
          },
          () => (this.templateLoading = false)
        );
    } else {
      this.templateLoading = false;
    }
  }

  refresh(): boolean {
    return false;
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
  }
}

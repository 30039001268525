<div fxLayout="row">
  @if (displayMenu) {
    <div class="header-cell-menu">
      @if ((displayFilterIcon$ | async) === false) {
        <span (click)="onDisplayMenuClick()" class="ag-header-icon ag-header-cell-menu-button active" ref="eMenu">
          <mat-icon class="icon">menu</mat-icon>
        </span>
      }
      @if (displayFilterIcon$ | async) {
        <span (click)="onDisplayMenuClick()" class="ag-header-icon active">
          <mat-icon class="icon">filter_list</mat-icon>
        </span>
      }
    </div>
  }

  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="customHeaderLabel" fxLayout="row" fxLayoutAlign="start center">
      <ng-content></ng-content>
    </div>
    @if (params?.enableSorting) {
      <div fxLayout="row">
        <div (click)="onSortRequested('asc', $event)" [ngClass]="ascSort" class="customSortDownLabel">
          <mat-icon class="icon">arrow_upward</mat-icon>
        </div>
        <div (click)="onSortRequested('desc', $event)" [ngClass]="descSort" class="customSortUpLabel">
          <mat-icon class="icon">arrow_downward</mat-icon>
        </div>

        <div
          (click)="onSortRequested('', $event)"
          [ngClass]="{ inactive: true, 'layout-visible': sorted !== '', 'layout-invisible': sorted === '' }"
          class="customSortRemoveLabel"
        >
          <mat-icon class="icon">clear</mat-icon>
        </div>
      </div>
    }
  </div>
</div>

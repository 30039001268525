import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortEntitiesUtil, SortUtil } from '@iot-platform/iot-platform-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DynamicListFieldService {
  constructor(private readonly httpClient: HttpClient) {}

  getDynamicList(
    url: string,
    sortMethod: {
      type: string;
      property?: string;
    },
    arrayOrObject = 'object'
  ): Observable<any[]> {
    return this.httpClient.get(url).pipe(
      map((data: any) => {
        if (data.content) {
          return this.sortDynamicList(data.content, sortMethod, arrayOrObject);
        } else {
          return this.sortDynamicList(data, sortMethod, arrayOrObject);
        }
      })
    );
  }

  sortDynamicList(data: any[], sortMethod: { type: string; property?: string }, arrayOrObject: string): any[] {
    switch (sortMethod.type) {
      case 'alphabetically':
        return arrayOrObject === 'object' ? data.sort(SortUtil.sortByProperty(sortMethod.property ? sortMethod.property : 'name')) : data.sort();

      case 'entityHierarchically':
        return SortEntitiesUtil.sortHierarchically(data);

      case '':
        return data.sort();

      default:
        return data;
    }
  }
}

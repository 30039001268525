<div [formGroup]="form" fxLayout="column" fxLayoutGap="10px" style="padding: 10px 20px">
  <mat-checkbox (change)="onAddThresholdCheckboxChange($event.checked)" [checked]="addThresholdCheckbox()" [disabled]="readonly">{{
    'ASSETS.VARIABLE_FORM.ADD_THRESHOLDS' | translate
  }}</mat-checkbox>

  @if(addThresholdCheckbox()) {
  <div>
    <mat-form-field fxFlex="100px" color="accent">
      <mat-label>{{ 'ASSETS.VARIABLE_FORM.THRESHOLD_OPERATOR' | translate }}</mat-label>
      <mat-select formControlName="thresholdOperator" [value]="this.thresholds?.operator" (selectionChange)="onOperatorChange()">
        @for(operator of operatorList; track operator) {
        <mat-option [value]="operator">{{ operator }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  @for(threshold of activeThresholds; track $index) {
  <div fxFlex>
    <shared-iot4bos-asset-ui-variable-thresholds-form
      [maxPosition]="thresholdsMax"
      [position]="$index + 1"
      [threshold]="initialThresholds[$index]"
      (changeThreshold)="onChangeThreshold($index + 1, $event)"
      (deleteThreshold)="onDeleteThreshold($index + 1)"
    ></shared-iot4bos-asset-ui-variable-thresholds-form>
  </div>
  }

  <div fxLayout="row" fxLayoutAlign="center center">
    <button
      (click)="onAddThreshold(currentThresholds?.length ?? +1)"
      [disabled]="currentThresholds?.length === thresholdsMax || readonly"
      class="button-regular"
      color="accent"
      fxFlex="50"
      mat-button
    >
      {{ 'ASSETS.VARIABLE_FORM.ADD_A_NEW_THRESHOLD' | translate: { currentTotal: currentThresholds?.length, maxThresholds: thresholdsMax } }}
    </button>
  </div>
  }
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Asset } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot4bos-ui-asset-breadcrumb-item',
  templateUrl: './asset-breadcrumb-item.component.html',
  styleUrls: ['./asset-breadcrumb-item.component.scss', '../breadcrumb-item.scss']
})
export class AssetBreadcrumbItemComponent {
  @Input() asset: Asset;
  @Input() active = true;

  @Output() navigate: EventEmitter<{ activeId: string; requestedSiteId: string }> = new EventEmitter();

  onClick() {
    // this.navigate.emit({ activeId: this.asset.id, requestedSiteId: this.asset.site.id });
    this.navigate.emit({ activeId: this.asset.site.id, requestedSiteId: this.asset.site.id });
  }

  close() {
    this.navigate.emit({ activeId: this.asset.site.id, requestedSiteId: this.asset.site.id });
  }
}

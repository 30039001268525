import { IHeaderParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'grid-engine-header-shell',
  templateUrl: './header-shell.component.html',
  styleUrls: ['./header-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderShellComponent implements OnInit {
  @Input() params: IHeaderParams;

  public sorted: string;
  public ascSort: string;
  public descSort: string;
  public noSort: string;

  displayMenu: boolean;
  displayFilterIcon$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  ngOnInit() {
    if (this.params.enableSorting) {
      this.params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
      this.onSortChanged();
    }
    this.params.column.addEventListener('filterChanged', this.isFilterActive.bind(this));
    this.displayMenu = this.params.enableMenu;
  }

  onSortRequested(order, event) {
    this.params.setSort(order, event.shiftKey);
    if (this.params.api) {
      this.params.api.refreshHeader();
    }
  }

  onSortChanged() {
    this.ascSort = this.descSort = this.noSort = 'inactive';
    if (this.params.column.isSortAscending()) {
      this.sorted = 'asc';
      this.ascSort = 'active';
    } else if (this.params.column.isSortDescending()) {
      this.sorted = 'desc';
      this.descSort = 'active';
    } else {
      this.sorted = '';
      this.noSort = 'active';
    }
  }

  onDisplayMenuClick() {
    this.params.showColumnMenu(this.params.eGridHeader);
  }

  isFilterActive() {
    this.displayFilterIcon$.next(this.params.column.isFilterActive());
  }
}

<div class="popup-content" fxLayout="column" style="width: 800px; padding: 16px 16px 0 16px">
  @if (isLoading) {
    <div fxFlex fxLayout="row" fxLayoutAlign="center center">
      <iot-platform-ui-card-loader [backgroundClass]="'h-140'" fxFlex></iot-platform-ui-card-loader>
    </div>
  } @else {
    <div>
      <div fxLayout="column" fxLayoutGap="8px">
        <div>
          <p class="basic-info fw-600" fxLayout="row" fxLayoutAlign="space-between center">
            <span>
              {{ data?.assetVariable?.name }}
            </span>
            <span class="value">
              {{ data?.assetVariable | valueUnitFormat }}
            </span>
          </p>
          <p fxLayout="row" fxLayoutAlign="space-between center">
            <span>
              {{ normalizedVariable?.description | translate }}
            </span>
            <span>
              {{ data?.assetVariable?.lastValue?.datetime | dateFormat }}
            </span>
          </p>
        </div>

        <iot-platform-ui-detail-popup-section-header [headerTitle]="'ASSETS.VARIABLE_FORM.COMMENT' | translate"></iot-platform-ui-detail-popup-section-header>

        <div class="main-block">
          {{ data.assetVariable.comment | infoDisplay }}
        </div>

        <iot-platform-ui-detail-popup-section-header [headerTitle]="'ASSETS.VARIABLE.FORMULA' | translate"></iot-platform-ui-detail-popup-section-header>

        <div class="main-block" fxLayout="column">
          <div fxLayout="column">
            <div class="inner-block" fxLayout="column">
              <p>
                <span class="label" fxFlex="30">{{ 'ASSETS.VARIABLE.FORMULA_MODEL' | translate }}</span>
                <span class="value"> {{ assetVariable.formula?.model | infoDisplay }}</span>
              </p>
            </div>
            <div class="inner-block" fxLayout="row wrap">
              @for (parameter of parameters; track parameter.key) {
                <p fxFlex="33" fxLayout="row">
                  <span class="label" fxFlex="50">{{ parameter.key }}</span>
                  <span class="value">{{ parameter.value | numberFormat | infoDisplay }}</span>
                </p>
              }
            </div>
          </div>
        </div>

        @if (displaySourceDeviceVariable || displaySourceAssetVariables) {
          <iot-platform-ui-detail-popup-section-header
            [headerTitle]="'ASSETS.VARIABLE.SELECTED_VARIABLES' | translate"
          ></iot-platform-ui-detail-popup-section-header>

          <div class="main-block" fxLayout="column" fxLayoutGap="8px">
            <div class="inner-block" fxLayout="column">
              <p>
                <span class="label capitalize-first-letter" fxFlex="30">{{
                  (displaySourceAssetVariables ? 'IOT_DICTIONARY.asset' : 'IOT_DICTIONARY.device') | translate
                }}</span>
                <span class="value">{{ displaySourceAssetVariables ? assetSource.name : deviceSource.name }}</span>
              </p>
            </div>
            <iot-platform-ui-detail-popup-section-header></iot-platform-ui-detail-popup-section-header>

            <div fxLayout="column">
              <div fxLayout="row">
                <p fxFlex="30" fxLayout="column">
                  <span class="label capitalize-first-letter">{{ 'IOT_DICTIONARY.NAME' | translate }}</span>
                </p>

                <p fxFlex="20" fxLayout="column">
                  <span class="label capitalize-first-letter">{{ 'IOT_DICTIONARY.VALUE' | translate }}</span>
                </p>
                <p fxFlex="20" fxLayout="column">
                  <span class="label capitalize-first-letter">{{ 'IOT_DICTIONARY.DATETIME' | translate }}</span>
                </p>
                <p fxFlex="30" fxLayout="column">
                  <span class="label capitalize-first-letter">{{ 'IOT_DICTIONARY.DESCRIPTION' | translate }}</span>
                </p>
              </div>
              <div>
                @for (formulaVariable of variablesToDisplay; track formulaVariable.id) {
                  <div class="inner-block" fxLayout="column">
                    <div fxLayout="row">
                      <p fxFlex="30" fxLayout="column">
                        <span class="value">{{ formulaVariable.name | infoDisplay }}</span>
                      </p>

                      <p fxFlex="20" fxLayout="column">
                        <span class="value">
                          {{ formulaVariable | valueUnitFormat }}
                        </span>
                      </p>
                      <p fxFlex="20" fxLayout="column">
                        <span class="label">{{ formulaVariable.lastValue.datetime | dateFormat | infoDisplay }}</span>
                      </p>
                      <p fxFlex="30" fxLayout="column">
                        <span class="label">{{ formulaVariable.comment | truncate: [35, '...'] | infoDisplay }}</span>
                      </p>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="8px">
            <iot-platform-ui-detail-popup-section-header [headerTitle]="'ASSETS.VARIABLE.THRESHOLDS' | translate"></iot-platform-ui-detail-popup-section-header>

            @if (originalThresholds.length === 0) {
              <div class="main-block" fxLayout="column">
                <p fxFlex fxLayout="column" fxLayoutAlign="start center">
                  <span class="value" fxFlex>{{ 'ASSETS.VARIABLE.NO_THRESHOLDS' | translate }}</span>
                </p>
              </div>
            } @else {
              <div class="main-block" fxLayout="column">
                <div fxLayout="column">
                  <p class="inner-block" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <span class="label" fxFlex="30">{{ 'ASSETS.VARIABLE_FORM.THRESHOLD_OPERATOR' | translate }}</span>
                    <span class="value" fxFlex="70">{{ getTextualOperator(assetVariable.thresholds?.operator) }}</span>
                    @if (!editThresholdsOn && canUpdateThresholds) {
                      <button
                        (click)="editThresholds()"
                        [disabled]="false"
                        [matTooltip]="'IOT_DICTIONARY.EDIT' | translate"
                        mat-icon-button
                        matTooltipClass="regular-tooltip"
                      >
                        <mat-icon>edit</mat-icon>
                      </button>
                    }
                    @if (editThresholdsOn && canUpdateThresholds) {
                      <button
                        (click)="cancelThresholds()"
                        [disabled]="false"
                        [matTooltip]="'IOT_DICTIONARY.CANCEL' | translate"
                        mat-icon-button
                        matTooltipClass="regular-tooltip"
                      >
                        <mat-icon>cancel</mat-icon>
                      </button>
                      <button
                        (click)="saveThresholds()"
                        [disabled]="false"
                        [matTooltip]="'IOT_DICTIONARY.SAVE' | translate"
                        mat-icon-button
                        matTooltipClass="regular-tooltip"
                      >
                        <mat-icon>save</mat-icon>
                      </button>
                    }
                  </p>

                  <iot-platform-ui-detail-popup-section-header style="padding-bottom: 10px"> </iot-platform-ui-detail-popup-section-header>

                  <div class="inner-block" fxFlex fxLayout="column">
                    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                      <p fxFlex="30" fxLayout="column" fxLayoutAlign="center start">
                        <span class="label capitalize-first-letter" fxFlex>{{ 'IOT_DICTIONARY.NAME' | translate }}</span>
                      </p>

                      @if (editThresholdsOn) {
                        <p fxFlex="20" fxLayout="column" fxLayoutAlign="center center">
                          <span class="label capitalize-first-letter" fxFlex>{{ 'IOT_DICTIONARY.PREVIOUS_VALUE' | translate }}</span>
                        </p>
                      }
                      <p fxFlex="20" fxLayout="column" fxLayoutAlign="center center">
                        <span class="label capitalize-first-letter" fxFlex>{{ 'IOT_DICTIONARY.VALUE' | translate }}</span>
                      </p>
                      <p fxFlex="15" fxLayout="column" fxLayoutAlign="center center">
                        <span class="label capitalize-first-letter" fxFlex>{{ 'AG_GRID.position' | translate }}</span>
                      </p>
                      <p fxFlex="10" fxLayout="column" fxLayoutAlign="center center">
                        <span class="label capitalize-first-letter" fxFlex>{{ 'AG_GRID.color' | translate }}</span>
                      </p>
                      <p fxFlex="25" fxLayout="column" fxLayoutAlign="center center">
                        <span class="label capitalize-first-letter" fxFlex>{{ 'ASSETS.VARIABLE_FORM.THRESHOLD_FORM.DYNAMIC' | translate }}</span>
                      </p>
                    </div>
                    @for (threshold of originalThresholds; track threshold.position) {
                      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <p fxFlex="30" fxLayout="column" fxLayoutAlign="center start">
                          <span class="value">{{ threshold.name | infoDisplay }}</span>
                        </p>
                        @if (!editThresholdsOn) {
                          <p fxFlex="20" fxLayout="column" fxLayoutAlign="center center">
                            <span class="value" fxFlex>{{ threshold.value | numberFormat | infoDisplay }}</span>
                          </p>
                        } @else {
                          <p fxFlex="20" fxLayout="column" fxLayoutAlign="center center">
                            <span class="label" fxFlex>{{ threshold.value | numberFormat | infoDisplay }}</span>
                          </p>
                          <input
                            #input
                            fxFlex="14"
                            (change)="thresholdChanged(threshold, input.value)"
                            [value]="threshold.value"
                            matNativeControl
                            required
                            step="0.01"
                            style="background-color: #fff; border: 1px solid #ccc; border-radius: 4px; padding: 2px; caret-color: #394c5a"
                            type="number"
                          />
                        }

                        <p fxFlex="15" fxLayout="column" fxLayoutAlign="center center">
                          <span class="value" fxFlex>{{ threshold.position | infoDisplay }}</span>
                        </p>
                        <p fxFlex="10" fxLayout="column" fxLayoutAlign="center center">
                          <span [style.background-color]="threshold.lineColor" class="color"> </span>
                        </p>
                        <p fxFlex="25" fxLayout="column" fxLayoutAlign="center center">
                          <span class="value" fxFlex>{{ threshold.calculate ? threshold.calculate.formulaName : ('-' | infoDisplay) }}</span>
                        </p>
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        }

        <iot-platform-ui-detail-popup-metadata-footer [data]="metadata$ | async" style="padding-bottom: 10px"></iot-platform-ui-detail-popup-metadata-footer>
      </div>
    </div>
  }
</div>

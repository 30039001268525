import { Action, createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';
import { POEventRulesDbActions, POEventRulesUiActions } from '../actions';

export const poEventRulesUiFeatureKey = 'poEventRulesUi';

export interface State {
  error?: any;
  poEventLoaded: boolean;
  poEventLoading: boolean;
  openConfigurePopup: boolean;
}

export const initialState: State = {
  error: null,
  poEventLoaded: false,
  poEventLoading: false,
  openConfigurePopup: false
};

const poEventRulesUiReducer = createReducer(
  initialState,
  on(POEventRulesUiActions.listPOEventRules, (state: State) => ({ ...state, poEventLoading: true, poEventLoaded: false, openConfigurePopup: false })),
  on(POEventRulesDbActions.listPOEventRulesSuccess, (state: State) => ({ ...state, poEventLoading: false, poEventLoaded: true, openConfigurePopup: false })),
  on(POEventRulesDbActions.listPOEventRulesFailure, (state: State) => ({ ...state, poEventLoading: false, poEventLoaded: true, openConfigurePopup: false })),

  on(POEventRulesUiActions.addPOEventRule, (state: State) => ({ ...state, poEventLoading: true, poEventLoaded: false, openConfigurePopup: false })),
  on(POEventRulesDbActions.addPOEventRuleSuccess, (state: State) => ({ ...state, poEventLoading: false, poEventLoaded: true, openConfigurePopup: false })),
  on(POEventRulesDbActions.addPOEventRuleFailure, (state: State) => ({ ...state, poEventLoading: false, poEventLoaded: true, openConfigurePopup: false })),

  on(POEventRulesUiActions.addPOEventRuleThenConfigure, (state: State) => ({
    ...state,
    poEventLoading: true,
    poEventLoaded: false,
    openConfigurePopup: false
  })),
  on(POEventRulesUiActions.cancelAddPOEventRuleThenConfigure, (state: State) => ({
    ...state,
    poEventLoading: false,
    poEventLoaded: true,
    openConfigurePopup: false
  })),
  on(POEventRulesDbActions.addPOEventRuleThenConfigureSuccess, (state: State) => ({
    ...state,
    poEventLoading: false,
    poEventLoaded: true,
    openConfigurePopup: true
  })),
  on(POEventRulesDbActions.addPOEventRuleThenConfigureFailure, (state: State) => ({
    ...state,
    poEventLoading: false,
    poEventLoaded: true,
    openConfigurePopup: false
  })),

  on(POEventRulesUiActions.updatePOEventRule, (state: State) => ({ ...state, poEventLoading: true, poEventLoaded: false, openConfigurePopup: false })),
  on(POEventRulesDbActions.updatePOEventRuleSuccess, (state: State) => ({ ...state, poEventLoading: false, poEventLoaded: true, openConfigurePopup: false })),
  on(POEventRulesDbActions.updatePOEventRuleFailure, (state: State) => ({ ...state, poEventLoading: false, poEventLoaded: true, openConfigurePopup: false })),

  on(POEventRulesUiActions.loadPOEventRule, (state: State) => ({ ...state, poEventLoading: true, poEventLoaded: false, openConfigurePopup: false })),
  on(POEventRulesDbActions.loadPOEventRuleSuccess, (state: State) => ({ ...state, poEventLoading: false, poEventLoaded: true, openConfigurePopup: false })),
  on(POEventRulesDbActions.loadPOEventRuleFailure, (state: State) => ({ ...state, poEventLoading: false, poEventLoaded: true, openConfigurePopup: false })),

  on(POEventRulesUiActions.deletePOEventRule, (state: State) => ({ ...state, poEventLoading: true, poEventLoaded: false, openConfigurePopup: false })),
  on(POEventRulesDbActions.deletePOEventRuleSuccess, (state: State) => ({ ...state, poEventLoading: false, poEventLoaded: true, openConfigurePopup: false })),
  on(POEventRulesDbActions.deletePOEventRuleFailure, (state: State) => ({ ...state, poEventLoading: false, poEventLoaded: true, openConfigurePopup: false })),

  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return poEventRulesUiReducer(state, action);
}

export const getPoEventRulesLoaded = (state: State) => state.poEventLoaded;
export const getPoEventRulesLoading = (state: State) => state.poEventLoading;
export const getOpenConfigurePopup = (state: State) => state.openConfigurePopup;
export const getPoEventRulesError = (state: State) => state.error;

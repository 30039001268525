import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserAccount, UserDateFormats } from '@iot-platform/models/common';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { UserProfileService } from '../../services/user-profile.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'iot-platform-users-user-profile-preferences-form',
  templateUrl: './user-profile-preferences-form.component.html'
})
export class UserProfilePreferencesFormComponent implements OnInit, OnDestroy {
  readonly dialogRef: MatDialogRef<UserProfilePreferencesFormComponent> = inject(MatDialogRef<UserProfilePreferencesFormComponent>);
  private readonly userProfileService: UserProfileService = inject(UserProfileService);
  private readonly translateService: TranslateService = inject(TranslateService);
  data: { user: UserAccount } = inject(MAT_DIALOG_DATA);

  form: UntypedFormGroup;
  availableLanguages: string[] = this.translateService.langs;
  numberFormatList: string[] = ['fr', 'en'];
  dateFormatList: UserDateFormats[] = [];
  defaultNumberOfDecimals = 3;
  maxNumberOfDecimals = 15;
  numberOfDecimalsList: number[] = [...Array(this.maxNumberOfDecimals).keys()].map((x) => ++x);
  currentDate = moment();

  private readonly destroy$: Subject<void> = new Subject<void>();

  get appLanguage(): AbstractControl {
    return this.form.get('appLanguage');
  }

  get appNumberFormat(): AbstractControl {
    return this.form.get('appNumberFormat');
  }

  get appNumberOfDecimals(): AbstractControl {
    return this.form.get('appNumberOfDecimals');
  }

  get appDateFormats(): AbstractControl {
    return this.form.get('appDateFormats');
  }

  ngOnInit() {
    this.initForm();
    this.initDateFormatList();
  }

  save(): void {
    this.dialogRef.close({
      ...this.data.user,
      preferences: {
        ...this.data.user.preferences,
        appLanguage: this.appLanguage.value,
        appNumberFormat: this.appNumberFormat.value,
        appNumberOfDecimals: this.appNumberOfDecimals.value,
        appDateFormats: this.appDateFormats.value
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      appLanguage: new UntypedFormControl(this.data?.user?.preferences?.appLanguage ?? 'en'),
      appNumberFormat: new UntypedFormControl(this.data?.user?.preferences?.appNumberFormat ?? 'fr'),
      appNumberOfDecimals: new UntypedFormControl(this.data?.user?.preferences?.appNumberOfDecimals ?? this.defaultNumberOfDecimals),
      appDateFormats: new UntypedFormControl(this.data?.user?.preferences?.appDateFormats ?? {})
    });
  }

  private initDateFormatList(): void {
    this.userProfileService
      .getAppDateFormats()
      .pipe(takeUntil(this.destroy$))
      .subscribe((formats) => {
        this.dateFormatList = formats;
        if (this.form) {
          this.appDateFormats.setValue(
            this.dateFormatList.find((f) => this.data?.user?.preferences?.appDateFormats?.momentFullFormat === f.momentFullFormat) ?? this.dateFormatList[0]
          );
        }
      });
  }
}

import {
  CellPosition,
  ColDef,
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnState,
  FirstDataRenderedEvent,
  GetLocaleTextParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  HeaderPosition,
  NavigateToNextCellParams,
  NavigateToNextHeaderParams,
  RowClickedEvent,
  RowNode,
  RowSelectedEvent,
  SelectionChangedEvent,
  SideBarDef,
  SortChangedEvent,
  TabToNextCellParams,
  TabToNextHeaderParams,
  ValueGetterParams
} from '@ag-grid-community/core';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConditionProcessorUtil } from '@iot-platform/iot-platform-utils';
import { Filter, Pagination } from '@iot-platform/models/common';
import {
  ExportParams,
  GridNavigationType,
  HeaderType,
  I4BButtonColumn,
  I4bCellConcept,
  I4BCellType,
  I4BColumn,
  I4BColumnConfiguration,
  I4BColumnHeader,
  I4BColumnOptions,
  I4BGrid,
  I4BGridData,
  I4BGridOptions,
  I4BGridSort,
  I4BSelectionColumn
} from '@iot-platform/models/grid-engine';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { get, isEqual } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ActiveEventsCellComponent } from '../../grid/cells/active-events-cell/active-events-cell.component';
import { AddressAccuracyCellComponent } from '../../grid/cells/address-accuracy-cell/address-accuracy-cell.component';
import { ArrayOfObjectsCellComponent } from '../../grid/cells/array-of-objects-cell/array-of-objects-cell.component';
import { AssetTemplateCellComponent } from '../../grid/cells/asset-template-cell/asset-template-cell.component';
import { AssetVariableCellComponent } from '../../grid/cells/asset-variable-cell/asset-variable-cell.component';
import { AssetVariableThresholdCellComponent } from '../../grid/cells/asset-variable-threshold-cell/asset-variable-threshold-cell.component';
import { BasicCellComponent } from '../../grid/cells/basic-cell/basic-cell.component';
import { BasicLinkCellComponent } from '../../grid/cells/basic-link-cell/basic-link-cell.component';
import { BooleanCellComponent } from '../../grid/cells/boolean-cell/boolean-cell.component';
import { CallToActionCellComponent } from '../../grid/cells/call-to-action-cell/call-to-action-cell.component';
import { CallToActionHeaderComponent } from '../../grid/cells/call-to-action-header/call-to-action-header.component';
import { ColorCellComponent } from '../../grid/cells/color-cell/color-cell.component';
import { CommentCellComponent } from '../../grid/cells/comment-cell/comment-cell.component';
import { CountCellComponent } from '../../grid/cells/count-cell/count-cell.component';
import { CronReadableCellComponent } from '../../grid/cells/cron-readable-cell/cron-readable-cell.component';
import { CyclicalCallFaultCellComponent } from '../../grid/cells/cyclical-call-fault-cell/cyclical-call-fault-cell.component';
import { DateCellComponent } from '../../grid/cells/date-cell/date-cell.component';
import { DiagnosticVariableCellComponent } from '../../grid/cells/diagnostic-variable-cell/diagnostic-variable-cell.component';
import { DynamicIconCellComponent } from '../../grid/cells/dynamic-icon-cell/dynamic-icon-cell.component';
import { EmailTemplateCellComponent } from '../../grid/cells/email-template-cell/email-template-cell.component';
import { EntityCellComponent } from '../../grid/cells/entity-cell/entity-cell.component';
import { EventDurationCellComponent } from '../../grid/cells/event-duration-cell/event-duration-cell.component';
import { FollowedNumberCellComponent } from '../../grid/cells/followed-number-cell/followed-number-cell.component';
import { IconCellComponent } from '../../grid/cells/icon-cell/icon-cell.component';
import { LastCommandStatusCellComponent } from '../../grid/cells/last-command-status-cell/last-command-status-cell.component';
import { LinkedCellComponent } from '../../grid/cells/linked-cell/linked-cell.component';
import { NumberCellComponent } from '../../grid/cells/number-cell/number-cell.component';
import { OutgoingConnectorCellComponent } from '../../grid/cells/outgoing-connector-cell/outgoing-connector-cell.component';
import { RichVariableValueCellComponent } from '../../grid/cells/rich-variable-value-cell/rich-variable-value-cell.component';
import { SeverityCellComponent } from '../../grid/cells/severity-cell/severity-cell.component';
import { TagCellComponent } from '../../grid/cells/tag-cell/tag-cell.component';
import { TopicSelfSubscriptionCellComponent } from '../../grid/cells/topic-self-subscription-cell/topic-self-subscription-cell.component';
import { TranslatedCellComponent } from '../../grid/cells/translated-cell/translated-cell.component';
import { UserCellComponent } from '../../grid/cells/user-cell/user-cell.component';
import { VariableDispatchCellComponent } from '../../grid/cells/variable-dispatch-cell/variable-dispatch-cell.component';
import { GridExportDialogComponent } from '../../grid/grid-export/grid-export-dialog/grid-export-dialog.component';
import { GridExportService } from '../../grid/grid-export/grid-export.service';
import { BasicHeaderCellComponent } from '../../grid/header-cells/basic-header-cell/basic-header-cell.component';
import { EmptyHeaderCellComponent } from '../../grid/header-cells/empty-header-cell/empty-header-cell.component';
import { GroupHeaderCellComponent } from '../../grid/header-cells/group-header-cell/group-header-cell.component';
import { IconAndTextHeaderCellComponent } from '../../grid/header-cells/icon-and-text-header-cell/icon-and-text-header-cell.component';
import { IconHeaderCellComponent } from '../../grid/header-cells/icon-header-cell/icon-header-cell.component';
import { AgGridNavigationHelpers } from '../../grid/helpers/ag-grid-navigation.helpers';
import { GridFiltersHelpers } from '../../grid/helpers/grid-filters.helpers';

import { GridsDbActions } from '../../state/actions';

export const GRID_HEADER_HEIGHT = 38;
export const GRID_GROUP_HEADER_HEIGHT = 38;
export const GRID_ROW_HEIGHT = 38;

export const FRAMEWORK_COMPONENTS = {
  [HeaderType.BASIC]: BasicHeaderCellComponent,
  [HeaderType.CALL_TO_ACTION]: CallToActionHeaderComponent,
  [HeaderType.CUSTOM_HEADER_GROUP]: GroupHeaderCellComponent,
  [HeaderType.EMPTY]: EmptyHeaderCellComponent,
  [HeaderType.ICON]: IconHeaderCellComponent,
  [HeaderType.ICON_AND_TEXT]: IconAndTextHeaderCellComponent,
  // End of headers
  [I4BCellType.ACTIVE_EVENTS_CELL]: ActiveEventsCellComponent,
  [I4BCellType.ADDRESS_ACCURACY]: AddressAccuracyCellComponent,
  [I4BCellType.ARRAY_OF_OBJECTS]: ArrayOfObjectsCellComponent,
  [I4BCellType.ASSET_TEMPLATE]: AssetTemplateCellComponent,
  [I4BCellType.ASSET_VARIABLE_CELL]: AssetVariableCellComponent,
  [I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL]: AssetVariableThresholdCellComponent,
  [I4BCellType.BASIC]: BasicCellComponent,
  [I4BCellType.BASIC_LINK]: BasicLinkCellComponent,
  [I4BCellType.BOOLEAN_CELL]: BooleanCellComponent,
  [I4BCellType.CALL_TO_ACTION]: CallToActionCellComponent,
  [I4BCellType.COLOR_CELL]: ColorCellComponent,
  [I4BCellType.COMMENT]: CommentCellComponent,
  [I4BCellType.COUNT]: CountCellComponent,
  [I4BCellType.CRON_READABLE_CELL]: CronReadableCellComponent,
  [I4BCellType.CYCLICAL_CALL_FAULT]: CyclicalCallFaultCellComponent,
  [I4BCellType.DATE]: DateCellComponent,
  [I4BCellType.DIAGNOSTIC_VARIABLE]: DiagnosticVariableCellComponent,
  [I4BCellType.DYNAMIC_ICON_CELL]: DynamicIconCellComponent,
  [I4BCellType.EMAIL_TEMPLATE]: EmailTemplateCellComponent,
  [I4BCellType.ENTITY_CELL]: EntityCellComponent,
  [I4BCellType.EVENT_DURATION_CELL]: EventDurationCellComponent,
  [I4BCellType.FOLLOWED_NUMBER_CELL]: FollowedNumberCellComponent,
  [I4BCellType.ICON]: IconCellComponent,
  [I4BCellType.LAST_COMMAND_STATUS_CELL]: LastCommandStatusCellComponent,
  [I4BCellType.LINKED_CELL]: LinkedCellComponent,
  [I4BCellType.NUMBER]: NumberCellComponent,
  [I4BCellType.OUTGOING_CONNECTOR]: OutgoingConnectorCellComponent,
  [I4BCellType.RICH_VARIABLE]: RichVariableValueCellComponent,
  [I4BCellType.SEVERITY_CELL]: SeverityCellComponent,
  [I4BCellType.TAG_CELL]: TagCellComponent,
  [I4BCellType.TOPIC_SELF_SUBSCRIPTION_CELL]: TopicSelfSubscriptionCellComponent,
  [I4BCellType.TRANSLATED_CELL]: TranslatedCellComponent,
  [I4BCellType.USER_CELL]: UserCellComponent,
  [I4BCellType.VARIABLE_DISPATCH_CELL]: VariableDispatchCellComponent,
  [I4BCellType.VARIABLE_VALUE_CELL]: BasicCellComponent
};

const cellConceptsWithIsDisplayOption: string[] = [I4bCellConcept.FOLLOWED_VARIABLES, I4bCellConcept.GROUPED_VARIABLES, I4bCellConcept.DIAGNOSTIC_VARIABLES];

@Component({
  selector: 'grid-engine-grid-page',
  templateUrl: './grid-page.component.html',
  styleUrls: ['./grid-page.component.scss']
})
export class GridPageComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() grid: I4BGrid<I4BGridOptions, I4BGridData>;
  @Input() gridSort: I4BGridSort[];
  @Input() disableAutoFit = false;
  @Input() visibleNodeId: string;
  @Input() userPermissions;
  @Input() hidePaginator = false;

  @Output() dispatchMasterViewEngineEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() dispatchGridEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageChange: EventEmitter<Pagination> = new EventEmitter<Pagination>();
  @Output() sortChange: EventEmitter<{
    event: SortChangedEvent;
    grid: I4BGrid<I4BGridOptions, I4BGridData>;
  }> = new EventEmitter<{
    event: SortChangedEvent;
    grid: I4BGrid<I4BGridOptions, I4BGridData>;
  }>();
  @Output() selectRow: EventEmitter<RowClickedEvent> = new EventEmitter<RowClickedEvent>();

  filters: Filter[] = [];
  mvName = '';
  selectedRowId;
  gridOptions: GridOptions = {
    enableRangeSelection: true,
    copyHeadersToClipboard: false,
    suppressCopyRowsToClipboard: true,
    suppressMenuHide: true,
    headerHeight: GRID_HEADER_HEIGHT,
    rowHeight: GRID_ROW_HEIGHT,
    groupHeaderHeight: GRID_GROUP_HEADER_HEIGHT,
    components: FRAMEWORK_COMPONENTS,
    rowSelection: 'multiple',
    rowBuffer: 20,
    debounceVerticalScrollbar: true,
    animateRows: false,
    enableCellTextSelection: true,
    suppressRowDeselection: true,
    suppressRowClickSelection: true,
    pagination: false,
    onRowDoubleClicked: (event) => {
      this.dispatchMasterViewEngineEvent.emit({
        type: 'open',
        options: { selected: event.context },
        rawData: event.data
      });
    },
    onRowClicked: (event) => {
      this.dispatchGridEvent.emit({
        event
      });
    },
    onColumnMoved: (event: ColumnMovedEvent) => {
      this.dispatchGridEvent.emit({
        event
      });
    },
    onColumnResized: (event: ColumnResizedEvent) => {
      this.dispatchGridEvent.emit({
        event
      });
    },
    getRowId: (params) => params.data.id
  };
  columnDefs: ColDef[] = [];
  defaultColDef = {
    suppressHeaderMenuButton: true,
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: false,
    minWidth: 80,
    // allow every column to be aggregated
    enableValue: true,
    // allow every column to be grouped
    enableRowGroup: true,
    // allow every column to be pivoted
    enablePivot: true
  };
  innerGridData$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  rowClassRules = {};
  rowSelected$ = new Subject<RowSelectedEvent>();
  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true
        }
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
        toolPanelParams: {
          suppressExpandAll: true,
          suppressFilterSearch: true
        }
      }
    ],
    hiddenByDefault: true,
    position: 'right',
    defaultToolPanel: 'columns'
  };
  private gridApi: GridApi;
  private singleActionsEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private readonly destroy$: Subject<void> = new Subject<void>();
  @ViewChild('exportViewRef', { read: ViewContainerRef }) private readonly exportViewRef: ViewContainerRef;

  constructor(
    private readonly renderer: Renderer2,
    private readonly translateService: TranslateService,
    @Inject(DOCUMENT) private readonly document: HTMLDocument,
    private readonly gridExportService: GridExportService,
    private readonly dialog: MatDialog,
    private readonly store: Store
  ) {}

  getLocaleText = (params: GetLocaleTextParams) => this.translateService.instant(`AG_GRID.${params.key}`);

  ngAfterViewInit(): void {
    this.gridExportService.setViewRef(this.exportViewRef);
    this.innerGridData$.pipe(takeUntil(this.destroy$)).subscribe((rowData) => {
      if (this.gridApi) {
        this.gridApi.updateGridOptions({ rowData: [...rowData] });
        this.gridApi.refreshCells({ force: true });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.grid?.currentValue) {
      changes.grid.currentValue.export = this.exportData.bind(this);
      this.gridSort = changes.grid.currentValue?.gridOptions?.gridSort;
      this.innerGridData$.next([...get(changes, ['grid', 'currentValue', 'data', 'response', 'data'], [])]);
    }
    /* if (!isEqual(changes.gridSort?.currentValue, changes.gridSort?.previousValue)) {
        console.log('SORT_CHANGED_B');
        this.setSort(changes.gridSort?.currentValue);
    } */
    if (changes.gridSort?.currentValue) {
      this.setSort(changes.gridSort?.currentValue);
    }
    if (!isEqual(changes.grid?.currentValue?.columns, changes.grid?.previousValue?.columns)) {
      this.initColumnDefs({ ...changes.grid.currentValue });
    }
    if (changes.userPermissions?.currentValue && !!changes.userPermissions.currentValue.length && this.grid) {
      this.initColumnDefs({ ...this.grid });
    }
  }

  ngOnInit(): void {
    this.handleRowSelection();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.setFlexColumnClass();
  }

  onFirstDataRendered(_: FirstDataRenderedEvent) {
    this.setSort(this.gridSort);
    if (this.visibleNodeId) {
      this.scrollTo(this.gridApi.getRowNode(this.visibleNodeId)?.rowIndex);
    }
  }

  onPageChange(pagination: Pagination): void {
    this.pageChange.emit(pagination);
    this.scrollTo(0);
  }

  scrollTo(index: number, position: 'top' | 'bottom' | 'middle' | null = 'top'): void {
    this.gridApi.ensureIndexVisible(index || 0, position);
  }

  onGridColumnsChanged() {
    if (this.disableAutoFit === false) {
      this.sizeColumnsToFit();
    }
    this.initHeaderSize();
  }

  sizeColumnsToFit() {
    const timeout = setTimeout(() => {
      const viewport: HTMLElement = this.document.querySelector('[ref="centerContainer"].ag-center-cols-viewport');
      const container: HTMLElement = this.document.querySelector('[ref="eContainer"].ag-center-cols-container');
      if (viewport && container && container.offsetWidth > 0 && container.offsetWidth - 1 < viewport.offsetWidth) {
        this.gridApi.sizeColumnsToFit();
      }
      clearTimeout(timeout);
    }, 0);
  }

  onSortChange(event: SortChangedEvent) {
    this.sortChange.emit({ event, grid: this.grid });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleRowSelection() {
    this.rowSelected$.pipe(debounceTime(100), takeUntil(this.destroy$)).subscribe(() => {
      this.gridApi.refreshHeader();
    });
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    this.dispatchMasterViewEngineEvent.emit({
      type: 'selectionChanged',
      rawData: event.api.getSelectedNodes()
    });
    if (event.api.getSelectedNodes().length > 0) {
      this.singleActionsEnabled$.next(false);
    } else {
      this.singleActionsEnabled$.next(true);
    }
  }

  /**
   *
   * Grid navigation
   */
  navigateToNextHeader = (params: NavigateToNextHeaderParams): HeaderPosition | null => {
    const { nextHeader, processedNextHeader } = AgGridNavigationHelpers.processNextHeader(params);
    if (params.key !== GridNavigationType.KEY_DOWN.toString() && params.key !== GridNavigationType.KEY_UP.toString()) {
      return nextHeader;
    }
    if (processedNextHeader.headerRowIndex === -1) {
      this.onNavigateToNextRow(params.api, GridNavigationType.KEY_DOWN, 0);
    }
    return processedNextHeader === nextHeader ? null : processedNextHeader;
  };

  tabToNextHeader = (params: TabToNextHeaderParams): HeaderPosition | null =>
    AgGridNavigationHelpers.moveHeaderFocusUpDown(params.previousHeaderPosition, params.headerRowCount, params.backwards);

  tabToNextCell = (params: TabToNextCellParams): CellPosition | null => {
    const previousCell = params.previousCellPosition;
    const suggestedNextCell = params.nextCellPosition;
    return AgGridNavigationHelpers.moveCellFocusUpDown(params.api, GridNavigationType.TAB_TO_NEXT_CELL, previousCell, suggestedNextCell, params.backwards);
  };

  navigateToNextCell = (params: NavigateToNextCellParams): CellPosition | null => {
    const previousCell = params.previousCellPosition;
    const suggestedNextCell = params.nextCellPosition;
    const result = AgGridNavigationHelpers.moveCellFocusUpDown(params.api, params.key, previousCell, suggestedNextCell);
    if (result && (params.key === GridNavigationType.KEY_UP.toString() || params.key === GridNavigationType.KEY_DOWN.toString())) {
      this.onNavigateToNextRow(params.api, params.key, result.rowIndex);
    }
    return result;
  };

  onNavigateToNextRow(api: GridApi, type: string, nextRowIndex: number) {
    api.forEachNodeAfterFilterAndSort((node: RowNode) => {
      if (node.rowIndex === nextRowIndex) {
        this.dispatchGridEvent.emit({
          event: {
            type,
            data: node.data
          }
        });
      }
    });
  }

  /**
   *
   * End grid navigation
   */

  private initColumnDefs(grid: I4BGrid<I4BGridOptions, I4BGridData>) {
    this.rowClassRules = grid.gridOptions?.rowClassRules;
    if (grid.data?.response?.data) {
      this.innerGridData$.next([...get(grid, ['data', 'response', 'data'], [])]);
    }
    this.columnDefs = this.getColumnsDefinitions(grid.columns);
    if (grid.gridOptions.buttonColumn?.enabled) {
      const btnColDef = this.getButtonColumnDefinition(grid);
      if (btnColDef) {
        this.columnDefs = [...this.columnDefs, btnColDef]; // NEED TO ADD MULTI SELECT COLUMN
      }
    }
    if (grid.gridOptions.selectionColumn?.enabled) {
      const selColDef = this.getSelectionColumnDefinition(grid);
      if (selColDef) {
        this.columnDefs = [selColDef, ...this.columnDefs];
      }
    }
  }

  private setSort(gridSort: I4BGridSort[]) {
    if (this.gridApi && gridSort) {
      // reset the sort state before applying the incoming sortState
      this.gridApi.applyColumnState({
        state: this.gridApi.getColumnState().map((state) => ({ ...state, sort: null, sortIndex: null }))
      });

      this.gridApi.applyColumnState({ state: gridSort as ColumnState[] });
      this.gridApi.refreshHeader();
    }
  }

  private initHeaderSize(): void {
    const headerElement: HTMLElement = this.document.querySelector('.ag-header.ag-focus-managed');
    if (headerElement && headerElement.offsetHeight > 40) {
      const height = GRID_HEADER_HEIGHT + GRID_GROUP_HEADER_HEIGHT + 10;
      this.renderer.setStyle(headerElement, 'height', `${height}px`);
    }
  }

  private exportData(params: ExportParams): void {
    this.store.dispatch(GridsDbActions.toggleRefreshActivated({ refreshActivated: false }));
    const {
      gridOptions: { filters, exportParams }
    } = this.grid;

    this.gridExportService.setGrid(this.grid);
    this.gridExportService.setParams({
      ...exportParams,
      filters,
      ...params
    });
    this.gridExportService.setColumnsDef(this.columnDefs);
    this.dialog
      .open(GridExportDialogComponent, {
        width: '500px',
        disableClose: true,
        data: { totalElements: params.totalElements }
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.store.dispatch(GridsDbActions.toggleRefreshActivated({ refreshActivated: true }));
      });
  }

  private getColumnsDefinitions(cols: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[]): ColDef[] {
    return cols.map((col: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) => this.getColumn(col));
  }

  private getSelectionColumnDefinition(grid: I4BGrid<I4BGridOptions, I4BGridData>): ColDef | null {
    const selectionColumn: I4BSelectionColumn = grid.gridOptions.selectionColumn.className;
    const buttonColum: I4BButtonColumn = grid.gridOptions.buttonColumn.className;

    const isSelectionVisible = ConditionProcessorUtil.processConditionsWithPermission(buttonColum?.configuration.cell.visibleConditions, this.userPermissions);

    if (selectionColumn && isSelectionVisible) {
      const selectionColDef = {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        field: selectionColumn.configuration.id,
        pinned: 'left',
        suppressHeaderMenuButton: true,
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        resizable: false
      };
      return selectionColDef as ColDef;
    } else {
      return null;
    }
  }

  private getButtonColumnDefinition(grid: I4BGrid<I4BGridOptions, I4BGridData>): ColDef | null {
    const buttonColumn: I4BButtonColumn = grid.gridOptions.buttonColumn.className;

    const isCallToActionVisible = ConditionProcessorUtil.processConditionsWithPermission(
      buttonColumn?.configuration.cell.visibleConditions,
      this.userPermissions
    );

    if (buttonColumn && isCallToActionVisible) {
      const buttonColDef: ColDef = {
        field: buttonColumn.configuration.id,
        headerName: buttonColumn.header.displayName ? this.translateService.instant(buttonColumn.header.displayName) : buttonColumn.header.displayName,
        sortable: false,
        suppressHeaderMenuButton: true,
        width: 40,
        maxWidth: 40,
        pinned: 'right',
        headerComponent: HeaderType.CALL_TO_ACTION,
        headerComponentParams: {
          bulkActions: buttonColumn.configuration.cell.bulkActions,
          visibleConditions: buttonColumn.configuration.cell.visibleConditions,
          userPermissions: this.userPermissions,
          dispatchEvent: (event) => {
            this.dispatchMasterViewEngineEvent.emit(event);
          }
        },
        cellRenderer: I4BCellType.CALL_TO_ACTION,
        cellRendererParams: {
          actions: buttonColumn.configuration.cell.singleActions,
          userPermissions: this.userPermissions,
          dispatchEvent: (event) => {
            this.dispatchMasterViewEngineEvent.emit(event);
          }
        },
        valueGetter: (params) => JSON.stringify(params.data)
      };
      return buttonColDef;
    } else {
      return null;
    }
  }

  private getColumn(col: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) {
    if (col.configuration?.children) {
      return this.getColumnDefinitionWithChildren(col);
    } else {
      return this.getColumnDefinition(col);
    }
  }

  private getColumnDefinition(col: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) {
    const headerName = col.options.overrideHeaderName ? col.options.overrideHeaderName : col.header.displayName;
    let columnDefinition: ColDef = {
      field: col.configuration.id,
      headerName: headerName ? this.translateService.instant(headerName) : headerName,
      sortable: col.options.sortable,
      resizable: col.configuration?.resizable !== undefined && col.configuration?.resizable !== null ? col.configuration.resizable : true,
      width: col.configuration.width,
      pinned: col.options.pinned,
      suppressHeaderMenuButton: false,
      cellClassRules: col.configuration.cell?.cellClassRules,
      headerComponent: col.header.type,
      headerComponentParams: {
        enableMenu: false,
        headerIcon: col.header.icon ?? '',
        headerTooltip: col.header.tooltip ?? '',
        showHeaderName: col.header.showHeaderName
      },
      comparator: col.configuration.comparator,
      cellRenderer: col.configuration.cell.type,
      cellRendererParams: {
        eventConfiguration: {
          type: col.configuration.cell?.event?.type ?? '',
          options: col.configuration.cell?.event?.options ?? ''
        },
        cellOptions: col.configuration.cell?.options ? col.configuration.cell?.options : '',
        userPermissions: this.userPermissions,
        dispatchEvent: (event) => {
          this.dispatchMasterViewEngineEvent.emit(event);
        }
      }
    };

    const enableFilter = get(col, ['configuration', 'filterParams', 'enabled'], false);
    if (enableFilter) {
      const filterDefinition = GridFiltersHelpers.getFilter(col);
      columnDefinition = {
        ...columnDefinition,
        headerComponentParams: {
          ...columnDefinition.headerComponentParams,
          enableMenu: true
        },
        ...filterDefinition,
        filterParams: {
          ...filterDefinition.filterParams,
          ...get(col, ['configuration', 'filterParams'])
        },
        suppressFiltersToolPanel: true,
        menuTabs: ['filterMenuTab' /* , 'generalMenuTab', 'columnsMenuTab' */]
      };
    }

    if (col.configuration.cell.valueGetter) {
      if (col.configuration.cell.valueGetter instanceof Function) {
        columnDefinition.valueGetter = col.configuration.cell.valueGetter.bind(this);
      } else {
        columnDefinition.valueGetter = (params: ValueGetterParams) => get(params.data, col.configuration.cell.valueGetter as string);
      }
    }
    if (col.configuration.cell.cellStyle) {
      columnDefinition.cellStyle = col.configuration.cell.cellStyle.bind(this);
    }
    return columnDefinition;
  }

  private getColumnDefinitionWithChildren(col: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) {
    let children: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...col.configuration.children];

    if (cellConceptsWithIsDisplayOption.includes(col.configuration.concept)) {
      children = children.filter((child: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) => child?.options?.isDisplayed);
    }

    return {
      ...this.getColumnDefinition(col),
      marryChildren: col.configuration.marryChildren,
      headerGroupComponent: HeaderType.CUSTOM_HEADER_GROUP,
      children: children.map((column: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) => ({
        ...this.getColumnDefinition(column),
        columnGroupShow: null // index === 0 ? '' : 'closed'
      }))
    };
  }

  private setFlexColumnClass() {
    const mainSidenavContentEl = this.document.querySelector('.main-sidenav-content');
    if (mainSidenavContentEl) {
      this.renderer.addClass(mainSidenavContentEl, 'flex-column');
    }
  }
}

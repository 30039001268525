import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromContactsDb from './contacts-db.reducer';
import * as fromContactsUi from './contacts-ui.reducer';

export const contactsFeatureKey = 'contacts';

export interface ContactsState {
  [fromContactsDb.contactsDbFeatureKey]: fromContactsDb.State;
  [fromContactsUi.contactsUiFeatureKey]: fromContactsUi.State;
}

export interface State {
  [contactsFeatureKey]: ContactsState;
}

export function reducers(state: ContactsState | undefined, action: Action) {
  return combineReducers({
    [fromContactsDb.contactsDbFeatureKey]: fromContactsDb.reducer,
    [fromContactsUi.contactsUiFeatureKey]: fromContactsUi.reducer
  })(state, action);
}

export const selectContactsState = createFeatureSelector<ContactsState>(contactsFeatureKey);

export const selectContactsDbState = createSelector(selectContactsState, (state: ContactsState) => state[fromContactsDb.contactsDbFeatureKey]);
export const selectContactsUiState = createSelector(selectContactsState, (state: ContactsState) => state[fromContactsUi.contactsUiFeatureKey]);

export const {
  selectIds: selectContactsIds,
  selectEntities: selectContactsEntities,
  selectAll: selectAllContacts,
  selectTotal: selectTotalContacts
} = fromContactsDb.adapter.getSelectors(selectContactsDbState);

// ***** Contacts DB
export const selectSelectedContactId = createSelector(selectContactsDbState, fromContactsDb.getSelectedContactId);
export const selectSelectedContact = createSelector(
  selectContactsEntities,
  selectSelectedContactId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

// ***** Contacts UI
export const selectContactsLoaded = createSelector(selectContactsUiState, fromContactsUi.getContactsLoaded);
export const selectContactsLoading = createSelector(selectContactsUiState, fromContactsUi.getContactsLoading);

@switch (formulaType){ @case (FormulaType.MIN_MAX){
<shared-iot4bos-asset-ui-min-max-parameters-form
  [readonly]="readonly"
  [initialFormula]="initialFormula"
  [deviceVariable]="deviceVariable"
  (dispatchFormula)="dispatchFormula.emit($event)"
></shared-iot4bos-asset-ui-min-max-parameters-form>
} @case (FormulaType.MIN_MAX_ADVANCED){
<shared-iot4bos-asset-ui-min-max-advanced-parameters-form
  [readonly]="readonly"
  [initialFormula]="initialFormula"
  [deviceVariable]="deviceVariable"
  (dispatchFormula)="dispatchFormula.emit($event)"
></shared-iot4bos-asset-ui-min-max-advanced-parameters-form>
} @case (FormulaType.SLOPE_SHIFT){
<shared-iot4bos-asset-ui-slope-shift-parameters-form
  [readonly]="readonly"
  [initialFormula]="initialFormula"
  [deviceVariable]="deviceVariable"
  (dispatchFormula)="dispatchFormula.emit($event)"
></shared-iot4bos-asset-ui-slope-shift-parameters-form>
} @case (FormulaType.LINEARIZATION){
<shared-iot4bos-asset-ui-linearization-parameters-form
  [readonly]="readonly"
  [initialFormula]="initialFormula"
  [deviceVariable]="deviceVariable"
  (dispatchFormula)="dispatchFormula.emit($event)"
></shared-iot4bos-asset-ui-linearization-parameters-form>
} @case (FormulaType.CONSTANT){
<shared-iot4bos-asset-ui-constant-parameters-form
  [readonly]="readonly"
  [initialFormula]="initialFormula"
  (dispatchFormula)="dispatchFormula.emit($event)"
></shared-iot4bos-asset-ui-constant-parameters-form>
} @case (FormulaType.CONSUMPTION_FOR_PG){
<shared-iot4bos-asset-ui-consumption-parameters-form
  [readonly]="readonly"
  [initialFormula]="initialFormula"
  [assetVariable]="assetVariable"
  [initialSelectedVariables]="selectedAssetVariables"
  (dispatchFormula)="dispatchFormula.emit($event)"
  (updateSelectedAssetVariables)="updateSelectedAssetVariables.emit($event)"
></shared-iot4bos-asset-ui-consumption-parameters-form>
} @case (FormulaType.SHORTFALL){
<shared-iot4bos-asset-ui-shortfall-parameters-form
  [readonly]="readonly"
  [initialFormula]="initialFormula"
  [assetVariable]="assetVariable"
  [initialSelectedVariables]="selectedAssetVariables"
  (dispatchFormula)="dispatchFormula.emit($event)"
  (updateSelectedAssetVariables)="updateSelectedAssetVariables.emit($event)"
></shared-iot4bos-asset-ui-shortfall-parameters-form>
} @case (FormulaType.AUTONOMY_FOR_PG){
<shared-iot4bos-asset-ui-autonomy-parameters-form
  [readonly]="readonly"
  [initialFormula]="initialFormula"
  [assetVariable]="assetVariable"
  [initialSelectedVariables]="selectedAssetVariables"
  (dispatchFormula)="dispatchFormula.emit($event)"
  (updateSelectedAssetVariables)="updateSelectedAssetVariables.emit($event)"
></shared-iot4bos-asset-ui-autonomy-parameters-form>
} @case (FormulaType.SUM){
<shared-iot4bos-asset-ui-sum-parameters-form
  [readonly]="readonly"
  [initialFormula]="initialFormula"
  [assetVariable]="assetVariable"
  [initialSelectedVariables]="selectedAssetVariables"
  (dispatchFormula)="dispatchFormula.emit($event)"
  (updateSelectedAssetVariables)="updateSelectedAssetVariables.emit($event)"
></shared-iot4bos-asset-ui-sum-parameters-form>
} }

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { FollowedVariable } from '@iot-platform/models/i4b';
import { get } from 'lodash';
import { CustomCellParams } from './../custom-cell.params';

@Component({
  selector: 'grid-engine-asset-variable-cell',
  templateUrl: './asset-variable-cell.component.html',
  styleUrls: ['./asset-variable-cell.component.scss']
})
export class AssetVariableCellComponent implements ICellRendererAngularComp {
  params: CustomCellParams;
  followedVariable: FollowedVariable | null;
  canReadGraph = false;

  agInit(params: CustomCellParams): void {
    this.followedVariable = this.getFollowedVariableByField(params.data, params.colDef.field);
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  getFollowedVariableByField(obj, path: string): FollowedVariable {
    let current = obj;
    path
      .split('.')
      .slice(0, -1)
      ?.forEach((p) => (current = get(current, p)));
    return current;
  }

  onDispatchEvent(event: MouseEvent): void {
    event.stopPropagation();
    this.params.dispatchEvent({ type: this.params.eventConfiguration.type, options: this.params.eventConfiguration.options, rawData: this.params.data });
  }
}

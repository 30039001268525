import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnalyticsService } from '@iot-platform/core';

import { FavoriteView } from '@iot-platform/models/common';

import { FavoriteViewsActions, fromFavoriteViews } from '@iot-platform/shared/components';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { selectGridAndLoadData } from '../../../../../../../grid-engine/src/lib/components/state/actions/grids-db.actions';

@Component({
  selector: 'iot4bos-ui-home-shell',
  templateUrl: './home-shell.component.html',
  styleUrls: ['./home-shell.component.scss']
})
export class HomeShellComponent implements OnInit {
  analytic: AnalyticsService = new AnalyticsService('home_page');

  sharedFavoriteViews$: Observable<FavoriteView[]>;
  notSharedFavoriteViews$: Observable<FavoriteView[]>;
  fvLoading: false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private store: Store
  ) {}

  ngOnInit() {
    this.store.dispatch(FavoriteViewsActions.loadFavoriteViews());
    this.sharedFavoriteViews$ = this.store.select(fromFavoriteViews.getSharedFavoriteViews);
    this.notSharedFavoriteViews$ = this.store.select(fromFavoriteViews.getNotSharedFavoriteViews);
  }

  navigateTo(favoriteView: FavoriteView): void {
    this.analytic.log('favorite_view_clicked', 'navigate_to_masterview', favoriteView.masterView);
    this.store.dispatch(FavoriteViewsActions.setCurrentFavoriteView({ masterView: favoriteView.masterView, favoriteView }));
    this.store.dispatch(
      selectGridAndLoadData({ gridId: favoriteView.gridId as string, masterview: favoriteView.masterView as string, filters: favoriteView.filters })
    );
  }
}

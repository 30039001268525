import { I4BCellType, I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { HeaderType, I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BBasicColumn } from './i4b-basic.column';

export class I4BTagColumn extends I4BBasicColumn {
  columnId = 'f6538da4-7237-11ec-a844-acde48001122-tag';
  className = I4BTagColumn;

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    super(
      {
        icon: 'label',
        displayName: customOptions?.overrideHeaderName
          ? customOptions?.overrideHeaderName
          : customHeader?.displayName
          ? customHeader?.displayName
          : 'TAG CATEGORY',
        type: HeaderType.ICON_AND_TEXT
      },
      {
        ...customConfig,
        isConfigurable: false,
        cell: {
          type: I4BCellType.TAG_CELL,
          valueGetter: customConfig?.cell?.valueGetter
        },
        isDefault: customConfig.isDefault ?? false,
        width: customOptions?.width ? customOptions.width : 140
      },
      { ...customOptions }
    );
  }
}

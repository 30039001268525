import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { GridExportService } from '@iot-platform/grid-engine';
import { ConditionProcessorUtil, SortModelAdapter, SortUtil } from '@iot-platform/iot-platform-utils';
import { MasterViewEngineEvent, Pagination } from '@iot-platform/models/common';
import { ExportParams } from '@iot-platform/models/grid-engine';
import { MasterView, MasterViewBluePrint } from '@iot-platform/models/i4b';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GridExportDialogComponent } from '../../../../../../grid-engine/src/lib/components/grid/grid-export/grid-export-dialog/grid-export-dialog.component';

const detailExpand = trigger('detailExpand', [
  state('collapsed', style({ height: '0px', minHeight: '0' })),
  state('expanded', style({ height: '*' })),
  transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
]);

@Component({
  selector: 'i4b-table-engine-master-view-table',
  templateUrl: './master-view-table.component.html',
  styleUrls: ['./master-view-table.component.scss'],
  animations: [detailExpand]
})
export class MasterViewTableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() bluePrint: MasterViewBluePrint;
  @Input() sticky = false;
  @Input() tableData: any;
  @Input() masterView: MasterView;
  @Input() useExternalData = false;
  @Input() useFullyLoadedDataset = false;
  @Input() userPermissions: Array<{ key: string; value: boolean }>;
  @Input() selectedElement: any = {};
  @Input() checkedElements: any[] = [];
  @Input() pendingRequest$ = false;
  @Input() pageSizeOptions: number[] = [];
  @Input() metadata: any;
  @Input() withLoadMoreButton = false;
  @Input() loading = false;
  @Input() observerSelectors: string[] = [];

  @Output() dispatchCellEvent: EventEmitter<MasterViewEngineEvent> = new EventEmitter<MasterViewEngineEvent>();
  @Output() pageChange: EventEmitter<Pagination> = new EventEmitter<Pagination>();
  @Output() sortChange: EventEmitter<Sort> = new EventEmitter<Sort>();

  @ViewChild(MatPaginator) fullDatasetPaginator: MatPaginator;
  @ViewChild(MatPaginator) onePageDatasetPaginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  masterViewDataSource: MatTableDataSource<any>;
  pagination$: BehaviorSubject<Pagination> = new BehaviorSubject<Pagination>({ limit: 10, currentPage: 0, total: 0, maxPage: 0, hasMore: false });
  columnsToDisplay: any[];
  columnsToDisplayIds: string[] = [];
  selection = new SelectionModel<any>(true, []);
  isCallToActionVisible: boolean;
  bulkActionVisibility: any;
  destroyed$ = new Subject();
  @ViewChild('exportViewRef', { read: ViewContainerRef }) private readonly exportViewRef: ViewContainerRef;

  constructor(
    private readonly gridExportService: GridExportService,
    private readonly dialog: MatDialog
  ) {}

  get totalSelected() {
    return this.selection.selected.length;
  }

  ngOnInit() {
    this.pagination$.next({
      ...this.pagination$.getValue(),
      currentPage: this.tableData.currentPage,
      hasMore: this.tableData.hasMore,
      maxPage: this.tableData.maxPage,
      limit: this.tableData.limit,
      total: this.tableData.total
    });
    this.masterViewDataSource = new MatTableDataSource<any>(this.tableData.data);
    setTimeout(() => {
      this.tableData.initialSort = SortModelAdapter.toTableSortModel(this.tableData.initialSort);
      if (this.tableData.initialSort) {
        this.sort.active = this.tableData.initialSort.active;
        this.sort.direction = this.tableData.initialSort.direction;
        this.sort.sortChange.emit(this.tableData.initialSort);
      }
      this.masterViewDataSource.sort = this.sort;
    });

    this.setPaginator();
    this.masterViewDataSource.sortingDataAccessor = (data, sortHeaderId) => this.getSortingDataAccessor(sortHeaderId, data);

    this.gridExportService.onExport$.pipe(takeUntil(this.destroyed$)).subscribe((params: ExportParams) => {
      this.openExportForm(params);
    });
  }

  ngAfterViewInit(): void {
    this.gridExportService.setViewRef(this.exportViewRef);
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('tableData') && changes.tableData.currentValue && this.masterViewDataSource) {
      this.masterViewDataSource.data = changes.tableData.currentValue.data;
      this.pagination$.next({
        ...this.pagination$.getValue(),
        total: changes.tableData.currentValue.total,
        currentPage: changes.tableData.currentValue.currentPage,
        hasMore: changes.tableData.currentValue.hasMore,
        maxPage: changes.tableData.currentValue.maxPage,
        limit: changes.tableData.currentValue.limit
      });
      this.setPaginator();
      this.setCheckedElements(changes.tableData.currentValue.data);
    }

    if (changes.hasOwnProperty('bluePrint') && changes.bluePrint.currentValue) {
      if (changes.bluePrint.currentValue.columns) {
        this.columnsToDisplay = Object.assign(changes.bluePrint.currentValue.columns.sort(SortUtil.sortByProperty('order')), []);
      }

      const buttonColumn = changes.bluePrint.currentValue.buttonColumn;

      if (changes.bluePrint.currentValue.hasOwnProperty('buttonColumn')) {
        this.isCallToActionVisible = ConditionProcessorUtil.processConditionsWithPermission(
          changes.bluePrint.currentValue.buttonColumn.visibleConditions,
          this.userPermissions
        );
      }

      if (changes.bluePrint.currentValue.multipleSelection) {
        this.columnsToDisplayIds = [];
        this.columnsToDisplayIds.push(changes.bluePrint.currentValue.selectionColumn.id);
        this.columnsToDisplayIds = [
          ...this.columnsToDisplayIds,
          ...changes.bluePrint.currentValue.columns.map((column) => column.id).sort(SortUtil.sortByProperty('order'))
        ];
        this.bulkActionVisibility = changes.bluePrint.currentValue.buttonColumn.bulkActions.reduce((acc, value) => {
          acc[value.key] = ConditionProcessorUtil.processConditionsWithPermission(value.visibleConditions, this.userPermissions);
          return acc;
        }, {});
      } else if (changes.bluePrint.currentValue.columns) {
        this.columnsToDisplayIds = changes.bluePrint.currentValue.columns.map((column) => column.id).sort(SortUtil.sortByProperty('order'));
      }

      if (buttonColumn) {
        this.columnsToDisplayIds.push(buttonColumn.id);
      }
    }

    // Set user permissions when it gets updated
    if (changes.hasOwnProperty('userPermissions') && this.bluePrint && this.bluePrint.buttonColumn) {
      this.userPermissions = changes.userPermissions.currentValue;
      this.isCallToActionVisible = ConditionProcessorUtil.processConditionsWithPermission(this.bluePrint.buttonColumn.visibleConditions, this.userPermissions);
    }

    if (changes.hasOwnProperty('checkedElements') && changes['checkedElements'].currentValue) {
      this.selection.clear();
      changes['checkedElements'].currentValue.forEach((element) => {
        this.selection.select(element);
      });
    }
  }

  setPaginator(): void {
    if (this.useFullyLoadedDataset) {
      setTimeout(() => (this.masterViewDataSource.paginator = this.fullDatasetPaginator));
    }
  }

  getSortingDataAccessor(path: string, data: any): any {
    const result = path.split('.').reduce((acc, value) => {
      if (acc) {
        acc = acc[value];
      }

      return acc;
    }, data);

    if (typeof result === 'string') {
      return result.toLowerCase();
    }
    return result;
  }

  onDispatchEvent(event: MasterViewEngineEvent): void {
    this.dispatchCellEvent.emit(event);
  }

  openDetail(element: any) {
    this.selectedElement = element;
    this.dispatchCellEvent.emit({ type: 'open', options: { selected: element }, rawData: element });
  }

  isAllSelected() {
    if (this.masterViewDataSource && this.masterViewDataSource.data.length !== 0) {
      const numSelected = this.selection.selected.length;
      const numRows = this.masterViewDataSource.data.length;
      return numSelected === numRows;
    }
  }

  toggleSelection(element: any) {
    this.selection.toggle(element);
    this.dispatchCellEvent.emit({ type: 'checkElements', rawData: this.selection.selected, options: {} });
  }

  toggleAllSelection() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.dispatchCellEvent.emit({ type: 'checkElements', rawData: this.selection.selected, options: {} });
    } else {
      this.masterViewDataSource.data.forEach((element: any) => this.selection.select(element));
      this.dispatchCellEvent.emit({ type: 'checkElements', rawData: this.selection.selected, options: {} });
    }
  }

  setCheckedElements(data: any[]) {
    if (this.checkedElements) {
      this.selection.clear();
      data
        .filter((element) => !!this.checkedElements.find((checked) => checked.id === element.id))
        .forEach((element) => {
          this.selection.select(element);
        });
    }
  }

  isChecked(element: any): boolean {
    return this.checkedElements ? !!this.checkedElements.filter((checked) => checked.id === element.id).length : false;
  }

  isRowSelected(element: any): boolean {
    return this.selectedElement ? this.selectedElement.id === element.id : false;
  }

  onSingleActionClick(event: MasterViewEngineEvent): void {
    this.dispatchCellEvent.emit(event);
  }

  onBulkActionClick(type: string): void {
    this.dispatchCellEvent.emit({ type, rawData: this.selection.selected, options: {} });
  }

  onPageChange(event: PageEvent) {
    const next: string = event.pageIndex > event.previousPageIndex ? 'next' : null;
    const prev: string = event.pageIndex < event.previousPageIndex ? 'prev' : null;
    this.pageChange.emit({
      ...this.pagination$.getValue(),
      limit: event.pageSize,
      currentPage: event.pageIndex,
      prev,
      next
    });
  }

  onSortChange($event: Sort) {
    this.sortChange.emit($event);
  }

  onLoadMore(): void {
    this.dispatchCellEvent.emit({ type: 'loadMore', rawData: null, options: {} });
  }

  private openExportForm(params: ExportParams): void {
    this.gridExportService.setPagination(this.pagination$.getValue());
    this.gridExportService.setRowData([...this.tableData.data]);
    this.gridExportService.setGridMeta(this.metadata);
    this.gridExportService.setParams(params);
    this.dialog.open(GridExportDialogComponent, {
      width: '500px',
      disableClose: true,
      data: { totalElements: params.totalElements }
    });
  }
}

import { Component, Injector, Signal, computed, effect, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthFacade } from '@iot-platform/auth';
import { IotToolbarDefaultButton, IotToolbarDispatchActionType, REFRESH_BUTTON_CONFIG } from '@iot-platform/iot-platform-ui';
import { BusinessProfile, Entity, IotToolbarEvent, ToolbarSize, UserAccount, UserPreferences } from '@iot-platform/models/common';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { UserProfilePreferencesFormComponent } from '../../components/user-profile-preferences-form/user-profile-preferences-form.component';
import { ProfileFacade } from '../../state/facade/profile.facade';
import { UserProfileInfoFormComponent } from '../../components/user-profile-info-form/user-profile-info-form.component';

@Component({
  selector: 'iot-platform-users-user-profile-shell',
  templateUrl: './user-profile-shell.component.html',
  styleUrls: ['./user-profile-shell.component.scss']
})
export class UserProfileShellComponent {
  protected readonly translateService: TranslateService = inject(TranslateService);
  protected readonly profileFacade: ProfileFacade = inject(ProfileFacade);
  protected readonly authFacade: AuthFacade = inject(AuthFacade);
  protected readonly injector: Injector = inject(Injector);
  readonly dialog: MatDialog = inject(MatDialog);

  toolbarButtonsList: IotToolbarDefaultButton[] = [];
  toolbarSize: string = ToolbarSize.SMALL;
  currentDate = moment();

  user: Signal<UserAccount> = this.profileFacade.user;
  userPreferences: Signal<UserPreferences> = this.profileFacade.userPreferences;
  selectedBusinessProfile: Signal<BusinessProfile> = this.authFacade.selectedBusinessProfile;

  userEntities: Signal<string> = computed(() =>
    this.user()
      ?.entities.map((entity: Entity) => entity.name)
      .join(', ')
  );

  userNotifications: Signal<string> = computed(() => {
    if (this.user()) {
      return Object.entries(this.user()?.notifications)
        .filter((notification) => notification[1])
        .reduce((translationsInArray, notification) => {
          translationsInArray.push(this.translateService.instant('USERS.PROFILE_PREFERENCES.' + notification[0].toLocaleUpperCase()));
          return translationsInArray;
        }, [])
        .join(', ');
    }
  });

  initToolbarButtonsListEffect = effect(
    () => {
      this.toolbarButtonsList = [new IotToolbarDefaultButton({ ...REFRESH_BUTTON_CONFIG, tooltip: 'ASSET_TEMPLATES.OVERVIEW.REFRESH' }, 0)];
    },
    { injector: this.injector, allowSignalWrites: true }
  );

  loadUserEffect = effect(
    () => {
      if (this.selectedBusinessProfile()) {
        this.refreshUserProfile();
      }
    },
    { injector: this.injector, allowSignalWrites: true }
  );

  onToolbarEvent(event: IotToolbarEvent): void {
    switch (event.type as IotToolbarDispatchActionType) {
      case IotToolbarDispatchActionType.REFRESH_PAGE:
        this.refreshUserProfile();
        break;
      default:
        break;
    }
  }

  refreshUserProfile(): void {
    this.profileFacade.loadUserProfile();
    this.profileFacade.loadUserPreferences();
  }

  editUserProfile() {
    this.dialog
      .open(UserProfileInfoFormComponent, {
        width: '700px',
        data: { user: this.user() },
        disableClose: true
      })
      .afterClosed()
      .subscribe((updatedUser: UserAccount) => {
        if (updatedUser) {
          this.profileFacade.saveUserProfile(updatedUser);
        }
      });
  }

  editUserPreferences() {
    this.dialog
      .open(UserProfilePreferencesFormComponent, {
        width: '700px',
        disableClose: true,
        data: { user: this.user() }
      })
      .afterClosed()
      .subscribe((updatedUser: UserAccount) => {
        if (updatedUser) {
          this.profileFacade.saveUserPreferences(updatedUser);
        }
      });
  }

  // TODO: UNCOMMENT WHEN FEATURE IS NEEDED

  /* maxApiKeysReached$ = this.store.select(fromUserProfile.getMaxApiKeysReached);
  apiKeys$ = this.store.select(fromUserProfile.getApiKeys);
  detailedApiKey$ = this.store.select(fromUserProfile.getDetailedApiKey);
  currentApiKey: string | null = null;

  //OLD CONSTRUCTOR
  constructor(
    protected store: Store,
    protected dialog: MatDialog,
    protected translateService: TranslateService
  ) {
    this.store.select(fromAuth.selectSelectedBusinessProfileForAccount).subscribe((bp) => {
      if (bp) {
        this.store.dispatch(ProfileActions.loadUserProfile());
        this.store.dispatch(ProfileActions.loadApiKeys());
        this.store.dispatch(PreferencesActions.loadUserPreferences());
      }
    });

    this.subscriptions.add(
      this.detailedApiKey$
        .pipe(filter((detailedApiKey) => detailedApiKey && this.currentApiKey && detailedApiKey.id === this.currentApiKey))
        .subscribe((detailedApiKey) => {
          if (detailedApiKey) {
            this.dialog.open(UserProfileApiKeysInfoPopupComponent, {
              data: {
                apiKeyConsumerKey: detailedApiKey.consumerKey
              }
            });
          }
        })
    );
  }

  deleteApiKey(apiKeyId: string) {
    if (apiKeyId) {
      this.subscriptions.add(
        this.dialog
          .open(PopupComponent, {
            data: {
              type: 'confirm',
              value: `Delete api key with id ${apiKeyId} ?`
            }
          })
          .afterClosed()
          .subscribe((value) => {
            if (value) {
              this.store.dispatch(ProfileActions.deleteApiKey({ apiKeyIdToDelete: apiKeyId }));
            }
          })
      );
    }
  }

  addApiKey() {
    this.subscriptions.add(
      this.dialog
        .open(PopupComponent, {
          data: {
            type: 'info',
            value: this.translateService.instant('USERS.PROFILE_PREFERENCES.CONFIRM_MESSAGE_ADD_KEY')
          }
        })
        .afterClosed()
        .subscribe((value) => {
          if (value) {
            this.store.dispatch(ProfileActions.addApiKey());
          }
        })
    );
  }

  getApiKey(apiKeyId: string) {
    this.currentApiKey = apiKeyId;
    this.store.dispatch(ProfileActions.getApiKey({ apiKeyIdToGet: apiKeyId }));
  } */
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { CommonApiListResponse, Environment, Product, ProductCatalog } from '@iot-platform/models/common';
import { AbstractConfigureProductsFormService } from '@iot-platform/shared/iot4bos/asset/ui';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigureProductsFormService extends AbstractConfigureProductsFormService {
  constructor(
    private httpClient: HttpClient,
    @Inject(ENVIRONMENT) private environment: Environment
  ) {
    super();
  }

  getCatalogs(id: string): Observable<ProductCatalog[]> {
    return this.httpClient
      .get<CommonApiListResponse<ProductCatalog>>(`${this.environment.api.url}${this.environment.api.endpoints.assetTemplates}/${id}/catalogs`)
      .pipe(map((response) => response.content));
  }

  getProducts(id: string): Observable<Product[]> {
    return this.httpClient
      .get<CommonApiListResponse<Product>>(`${this.environment.api.url}${this.environment.api.endpoints.assetTemplates}/${id}/products`)
      .pipe(map((response) => response.content));
  }
}

import { Component, OnDestroy } from '@angular/core';
import { AssetTemplate } from '@iot-platform/models/i4b';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomCellParams } from '../custom-cell.params';
import { AssetTemplateCellService } from './asset-template-cell.service';

@Component({
  selector: 'grid-engine-asset-template-cell',
  templateUrl: './asset-template-cell.component.html',
  styleUrls: ['./asset-template-cell.component.scss']
})
export class AssetTemplateCellComponent implements OnDestroy {
  params: CustomCellParams;
  templateLoading = true;
  assetTemplate!: AssetTemplate;

  destroyed$ = new Subject<void>();

  constructor(private readonly assetTemplateCellService: AssetTemplateCellService) {}

  agInit(params: CustomCellParams): void {
    this.params = params;

    if (!!this.params.value) {
      this.assetTemplateCellService
        .getAssetTemplateById(this.params.value)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (template: AssetTemplate) => {
            this.assetTemplate = template;
            this.templateLoading = false;
          },
          error: () => (this.templateLoading = false),
          complete: () => (this.templateLoading = false)
        });
    } else {
      this.templateLoading = false;
    }
  }

  refresh(): boolean {
    return false;
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}

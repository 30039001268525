import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmailValidators } from '@iot-platform/iot-platform-utils';
import { UserAccount } from '@iot-platform/models/common';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iot-platform-users-user-profile-info-form',
  templateUrl: './user-profile-info-form.component.html',
  styleUrls: ['./user-profile-info-form.component.scss']
})
export class UserProfileInfoFormComponent implements OnInit, OnDestroy {
  protected dialogRef: MatDialogRef<UserProfileInfoFormComponent> = inject(MatDialogRef<UserProfileInfoFormComponent>);
  public data: { user: UserAccount } = inject(MAT_DIALOG_DATA);

  userForm: UntypedFormGroup;
  initialFormState!: string;
  isFormDisabled$ = new BehaviorSubject<boolean>(true);
  destroy$ = new Subject<void>();

  get firstnameControl(): AbstractControl {
    return this.userForm.get('firstname');
  }

  get lastnameControl(): AbstractControl {
    return this.userForm.get('lastname');
  }

  get secondaryEmailControl(): AbstractControl {
    return this.userForm.get('secondaryEmail');
  }

  get phoneNumberControl(): AbstractControl {
    return this.userForm.get('phoneNumber');
  }

  get notificationSMSControl(): AbstractControl {
    return this.userForm.get('notificationSMS');
  }

  get notificationEmailControl(): AbstractControl {
    return this.userForm.get('notificationEmail');
  }

  get notificationSecondaryEmailControl(): AbstractControl {
    return this.userForm.get('notificationSecondaryEmail');
  }

  get currentFormState(): string {
    return JSON.stringify({ contactForm: this.userForm.value });
  }

  ngOnInit() {
    this.userForm = new UntypedFormGroup({
      firstname: new UntypedFormControl({ value: this.data.user.firstname, disabled: true }, [Validators.required]),
      lastname: new UntypedFormControl({ value: this.data.user.lastname, disabled: true }, [Validators.required]),
      email: new UntypedFormControl({ value: this.data.user.email, disabled: true }, [Validators.required, EmailValidators.isValid()], []),
      secondaryEmail: new UntypedFormControl(this.data.user.secondaryEmail ?? '', [EmailValidators.isValid()]),
      phoneNumber: new UntypedFormControl(this.data.user.phoneNumber ?? '', [Validators.pattern(/^(\+|00)/), Validators.maxLength(20)]),
      notificationSMS: new UntypedFormControl(this.data.user.notifications.sms),
      notificationEmail: new UntypedFormControl(this.data.user.notifications.email),
      notificationSecondaryEmail: new UntypedFormControl(this.data.user.notifications.secondaryEmail)
    });

    this.initialFormState = this.currentFormState;

    this.userForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((changes) => {
      if (changes.notificationSMS) {
        this.phoneNumberControl.addValidators([Validators.required]);
      } else {
        this.phoneNumberControl.removeValidators([Validators.required]);
      }
      if (changes.notificationSecondaryEmail) {
        this.secondaryEmailControl.addValidators([Validators.required]);
      } else {
        this.secondaryEmailControl.removeValidators([Validators.required]);
      }
      this.phoneNumberControl.markAsTouched();
      this.phoneNumberControl.markAsDirty();
      this.secondaryEmailControl.markAsTouched();
      this.phoneNumberControl.updateValueAndValidity({ onlySelf: true });
      this.secondaryEmailControl.updateValueAndValidity({ onlySelf: true });
      this.isFormDisabled$.next(this.getIsFormDisabledValue());
    });
  }

  getIsFormDisabledValue(): boolean {
    return !this.formStateChanged() || !!Object.values(this.userForm.controls).find((control) => !!control.errors);
  }

  formStateChanged(): boolean {
    return this.initialFormState !== this.currentFormState;
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close({
      ...this.data.user,
      secondaryEmail: this.secondaryEmailControl.getRawValue().length ? this.secondaryEmailControl.getRawValue() : null,
      phoneNumber: this.phoneNumberControl.getRawValue().length ? this.phoneNumberControl.getRawValue() : null,
      notifications: {
        ...this.data.user.notifications,
        sms: this.notificationSMSControl.getRawValue(),
        email: this.notificationEmailControl.getRawValue(),
        secondaryEmail: this.notificationSecondaryEmailControl.getRawValue()
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

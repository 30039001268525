import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
  selector: 'grid-engine-cron-readable-cell',
  templateUrl: './cron-readable-cell.component.html',
  styleUrls: ['./cron-readable-cell.component.css']
})
export class CronReadableCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}

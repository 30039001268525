import { ICellRendererAngularComp } from '@ag-grid-community/angular';

import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

import { EntityCellService } from './entity-cell.service';

@Component({
  selector: 'grid-engine-entity-cell',
  templateUrl: './entity-cell.component.html',
  styleUrls: ['./entity-cell.component.scss']
})
export class EntityCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  isDataLoaded = false;
  dataToDisplay = '';
  displayErrorMessage = false;
  errorMessage = 'N/A';

  constructor(private readonly entityCellService: EntityCellService) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;

    if (this.params.value) {
      this.entityCellService.getEntityById(this.params.value).subscribe(
        (entity) => {
          this.isDataLoaded = true;
          if (!!entity) {
            this.dataToDisplay = entity.name ? entity.name : '';
          }
        },
        () => {
          this.displayErrorMessage = true;
          this.isDataLoaded = true;
        }
      );
    } else {
      this.isDataLoaded = true;
    }
  }

  refresh(): boolean {
    return false;
  }
}

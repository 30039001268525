import { IHeaderGroupAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'grid-engine-group-header-cell',
  templateUrl: './group-header-cell.component.html',
  styleUrls: ['./group-header-cell.component.scss']
})
export class GroupHeaderCellComponent implements IHeaderGroupAngularComp {
  params: any;
  expandState: string;
  showExpandArrow = false;

  agInit(params): void {
    this.params = params;

    this.params.columnGroup.getProvidedColumnGroup().addEventListener('expandedChanged', this.syncExpandButtons.bind(this));

    this.syncExpandButtons();
  }

  expandOrCollapse() {
    const currentState = this.params.columnGroup.getProvidedColumnGroup().isExpanded();
    this.params.setExpanded(!currentState);
  }

  syncExpandButtons() {
    if (this.params.columnGroup.getProvidedColumnGroup().isExpanded()) {
      this.expandState = 'expanded';
    } else {
      this.expandState = 'collapsed';
    }
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAccount } from '@iot-platform/models/common';
import { Subscription } from 'rxjs';
import { AccountValidationService } from './services/account-validation.service';

@Component({
  selector: 'iot4bos-backoffice-ui-account-validation',
  templateUrl: './account-validation.component.html',
  styleUrls: ['./account-validation.component.scss']
})
export class AccountValidationComponent implements OnInit, OnDestroy {
  account: UserAccount;
  error: any;
  canValidate: boolean;

  subs: Subscription[] = [];
  constructor(private accountValidationService: AccountValidationService, private router: Router) {}

  ngOnInit() {
    this.subs.push(
      this.accountValidationService.validateSharedAccount().subscribe(
        () => {
          this.error = null;
        },
        (error) => {
          this.error = error;
        }
      )
    );
  }

  onGoToLoginPage(): void {
    this.router.navigate(['/login']);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

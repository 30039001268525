import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'grid-engine-variable-dispatch-cell',
  templateUrl: './variable-dispatch-cell.component.html',
  styleUrls: ['./variable-dispatch-cell.component.scss']
})
export class VariableDispatchCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  tooltip: string;

  constructor(private translateService: TranslateService) {}

  agInit(params: ICellRendererParams) {
    this.params = params;
    this.tooltip = this.translateService.instant('ASSETS.VARIABLE.DISPATCH_TOOLTIP', { dispatchDestination: this.params?.value.dispatchDestination });
  }

  refresh(): boolean {
    return false;
  }
}

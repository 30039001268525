import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetVariable, DeviceVariable } from '@iot-platform/models/i4b';
import { TimeseriesWidgetOptions } from '@iot-platform/models/widgets';

import { VariableChartComponent } from '../variable-chart.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'iot4bos-ui-variable-chart-dialog',
  templateUrl: './variable-chart-dialog.component.html',
  styleUrls: ['./variable-chart-dialog.component.scss']
})
export class VariableChartDialogComponent {
  @ViewChild(VariableChartComponent) variableChart!: VariableChartComponent;
  fullscreen = false;
  displayTableView = false;

  constructor(
    public dialogRef: MatDialogRef<VariableChartDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { variables: (DeviceVariable | AssetVariable)[]; variableType: string; title?: string; options?: TimeseriesWidgetOptions }
  ) {
    this.data.options = {
      variables: this.data.variables,
      chart: {
        legend: {
          enabled: true,
          labelFormat: (params: any) => {
            if (params.asset?.name) {
              return `${params.asset.name} - ${params.name}`;
            }
            if (params.device?.name) {
              return `${params.device.name} - ${params.name}`;
            }
            return params.name;
          }
        }
      },
      ...this.data.options
    };
  }

  close() {
    this.dialogRef.close();
  }

  enterFullscreen() {
    this.fullscreen = true;
    this.dialogRef.addPanelClass('fullscreen-panel');
    this.variableChart.setFullscreenOn(true);
    setTimeout(() => this.variableChart.reflow(), 0);
  }

  exitFullscreen() {
    this.fullscreen = false;
    this.dialogRef.removePanelClass('fullscreen-panel');
    this.variableChart.setFullscreenOn(false);
    setTimeout(() => this.variableChart.reflow(), 0);
  }

  toggleTableView(): void {
    this.displayTableView = !this.displayTableView;
    this.variableChart.toggleTableView(this.displayTableView);
  }
}

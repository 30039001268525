import { noop } from 'rxjs';
import { I4BCellType, I4BColumnConfiguration } from './configurations';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import { I4BBasicColumn, I4BColumn, I4BSelectionColumn } from './definitions';
import { DaliaSensorButtonColumn } from './definitions/dalia-sensor-button.column';
import { I4bCellConcept } from './enums';
import { DaliaSensorsGridData, I4BColumnHeader, I4BGrid, I4BGridOptions } from './models';
import { I4BColumnOptions } from './options';

export const DEFAULT_SENSORS_COLUMNS_FOR_DALIA_SENSORS_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new I4BBasicColumn(
    { displayName: 'SENSOR NAME' },
    {
      id: 'sensor.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SENSOR_NAME',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      sortProperty: 'sensor.name',
      concept: I4bCellConcept.SENSORS
    },
    {
      order: 1,
      width: 160
    }
  ),
  new I4BBasicColumn(
    { displayName: 'INPUT MODEL' },
    {
      id: 'inputModel',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SENSOR_INPUT_MODEL',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      sortProperty: 'inputModel',
      concept: I4bCellConcept.SENSORS
    },
    {
      order: 2
    }
  ),
  new I4BBasicColumn(
    { displayName: 'PATH' },
    {
      id: 'path.label',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SENSOR_PATH',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      sortProperty: 'path.label',
      concept: I4bCellConcept.SENSORS
    },
    {
      order: 3
    }
  ),
  new I4BBasicColumn(
    { displayName: 'VARIABLE NAME' },
    {
      id: 'suggestedVariableName',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SENSOR_VARIABLE_NAME',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      sortProperty: 'suggestedVariableName',
      concept: I4bCellConcept.SENSORS
    },
    {
      order: 4
    }
  ),
  new I4BBasicColumn(
    { displayName: 'UNIT' },
    {
      id: 'suggestedVariableUnit.label',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SENSOR_VARIABLE_UNIT',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      sortProperty: 'suggestedVariableUnit.label',
      concept: I4bCellConcept.SENSORS
    },
    {
      order: 5
    }
  )
];

export class DefaultDaliaSensorsGrid implements I4BGrid<I4BGridOptions, DaliaSensorsGridData> {
  masterview = 'dalia-sensors';
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name = null;
  userId = null;
  data: DaliaSensorsGridData = {
    response: {
      data: [],
      pagination: null
    }
  };
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: false,
    autoRefresh: { enabled: true, delay: 120 },
    gridSort: [{ colId: 'name', sort: 'asc', sortIndex: 2 }],
    buttonColumn: { enabled: false, className: new DaliaSensorButtonColumn() },
    selectionColumn: { enabled: false, className: new I4BSelectionColumn() }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];

  export = () => noop();
}

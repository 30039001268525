import { EnvironmentProviders } from '@angular/core';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { ContactNotificationExpandedContact, ExportSpreadsheetExpandedContact } from '@iot-platform/models/i4b';
import { fromContacts } from '@iot-platform/shared/data-access/contacts';
import { provideEffects } from '@ngrx/effects';
import { Action, combineReducers, createFeatureSelector, createSelector, provideState } from '@ngrx/store';
import { SitesEffects } from '../effects/sites.effects';

import * as fromExportSpreadsheetsDb from './export-spreadsheets-db.reducer';
import * as fromExportSpreadsheetsUi from './export-spreadsheets-ui.reducer';
import * as fromGraphsDb from './graphs-db.reducer';
import * as fromGraphsUi from './graphs-ui.reducer';
import * as fromNotificationsDb from './notifications-db.reducer';
import * as fromNotificationsUi from './notifications-ui.reducer';
import { sitesCommentsFeature, State as SitesCommentsState, adapter as SitesCommentsAdapter } from './sites-comments.reducer';
import { sitesV2feature } from './sites.reducer';

/// New index le reste se fait dans les selecteurs
export function provideSitesState(): EnvironmentProviders[] {
  return [provideState(sitesV2feature.name, sitesV2feature.reducer), provideEffects(SitesEffects)];
}

//

export const sitesFeatureKey = 'sites';

export interface SitesState {
  [fromGraphsDb.graphsDbFeatureKey]: fromGraphsDb.State;
  [fromGraphsUi.graphsUiFeatureKey]: fromGraphsUi.State;
  [fromExportSpreadsheetsDb.exportSpreadsheetsDbFeatureKey]: fromExportSpreadsheetsDb.State;
  [fromExportSpreadsheetsUi.exportSpreadsheetsUiFeatureKey]: fromExportSpreadsheetsUi.State;
  [fromNotificationsDb.notificationsDbFeatureKey]: fromNotificationsDb.State;
  [fromNotificationsUi.notificationsUiFeatureKey]: fromNotificationsUi.State;
  [sitesCommentsFeature.name]: SitesCommentsState;
}

export interface State {
  [sitesFeatureKey]: SitesState;
}

export function reducers(state: SitesState | undefined, action: Action) {
  return combineReducers({
    [fromGraphsDb.graphsDbFeatureKey]: fromGraphsDb.reducer,
    [fromGraphsUi.graphsUiFeatureKey]: fromGraphsUi.reducer,
    [fromExportSpreadsheetsDb.exportSpreadsheetsDbFeatureKey]: fromExportSpreadsheetsDb.reducer,
    [fromExportSpreadsheetsUi.exportSpreadsheetsUiFeatureKey]: fromExportSpreadsheetsUi.reducer,
    [fromNotificationsDb.notificationsDbFeatureKey]: fromNotificationsDb.reducer,
    [fromNotificationsUi.notificationsUiFeatureKey]: fromNotificationsUi.reducer,
    [sitesCommentsFeature.name]: sitesCommentsFeature.reducer
  })(state, action);
}

export const selectSitesState = createFeatureSelector<SitesState>(sitesFeatureKey);

/** ******************************/
/** ********** Graphs ************/
/** ******************************/
export const selectGraphsDbState = createSelector(selectSitesState, (state: SitesState) => (state ? state[fromGraphsDb.graphsDbFeatureKey] : null));
export const selectGraphsUiState = createSelector(selectSitesState, (state: SitesState) => (state ? state[fromGraphsUi.graphsUiFeatureKey] : null));

export const {
  selectIds: getGraphsIds,
  selectEntities: getGraphsEntities,
  selectAll: getAllGraphs,
  selectTotal: getTotalGraphs
} = fromGraphsDb.adapter.getSelectors(selectGraphsDbState);

// ***** Graph DB
export const getSelectedGraphId = createSelector(selectGraphsDbState, fromGraphsDb.getSelectedGraphId);
export const getSelectedGraph = createSelector(getGraphsEntities, getSelectedGraphId, (entities, selectedId) => selectedId && entities[selectedId]);
// ***** Graph UI
export const getGraphsLoaded = createSelector(selectGraphsUiState, fromGraphsUi.getGraphLoaded);
export const getGraphsLoading = createSelector(selectGraphsUiState, fromGraphsUi.getGraphLoading);

/** *******************************/
/** **** Exports Spreadsheet ******/
/** *******************************/
export const selectExportSpreadsheetsDbState = createSelector(selectSitesState, (state: SitesState) =>
  state ? state[fromExportSpreadsheetsDb.exportSpreadsheetsDbFeatureKey] : null
);
export const selectExportSpreadsheetsUiState = createSelector(selectSitesState, (state: SitesState) =>
  state ? state[fromExportSpreadsheetsUi.exportSpreadsheetsUiFeatureKey] : null
);

export const {
  selectIds: selectExportSpreadsheetsIds,
  selectEntities: selectExportSpreadsheetsEntities,
  selectAll: selectAllExportSpreadsheets,
  selectTotal: selectTotalExportSpreadsheets
} = fromExportSpreadsheetsDb.adapter.getSelectors(selectExportSpreadsheetsDbState);

// **** Export spreadsheets DB
export const selectSelectedExportSpreadsheetId = createSelector(selectExportSpreadsheetsDbState, fromExportSpreadsheetsDb.getSelectedExportSpreadsheetId);
export const selectSelectedExportSpreadsheet = createSelector(
  selectExportSpreadsheetsEntities,
  selectSelectedExportSpreadsheetId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const selectExportSpreadsheetsWithExpandedContacts = createSelector(selectAllExportSpreadsheets, fromContacts.selectAllContacts, (exports, contacts) => {
  exports.forEach(
    (exportSpreadsheet) =>
      (exportSpreadsheet.expandedContacts = exportSpreadsheet?.contactIds.reduce((acc: ExportSpreadsheetExpandedContact[], contactId: string) => {
        const matchingContact = contacts.find((c) => c.id === contactId);
        if (matchingContact) {
          const expandedContact: ExportSpreadsheetExpandedContact = {
            id: matchingContact.id as string,
            firstname: matchingContact.firstname,
            lastname: matchingContact.lastname,
            notificationSupports: { email: true },
            incorrectEmailSetting: !matchingContact.email
          };
          acc.push(expandedContact);
        }
        return acc;
      }, []))
  );

  return exports.sort(SortUtil.sortByName);
});

// ***** Export spreadsheets UI
export const selectExportSpreadsheetsLoaded = createSelector(selectExportSpreadsheetsUiState, fromExportSpreadsheetsUi.getExportSpreadsheetsLoaded);
export const selectExportSpreadsheetsLoading = createSelector(selectExportSpreadsheetsUiState, fromExportSpreadsheetsUi.getExportSpreadsheetsLoading);

/** ***********************************/
/** ********** Notifications **********/
/** ***********************************/
export const selectNotificationsDbState = createSelector(selectSitesState, (state: SitesState) =>
  state ? state[fromNotificationsDb.notificationsDbFeatureKey] : null
);
export const selectNotificationsUiState = createSelector(selectSitesState, (state: SitesState) =>
  state ? state[fromNotificationsUi.notificationsUiFeatureKey] : null
);

export const {
  selectIds: selectNotificationsIds,
  selectEntities: selectNotificationsEntities,
  selectAll: selectAllNotifications,
  selectTotal: selectTotalNotifications
} = fromNotificationsDb.adapter.getSelectors(selectNotificationsDbState);

// ***** Notifications DB
export const selectSelectedNotificationId = createSelector(selectNotificationsDbState, fromNotificationsDb.getSelectedNotificationId);

export const selectNotificationsWithExpandedContacts = createSelector(selectAllNotifications, fromContacts.selectAllContacts, (notifications, contacts) => {
  notifications.forEach(
    (notification) =>
      (notification.expandedContacts = notification?.contactIds.reduce((acc: ContactNotificationExpandedContact[], contactId: string) => {
        const matchingContact = contacts.find((c) => c.id === contactId);
        if (matchingContact) {
          const expandedContact: ContactNotificationExpandedContact = {
            id: matchingContact.id as string,
            firstname: matchingContact.firstname,
            lastname: matchingContact.lastname,
            notificationSupports: matchingContact.notificationSupport as { email: boolean; sms: boolean },
            incorrectEmailSetting: matchingContact.notificationSupport?.email ? !matchingContact.email : false,
            incorrectSmsSetting: matchingContact.notificationSupport?.sms ? !matchingContact.phone : false
          };
          acc.push(expandedContact);
        }
        return acc;
      }, []))
  );

  return notifications.sort(SortUtil.sortByName);
});

export const selectSelectedNotification = createSelector(
  selectSelectedNotificationId,
  selectNotificationsWithExpandedContacts,
  (selectedId, notificationsWithExpandedContacts) => selectedId && notificationsWithExpandedContacts.find((n) => n.id === selectedId)
);
// ***** Notifications UI
export const selectNotificationsLoaded = createSelector(selectNotificationsUiState, fromNotificationsUi.getNotificationsLoaded);
export const selectNotificationsLoading = createSelector(selectNotificationsUiState, fromNotificationsUi.getNotificationsLoading);

/** ********************************************************************/
/** ********** Contacts X Notifications X Export spreadsheets **********/
/** ********************************************************************/

export const selectContactsWithNotifications = createSelector(
  fromContacts.selectAllContacts,
  selectAllNotifications,
  selectAllExportSpreadsheets,
  (contacts, notifications, exportSpreadsheets) => {
    contacts.forEach((contact) => {
      contact.associatedNotifications = notifications.filter((notification) => notification.contactIds.includes(contact.id as string));
      contact.associatedExportSpreadsheets = exportSpreadsheets.filter((exportSpreadsheet) => exportSpreadsheet.contactIds.includes(contact.id as string));
    });

    return contacts;
  }
);

/** **********************************/
/** ********** Site Comments ************/
/** **********************************/
const selectSitesCommentsState = createSelector(selectSitesState, (state: SitesState) => state[sitesCommentsFeature.name]);

export const {
  selectIds: selectSiteCommentsIds,
  selectEntities: selectSiteCommentsDictionary,
  selectAll: selectAllSiteComments,
  selectTotal: selectTotalSiteComments
} = SitesCommentsAdapter.getSelectors(selectSitesCommentsState);

export const selectSiteCommentsError = createSelector(selectSitesCommentsState, (state: SitesCommentsState) => state.error);
export const selectSiteCommentsLoading = createSelector(selectSitesCommentsState, (state: SitesCommentsState) => state.loading);

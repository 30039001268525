import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { CustomCellParams } from '../custom-cell.params';

@Component({
  selector: 'grid-engine-asset-variable-threshold-cell',
  templateUrl: './asset-variable-threshold-cell.component.html',
  styleUrls: ['./asset-variable-threshold-cell.component.scss']
})
export class AssetVariableThresholdCellComponent implements ICellRendererAngularComp {
  params: CustomCellParams;

  agInit(params: CustomCellParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
